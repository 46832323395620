var firebaseConfigInfo = {
  apiKey: 'AIzaSyBRmLQxl1fzptWkCwdiKOs1f8x9HTgIar8',
  authDomain: 'vwguk-evreadiness.firebaseapp.com',
  databaseURL: 'https://vwguk-evreadiness.firebaseio.com',
  projectId: 'vwguk-evreadiness',
  storageBucket: 'vwguk-evreadiness.appspot.com',
  messagingSenderId: '978353073684'
};

export default firebaseConfigInfo;
