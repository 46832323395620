import React from 'react';
// react component for creating dynamic tables
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import ReactTable from 'react-table';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// @material-ui/icons

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import { BeatLoader } from 'react-spinners';
import { cardTitle } from 'assets/jss/material-dashboard-pro-react.jsx';

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  }
};

// const phase2 = "2";
// const phase3 = "3";

const tableColumns = [
  {
    Header: 'Sites',
    accessor: 'retailerCount',
    width: 80
  },
  {
    Header: 'Name',
    accessor: 'displayName'
  },
  {
    Header: 'Survey(s) Started?',
    accessor: 'surveyStatus'
  },
  {
    Header: 'Email',
    accessor: 'email'
  }
];

// const getClaimedRetailerUsers = (retailers = []) => {
//   // retailers && retailers.filter(retailer => retailer.responderId !== null);
//   // console.log(claimedRetailers);

//   const claimedRetailers = retailers
//     ? retailers.filter(retailer => retailer.responderId !== null)
//     : [];
//   console.log(claimedRetailers);
//   const
//   return claimedRetailers.map(retailer => {
//     // console.log(retailer.responderId);
//     if (retailer.responderId && retailer.responderId !== null) {
//       console.log(retailer);
//       return {
//         retailerId: retailer.id,
//         retailerName: retailer.retailerName,
//         responderId: retailer.responderId,
//         surveyOn: retailer.surveyOn
//       };
//     }
//   });
// };

const getClaimedRetailersForUser = (retailers, userId) => {
  return retailers.filter(
    retailer => retailer.responderId && retailer.responderId === userId
  );
};

const getResponsSummariesForUser = (responseSummaries, userId) => {
  return responseSummaries.filter(
    responseSummary =>
      responseSummary.responderId &&
      responseSummary.responderId === userId &&
      responseSummary.time
  );
};

// const getResponsSummariesForUser = (responseSummaries, userId) => {
//   const claimedResponseSummaries = responseSummaries.map(responseSummary => {
//     // console.log(retailer.responderId);
//     if (
//       responseSummary.responderId &&
//       responseSummary.responderId == userId &&
//       responseSummary.time &&
//       responseSummary.time !== null
//     ) {
//       return {
//         retailerId: responseSummary.retailerId,
//         retailerName: responseSummary.retailerName,
//         responderId: responseSummary.responderId,
//         lastResponseTime: responseSummary.time
//       };
//     }
//   });
//   return claimedResponseSummaries;
// };

const getClaimedRetailers = retailers =>
  retailers.filter(retailer => retailer.responderId !== null);

// const getStartedSurveys = responseSummaries =>
//   responseSummaries.filter(responseSummary => responseSummary.time);

// const getUnstartedSurveys = responseSummaries =>
//   responseSummaries.filter(responseSummary => !responseSummary.time);

const getTableData = (users, retailers, responseSummaries) => {
  // const claimedRetailerUsersArr = getClaimedRetailerUsers(retailers);
  const claimedRetailersArr = getClaimedRetailers(retailers);
  // console.log(claimedRetailersArr.length);

  return users.map(user => {
    // console.log("user.id", user.id);
    // console.log(claimedRetailerArr);
    const claimedRetailersForUserArr = getClaimedRetailersForUser(
      claimedRetailersArr,
      user.id
    );
    // console.log(claimedRetailersForUserArr.length);
    const responseSummariesForUserArr = getResponsSummariesForUser(
      responseSummaries,
      user.id
    );

    // console.log(responseSummariesForUserArr);
    return {
      id: user.id,
      displayName: user.lastName + ', ' + user.firstName,
      email: user.email,
      retailerCount:
        claimedRetailersForUserArr.length > 0
          ? claimedRetailersForUserArr.length
          : '-',
      surveyStatus:
        responseSummariesForUserArr.length > 0
          ? claimedRetailersForUserArr.length > 0
            ? `${responseSummariesForUserArr.length} started`
            : 'Enabled, not started'
          : '-'
    };
  });
};

const UserTablePaginated = ({ ...props }) => {
  const { auth, classes, retailers, responseSummaries, users } = props;

  if (!auth.uid) return <Redirect to='/admin/signin' />;

  const dataIsLoaded =
    isLoaded(responseSummaries) &&
    !isEmpty(responseSummaries) &&
    isLoaded(users) &&
    !isEmpty(users) &&
    isLoaded(retailers) &&
    !isEmpty(retailers) &&
    users &&
    users.length > 0 &&
    retailers &&
    retailers.length > 0 &&
    responseSummaries &&
    responseSummaries.length > 0
      ? true
      : false;

  // console.log("dataIsLoaded", dataIsLoaded);
  // console.log(users && users.length);
  // console.log(retailers && retailers.length);
  // console.log(responseSummaries && responseSummaries.length);

  const claimedRetailersArr = dataIsLoaded
    ? getClaimedRetailers(retailers)
    : [];
  // console.log(claimedRetailersArr.length);
  // const startedSurveysArr = dataIsLoaded
  //   ? getStartedSurveys(responseSummaries)
  //   : [];

  // console.log(startedSurveysArr.length);

  // console.log(dataIsLoaded && getUnstartedSurveys(responseSummaries).length);

  // console.log("dataIsLoaded", dataIsLoaded);
  const tableData = dataIsLoaded
    ? getTableData(users, retailers, responseSummaries)
    : [];
  // var tableData = [];
  // console.log(tableData);
  // if (dataIsLoaded) {
  //   tableData = getTableData(users, responseSummaries, retailers);
  //   console.log("dataIsLoaded", dataIsLoaded);
  //   console.log(tableData && tableData.length);
  // }
  // const claimedRetailerCount = getClaimedRetailers(retailers);

  return dataIsLoaded ? (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color='primary' icon>
            <h4 className={classes.cardIconTitle}>
              {users && users.length > 0 ? users.length : '(Counting...)'}
              &nbsp;responders have registered.
            </h4>
          </CardHeader>
          <CardBody>
            <div>
              <p>
                Responders have claimed&nbsp;
                {claimedRetailersArr.length > 0
                  ? claimedRetailersArr.length
                  : '(Counting...)'}
                &nbsp;sites
              </p>
              <ReactTable
                data={tableData}
                columns={tableColumns}
                defaultPageSize={25}
                showPaginationTop
                showPaginationBottom={false}
                className='-striped -highlight'
                defaultSorted={[
                  {
                    id: 'diplaName',
                    desc: false
                  }
                ]}
              />
            </div>
          </CardBody>
        </Card>
      </GridItem>{' '}
    </GridContainer>
  ) : (
    <GridContainer>
      <GridItem xs={12}>
        <BeatLoader sizeUnit={'px'} size={30} color={'gray'} />
      </GridItem>
    </GridContainer>
  );
};

const mapStateToProps = state => {
  // console.log(state.firestore.ordered.retailers);
  const allUsers = state.firestore.ordered.users;

  // const role = state.firebase.profile.role && state.firebase.profile.role;
  // if ( role &&role === "manager") {
  // }
  // console.log(allUsers && allUsers.length);
  const filteredUsers = allUsers && allUsers.filter(user => !user.manager);
  // console.log(filteredUsers && filteredUsers.length);

  // console.log(allRetailers && allRetailers.length);
  const allRetailers = state.firestore.ordered.retailers;
  const filteredRetailers =
    allRetailers && allRetailers.filter(retailer => retailer.sandbox !== true);

  const allResponseSummaries = state.firestore.ordered.responseSummaries;
  const filteredResponseSummaries =
    allResponseSummaries &&
    allResponseSummaries.filter(responseSummary => !responseSummary.sandbox);
  // console.log(allResponseSummaries && allResponseSummaries);
  // console.log(filteredResponseSummaries && filteredResponseSummaries);
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    retailers: filteredRetailers,
    users: filteredUsers,
    responseSummaries: filteredResponseSummaries
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // unclaimRetailer: retailer => dispatch(unclaimRetailer(retailer))
  };
};
UserTablePaginated.propTypes = {
  classes: PropTypes.object,
  showGeneric: PropTypes.bool,
  auth: PropTypes.object,
  profile: PropTypes.object,
  retailers: PropTypes.array,
  responseSummaries: PropTypes.array
};
UserTablePaginated.defaultProps = {
  showGeneric: false,
  retailers: [],
  auth: {},
  profile: {}
};

export default withStyles(styles)(
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(props => [
      {
        collection: 'responseSummaries',
        where: [['phase', '==', '4']],
        limit: 300
      },
      { collection: 'retailers', limit: 250, orderBy: ['retailerName', 'asc'] },
      { collection: 'users', limit: 250, orderBy: ['lastName', 'asc'] }
      // { collection: "users", limit: 300, orderBy: ["lastName", "asc"] }
      // {
      //   collection: "retailers",
      //   limit: 250,
      //   orderBy: ["retailerName", "asc"]
      // },
      // { collection: "users", limit: 250, orderBy: ["lastName", "asc"] }
    ])
  )(UserTablePaginated)
);
