import React from "react";
import { FormSpy } from "react-final-form";
import diff from "object-diff";

class SurveyAutoSave extends React.Component {
  static defaultProps = {
    valuesToDebounce: []
  };
  constructor(props) {
    super(props);
    this.state = {
      values: props.values,
      submitting: false
    };
  }

  componentWillReceiveProps(nextProps) {
    const {
      values,
      debounceTime,
      valuesToDebounce,
      valuesNotToDebounce
      // callerIsLoading
    } = nextProps;
    const debouncedValues = {};
    const immediateValues = {};
    let mergedValues = {};

    // if (values.shortCode === "S7UKQ1") {
    //   console.log("componentWillReceiveProps", values);
    //   console.log(this.state.values.txtA && this.state.values.txtA);
    // }

    Object.keys(values).forEach(key => {
      if (~valuesToDebounce.indexOf(key)) {
        debouncedValues[key] = values[key];
      } else if (~valuesNotToDebounce.indexOf(key)) {
        immediateValues[key] = values[key];
      }
    });

    // if (values.shortCode === "S7UKQ1") {
    //   // console.log(debouncedValues);
    //   // console.log(Object.keys(debouncedValues));
    // }
    // if (values.shortCode === "S7UKQ1") {
    // console.log(valuesToDebounce);
    Object.keys(valuesToDebounce).forEach(key => {
      const keyName = valuesToDebounce[key];
      // console.log(keyName);
      if (this.state.values[keyName] && !nextProps.values[keyName]) {
        debouncedValues[keyName] = null;
      }
    });
    // console.log(debouncedValues);
    // console.log(Object.keys(debouncedValues));
    // console.log(Object.keys(debouncedValues).length);
    // }

    if (Object.keys(debouncedValues).length) {
      // if (Object.keys(debouncedValues)) {
      // console.log(
      //   "in SurveyAutosave, debounced, calling save",
      //   Object.keys(debouncedValues)
      // );
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        if (Object.keys(immediateValues).length) {
          mergedValues = {
            ...debouncedValues,
            ...immediateValues
          };
          this.save(mergedValues);
        } else {
          this.save(debouncedValues);
        }
      }, debounceTime);
    } else {
      if (Object.keys(immediateValues).length) {
        this.save(immediateValues);
      }
    }
  }

  save = async values => {
    if (this.promise) {
      await this.promise;
    }

    // const { save, callerIsLoading } = this.props;
    const { save } = this.props;
    // console.log(this.props.save);
    // console.log("callerIsLoading", callerIsLoading);
    // if (!callerIsLoading) {
    const changedValues = Object.keys(values).reduce((result, key) => {
      if (values[key] !== this.state.values[key]) {
        result[key] = values[key];
      }
      return result;
    }, {});
    if (Object.keys(changedValues).length) {
      diff(this.state.values);
      // values have changed
      this.setState(state => ({
        submitting: true,
        values: { ...state.values, ...changedValues }
      }));
      // console.log("gonna call save");
      // console.log("saveObject", changedValues);

      this.promise = save(changedValues);
      await this.promise;
      delete this.promise;
      this.setState({ submitting: false });
    }
    // }
  };

  render() {
    // This component doesn't have to render anything, but it can render
    // submitting state.
    return this.state.submitting ? (
      <div className="submitting">Synchronising with database...</div>
    ) : (
      <div className="submitting">&nbsp;</div>
    );
  }
}

// Make a HOC
// This is not the only way to accomplish auto-save, but it does let us:
// - Use built-in React lifecycle methods to listen for changes
// - Maintain state of when we are submitting
// - Render a message when submitting
// - Pass in debounceTime and save props nicely
export default props => (
  <FormSpy
    {...props}
    subscription={{ values: true }}
    component={SurveyAutoSave}
  />
);
