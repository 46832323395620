import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import LinearProgress from '@material-ui/core/LinearProgress';

const BorderLinearProgress = withStyles({
  root: {
    height: 15,
    backgroundColor: lighten('#ccc', 0.5)
  },
  bar: {
    borderRadius: 0,
    backgroundColor: '#51ae30'
  }
})(LinearProgress);

// const styles = {
//   root: {
//     flexGrow: 1
//   },
//   margin: {
//     // margin: theme.spacing(1)
//   }
// };

const getMaximumPoints = (questions, responseSummary) => {
  // const reserveShortCode = "S3Q1-ynA";
  const reserveShortCode = 'P4S2Q1-ynA'; //added for phase 3
  let maximumPoints = 0;
  responseSummary &&
    questions &&
    questions.forEach(question => {
      if (question.bccOnly) {
        if (responseSummary.bcc) {
          maximumPoints += 1;
        }
      } else if (question.fullOnly) {
        if (responseSummary.full) {
          maximumPoints += 1;
        }
      } else if (
        question.parentQuestionId &&
        (question.parentQuestionId === 'DAs19JufElKcxXOh2EgC' ||
          question.parentQuestionId === 'dLkI12864rPo1LElEMpo')
      ) {
        if (
          !responseSummary[reserveShortCode] ||
          responseSummary[reserveShortCode] !== 'Yes'
        ) {
          maximumPoints += 1;
        }
      } else {
        maximumPoints += 1;
      }
    });
  return maximumPoints;
};

const getScoreForQuestion = (question, responseSummary) => {
  // const filteredResponses =
  //   responses &&
  //   responses.length > 0 &&
  //   responses.filter(response => response.qId === questionId);

  const responseYnA = question && `${question.shortCode}-ynA`;
  const responseTxtA = question && `${question.shortCode}-txtA`;
  //   const wallboxAnswer = 'P3S4UKQ2-ynA'; //added for phase 3
  const wallboxAnswer = 'P4S4Q3-ynA'; //added for phase 4
  // const responseDtA = question && `${question.shortCode}-dtA`;

  if (responseSummary) {
    // console.log(response.ynA);
    // console.log(question.shortCode);
    if (question.optionalQuestion) {
      //   console.log(question.shortCode + ' ' + 'optional');
      if (
        responseSummary[responseYnA] &&
        (responseSummary[responseYnA] === 'Yes' ||
          responseSummary[responseYnA] === 'No')
      ) {
        return 1;
      } else {
        return 0;
      }
    } else if (question.twoAnswersIfYes) {
      //   console.log(question.shortCode + ' ' + 'twoAnswersIfYes');
      if (
        responseSummary[responseYnA] === 'Yes' &&
        responseSummary[responseTxtA]
      ) {
        return 1;
      } else {
        return 0;
      }
    } else if (question.twoAnswersIfNo) {
      //   console.log(question.shortCode + ' ' + 'twoAnswersIfNo');
      // console.log(question, responseSummary);
      if (
        responseSummary[responseYnA] &&
        responseSummary[responseYnA] === 'Yes'
      ) {
        return 1;
      } else if (
        responseSummary[responseYnA] &&
        responseSummary[responseYnA] === 'No' &&
        responseSummary[responseTxtA] &&
        responseSummary[responseTxtA] !== ''
      ) {
        return 1;
      } else {
        return 0;
      }
    } else if (
      !question.yesNoQuestion &&
      question.textQuestion &&
      responseSummary[responseTxtA] &&
      responseSummary[responseTxtA] !== ''
    ) {
      // console.log(question, responseSummary);
      //   console.log(question.shortCode + ' ' + responseSummary[responseTxtA]);
      return 1;
    } else if (
      responseSummary[responseYnA] &&
      responseSummary[responseYnA] === 'Yes'
    ) {
      //   console.log(question.shortCode + ' ' + responseSummary[responseYnA]);
      return 1;
    } else {
      //   console.log(question.shortCode + ' other 0');
      return 0;
    }
  } else {
    return 0;
  }
};

const getPoints = (questions, responseSummary) => {
  let point = 0;
  let points = 0;
  questions.forEach(question => {
    // console.log(getScoreForQuestion(question, responseSummary));
    point = getScoreForQuestion(question, responseSummary);
    // console.log(point);
    points += point;
  });
  return points;
};

const PercentageReady = ({ ...props }) => {
  const { questions, responseSummary } = props;
  // const classes = useStyles();
  const dataIsLoaded =
    questions && questions.length > 0 && responseSummary ? true : false;

  if (dataIsLoaded) {
    const maximumPoints = getMaximumPoints(questions, responseSummary);
    const points = getPoints(questions, responseSummary);
    // console.log(points + ' out of ' + maximumPoints);
    const percentageComplete = Math.round((100 * points) / maximumPoints);
    return <Fragment>{` (${percentageComplete}% compliance)`}</Fragment>;
  } else {
    return null;
  }
};

PercentageReady.propTypes = {
  classes: PropTypes.object,
  phase: PropTypes.string, // added for phase III
  questions: PropTypes.array,
  responseSummary: PropTypes.object
};

export default PercentageReady;
