import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { compose } from "redux";
// import { firestoreConnect, isLoaded, isEmpty } from "react-redux-firebase";
import PropTypes from 'prop-types';
import diff from 'object-diff';
import { Form } from 'react-final-form';
import { Field } from 'react-final-form';
// import { Field } from "react-final-form-html5-validation";
// View and components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
// import LoadSaveReinitializeForm from "views/Responses/ResponseLoadSaveReinitializeFormSingle";
import SurveyAutoSave from 'views/Surveys/SurveyAutoSave';
import Danger from 'components/Typography/Danger';
// Actions
import { updateResponse } from '_store/actions/responseActions';
// Styling
import { withStyles } from '@material-ui/core/styles';
import regularFormsStyle from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import { TextField, Radio } from 'final-form-material-ui';
import { FormControlLabel } from '@material-ui/core';
import TBAIcon from '@material-ui/icons/PauseCircleOutline';
import YesIcon from '@material-ui/icons/CheckCircleOutline';
import NoIcon from '@material-ui/icons/HighlightOff';

const styles = {
  ...regularFormsStyle,
  yesIcon: {
    width: '16px',
    height: '16px',
    fontSize: '16px',
    viewBox: '0 0 16 16',
    lineHeight: '16px',
    backgroundColor: 'white !important',
    color: '#51ae30 !important',
    border: '2px solid #51ae30',
    borderRadius: '50%'
  },
  noIcon: {
    width: '16px',
    height: '16px',
    fontSize: '16px',
    viewBox: '0 0 16 16',
    lineHeight: '16px',
    color: '#e4002c !important',
    backgroundColor: 'white !important',
    border: '2px solid #e4002c',
    borderRadius: '50%'
  },
  noButOKIcon: {
    width: '16px',
    height: '16px',
    fontSize: '16px',
    viewBox: '0 0 16 16',
    lineHeight: '16px',
    color: '#51ae30 !important',
    backgroundColor: 'white !important',
    border: '2px solid #51ae30',
    borderRadius: '50%'
  },
  tbaIcon: {
    color: 'gray',
    width: '16px',
    height: '16px',
    fontSize: '16px',
    lineHeight: '16px',
    backgroundColor: 'white !important',
    border: '2px solid gray',
    borderRadius: '50%'
  },
  unansweredIcon: {
    color: 'gray',
    width: '16px',
    height: '16px',
    fontSize: '16px',
    lineHeight: '16px',
    backgroundColor: 'white !important',
    border: '1px solid #333',
    borderRadius: '50%'
  }
};
// import classNames from "classnames";

// const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// const load = async () => {
//   await sleep(300);
//   return {
//     notes: "",
//     retailerNumber: "",
//     bcc: false
//   };
// };
const objectIsEmpty = obj => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

const Condition = ({ when, is, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
);

const Error = ({ name }) => {
  return (
    <Field
      name={name}
      subscription={{ error: true }}
      render={({ meta: { touched, error } }) =>
        error ? <Danger>{error}</Danger> : null
      }
    />
  );
};

// const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

class SurveyQuestionForm extends Component {
  constructor(props) {
    super(props);
    // console.log(props);
    this.getRecordStub = this.getRecordStub.bind(this);
    this.debouncedSave = this.debouncedSave.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = { loading: true };
  }

  componentDidMount() {
    // console.log("in componentDidMount");
    if (this.props.question && this.props.response) {
      // console.log("yes this.props.response");
      // console.log(this.props.response);
      this.setState({
        loading: false,
        response: this.props.response
      });
    } else if (this.props.question) {
      this.setState({
        loading: false
      });
    }
    // if (this.props) {
    //   console.log("ppppppp ppppp ppppp componentDidMount");
    //   console.log(this.props);
    // }
    // const { metaData, section.id, question, response } = this.props;

    // this.setState({ ...this.state, response: recordStub });
    // this.getStarterResponse();
  }

  componentDidUpdate(prevProps) {
    // console.log("in componentDidUpdate");
    // console.log(prevProps.response);
    // console.log(this.props.response);
    if (
      typeof prevProps.response !== 'undefined' &&
      typeof this.props.response === 'undefined'
      // was not empty, now is empty
    ) {
      // so store it in state
      this.setState({
        loading: false
        // response: { ynA: "TBA" }
      });
    } else if (
      typeof prevProps.response === 'undefined' &&
      typeof this.props.response !== 'undefined'
      // was empty, now with a response
    ) {
      // so store it in state
      this.setState({
        loading: false,
        response: this.props.response
      });
    } else if (
      typeof prevProps.response !== 'undefined' &&
      typeof this.props.response !== 'undefined'
      // was NOT empty, now with a response coming - but has it changed?
    ) {
      // let's see if it's changed so we don't go into a loop
      const changes = diff(prevProps.response, this.props.response);

      if (!objectIsEmpty(changes) && Object.keys(changes).length > 1) {
        // yes, there are changes, but ignore timestamp
        // console.log("changes in componentDidUpdate");
        // console.log(this.props.response);
        // console.log(changes);
        const responseForState = this.props.response;
        // if (!responseForState.ynA) {
        //   responseForState["ynA"] = "TBA";
        // }
        // console.log(responseForState);
        this.setState({
          loading: false,
          response: responseForState
        });
      } else {
        // this.setState({
        //   loading: false
        // });
        // set state create a loop
        // console.log("no changes in componentDidUpdate");
      }
      // console.log(this.state);
    }
  }

  // componentWillReceiveProps(nextProps) {
  //   console.log("in componentWillReceiveProps");
  //   console.log(nextProps);
  //   // const { question, response } = nextProps;
  //   // console.log(question && question.id);
  //   // console.log(response && response.qId);
  //   // this.setInitialValues(response);
  // }
  getRecordStub() {
    const recordStub = this.props.response
      ? { ...this.props.response, respId: this.props.response.id }
      : {
          ...this.props.metaData,
          // ynA: "TBA",
          sctnId: this.props.section.id,
          qId: this.props.question.id,
          phase: this.props.question.phase, // added for phase III
          shortCode: this.props.question.shortCode,
          applies: true
        };
    return recordStub;
  }

  async debouncedSave(values) {
    // console.log("debouncedSave");
    // console.log(saveObject);

    if (values) {
      // console.log(this.props.response && this.props.response);
      // console.log("8888888 Saving", values);
      // console.log(values);
      const recordToSave = this.getRecordStub();
      Object.keys(values).forEach(key => {
        recordToSave[key] = values[key];
      });
      recordToSave.time = new Date();
      // console.log(recordToSave);
      // const section = this.createStub;
      // const record = this.setUpRecord();
      // console.log(this.props && this.props);
      // const { metaData } = this.props;
      // console.log(this.props);
      // console.log("calling updateResponse");
      // console.log(recordToSave);
      // this.updateResponse(recordToSave);
      console.log('calling updateResponse');
      this.props.updateResponse(recordToSave);
    }
  }

  saveData(values) {
    // console.log("in saveData");
    // console.log(values);
    // props.updateResponse(values);
    return true;
  }

  // validate(values) {
  //   console.log(values);
  //   console.log(this.props.question);
  //   const twoAnswersIfNoErrorMsg =
  //     "You need to provide this if your answer is no.";
  //   const twoAnswersIfYesErrorMsg =
  //     "You need to provide this if your answer is yes.";
  //   const noteErrorMsg =
  //     "From June, you'll need to provide notes & actions if the answer is not yes.";

  //   const errors = {};
  //   if (values.ynA === "No") {
  //     //   if (this.props.question && this.props.question.twoAnswersIfNo) {
  //     //     if (!values.txtA) {
  //     //       console.log(twoAnswersIfNoErrorMsg);
  //     //       errors.txtA = twoAnswersIfNoErrorMsg;
  //     //     }
  //     //   } else if (!values.note) {
  //     //     console.log(noteErrorMsg);
  //     //     errors.note = noteErrorMsg;
  //     //   }
  //     // } else if (values.ynA === "Yes") {
  //     //   if (this.props.question && this.props.question.twoAnswersIfYes) {
  //     //     if (!values.txtA) {
  //     //       console.log(twoAnswersIfYesErrorMsg);
  //     //       errors.txtA = twoAnswersIfYesErrorMsg;
  //     //     }
  //     //   }
  //   }

  //   return errors;
  // }

  handleChange(values) {
    console.log('in handleChange');
    console.log(values);
    // const noteErrorMsg =
    //   "From June, you'll need to provide notes & actions if the answer is not yes.";

    // const errors = {};
    // if (values.ynA === "No") {
    //   if (!values.note) {
    //     // console.log(noteErrorMsg);
    //     errors.note = noteErrorMsg;
    //   }
    // }

    // return errors;
  }

  // Error(name) {
  //   return (
  //     <Field name={name} subscription={{ error: true }}>
  //       {({ meta: { error } }) => (error ? <span>{error}</span> : null)}
  //     </Field>
  //   );
  // }

  render() {
    const { classes, section, question, metaData, response } = this.props;

    // console.log(this.state);
    // console.log(metaData);
    // console.log(response && response);
    // console.log(section);

    const recordStub = response
      ? { ...response, respId: response.id }
      : {
          ...metaData,
          // ynA: "TBA",
          sctnId: section.id,
          qId: question.id,
          applies: true
        };
    // console.log(recordStub);
    // if (response && response.id) {
    //   recordStub["respId"] = response.id;
    // }
    // console.log(recordStub);

    // const wallboxShortcode = 'P3S4UKQ2';
    const wallboxShortcode = 'P4S4Q3'; // for phase 3
    // if (question.shortCode === wallboxShortcode) {
    //   console.log("in SurveyQuestionForm", question);
    // }

    const loadingText = <div className='loading'>Loading data ...</div>;

    // need to halt until this.state.loading === false otherwise the form starts seeing differenece and saving records

    return (
      <GridContainer>
        {question &&
        question.id &&
        recordStub &&
        this.state.loading === false ? (
          <GridItem xs={12}>
            <Form
              onSubmit={this.debouncedSave() /* NOT USED, but required */}
              initialValues={this.state.response}
              subscription={{} /* No need to subscribe to anything */}
              // validate={this.validate}
              validate={values => {
                const twoAnswersIfNoErrorMsg = `Please record the ${
                  question.textPrompt
                    ? question.textPrompt.toLowerCase()
                    : 'details'
                }, since your answer is no.`;
                const twoAnswersIfYesErrorMsg = `Please record the ${
                  question.textPrompt
                    ? question.textPrompt.toLowerCase()
                    : 'details'
                }, since your answer is yes.`;
                const noteErrorMsg =
                  'Please record your planned actions to get the answer to yes.';

                const errors = {};

                if (values.ynA === 'No') {
                  if (question.twoAnswersIfNo) {
                    if (!values.txtA) {
                      errors.txtA = twoAnswersIfNoErrorMsg;
                    }
                  } else if (!values.note) {
                    if (!question.hasOwnProperty('optionalQuestion')) {
                      errors.note = noteErrorMsg;
                    }
                  }
                } else if (values.ynA === 'Yes') {
                  if (question.twoAnswersIfYes) {
                    if (!values.txtA) {
                      errors.txtA = twoAnswersIfYesErrorMsg;
                    }
                  }
                } else if (values.ynA === 'TBA') {
                  if (!values.note) {
                    if (!question.hasOwnProperty('optionalQuestion')) {
                      errors.note = noteErrorMsg;
                    }
                  }
                }
                return errors;
              }}
              render={({
                handleSubmit,
                reset,
                submitting,
                pristine,
                invalid,
                valid,
                dirty,
                values,
                input
              }) => (
                <div className={'form'}>
                  <GridContainer>
                    <GridItem xs={12}>
                      <SurveyAutoSave
                        debounceTime={2000}
                        save={this.debouncedSave}
                        valuesToDebounce={['txtA', 'dtA', 'note']}
                        valuesNotToDebounce={['ynA']}
                        callerIsLoading={this.state.loading}
                      />
                      {response && response.qId ? (
                        <h6>
                          Showing your recorded response...
                          <br />
                        </h6>
                      ) : null}
                    </GridItem>
                    {question.yesNoQuestion ? (
                      <GridItem xs={12}>
                        <GridContainer>
                          <GridItem xs={12} sm={4} md={3}>
                            <FormControlLabel
                              label='I will find out'
                              control={
                                <Field
                                  name='ynA'
                                  id='radioTBA'
                                  component={Radio}
                                  type='radio'
                                  value={'TBA'}
                                  // onChange={handleChange(values)}
                                  // onChange={(val, prevVal) => handleChange(val, prevVal)}
                                  // onChange={(name, val,prevVal) => handleChange}
                                  // onChange={() => handleChange}
                                  onChange={() => this.handleChange}
                                  icon={
                                    <FiberManualRecord
                                      className={classes.radioUnchecked}
                                    />
                                  }
                                  checkedIcon={
                                    <TBAIcon className={classes.tbaIcon} />
                                  }
                                />
                              }
                            />
                          </GridItem>
                          <GridItem xs={12} sm={4} md={3}>
                            <FormControlLabel
                              label='Yes'
                              control={
                                <Field
                                  name='ynA'
                                  id='radioYes'
                                  component={Radio}
                                  type='radio'
                                  value={'Yes'}
                                  // onChange={handleChange(values)}
                                  // onChange={(val, prevVal) => handleChange(val, prevVal)}
                                  // onChange={(name, val,prevVal) => handleChange}
                                  // onChange={() => handleChange}
                                  onChange={() => this.handleChange}
                                  icon={
                                    <FiberManualRecord
                                      className={classes.radioUnchecked}
                                    />
                                  }
                                  checkedIcon={
                                    <YesIcon className={classes.yesIcon} />
                                  }
                                />
                              }
                            />
                          </GridItem>
                          <GridItem xs={12} sm={4} md={3}>
                            <FormControlLabel
                              label='No'
                              control={
                                <Field
                                  name='ynA'
                                  id='radioNo'
                                  component={Radio}
                                  type='radio'
                                  value={'No'}
                                  // onChange={handleChange(values)}
                                  // onChange={(val, prevVal) => handleChange(val, prevVal)}

                                  onChange={() => this.handleChange}
                                  icon={
                                    <FiberManualRecord
                                      className={classes.radioUnchecked}
                                    />
                                  }
                                  checkedIcon={
                                    question.optionalQuestion ? (
                                      <NoIcon className={classes.noButOKIcon} />
                                    ) : (
                                      <NoIcon className={classes.noIcon} />
                                    )
                                  }
                                />
                              }
                            />
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                    ) : null}
                    {question.textQuestion ? (
                      question.twoAnswersIfYes ? (
                        <Condition when='ynA' is='Yes'>
                          <div>
                            <GridItem xs={12}>
                              <Field
                                name={'txtA'}
                                component={TextField}
                                type='text'
                                multiline={true}
                                label={question.textPrompt}
                                // onChange={handleChange(values)}
                                // onChange={(name, val,prevVal) => console.log(name, val,prevVal)}
                                // onChange={() => handleChange}
                                fullWidth
                              />
                              <br />
                              <Error name='txtA' />
                            </GridItem>
                            <br /> <br />
                          </div>
                        </Condition>
                      ) : question.twoAnswersIfNo ? (
                        <Condition when='ynA' is='No'>
                          <div>
                            <GridItem xs={12}>
                              <Field
                                name={'txtA'}
                                component={TextField}
                                type='text'
                                multiline={true}
                                label={question.textPrompt}
                                // onChange={handleChange(values)}
                                // onChange={(name, val,prevVal) => console.log(name, val,prevVal)}
                                // onChange={() => handleChange}
                                fullWidth
                              />
                              <br />
                              <Error name='txtA' />
                            </GridItem>
                            <br /> <br />
                          </div>
                        </Condition>
                      ) : (
                        <div>
                          <GridItem xs={12}>
                            <Field
                              name={'txtA'}
                              component={TextField}
                              type='text'
                              multiline={true}
                              label={question.textPrompt}
                              // onChange={handleChange(values)}
                              // onChange={(name, val,prevVal) => console.log(name, val,prevVal)}
                              // onChange={() => handleChange}
                              fullWidth
                            />
                          </GridItem>
                          <br /> <br />
                        </div>
                      )
                    ) : null}

                    <GridItem xs={12}>
                      <GridContainer>
                        <GridItem xs={12} lg={8}>
                          <h5>
                            <strong>My Notes and Actions:</strong>
                          </h5>
                          <br />
                          <Field
                            name={'note'}
                            component={TextField}
                            type='text'
                            multiline={true}
                            //placeholder="Notes & planned actions if 'No' selected."
                            // onChange={handleChange(values)}
                            // onChange={(name, val,prevVal) => console.log(name, val,prevVal)}
                            // onChange={() => handleChange}
                            fullWidth
                            //minLength={4}
                          />
                          <br />
                          <Error name='note' />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </div>
              )}
            />
          </GridItem>
        ) : (
          loadingText
        )}
      </GridContainer>
    );
  }
}

SurveyQuestionForm.propTypes = {
  classes: PropTypes.object,
  section: PropTypes.object,
  question: PropTypes.object,
  response: PropTypes.object,
  updateResponse: PropTypes.func
};

SurveyQuestionForm.defaultProps = {
  classes: {},
  section: {},
  question: {}
};

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateResponse: response => dispatch(updateResponse(response))
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(SurveyQuestionForm)
);
