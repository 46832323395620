const excelRowTemplate = [
  { column: "A", method: "spacer", source: "" },
  { column: "B", method: "spacer", source: "" },
  { column: "C", method: "spacer", source: "" },
  { column: "D", method: "brand", source: "Volkswagen" },
  { column: "E", method: "retailerNumber", source: "retailerNumber" },
  { column: "F", method: "retailerName", source: "retailerName" },
  { column: "G", method: "spacer", source: "" },
  { column: "H", method: "spacer", source: "" },
  { column: "I", method: "spacer", source: "" },
  { column: "J", method: "responderName", source: "responderName" },
  { column: "K", method: "responderEmail", source: "responderEmail" },
  { column: "L", method: "spacer", source: "" },
  { column: "M", method: "spacer", source: "" },
  { column: "N", method: "spacer", source: "" },

  { column: "O", method: "ynA", source: "P3S1Q1" },
  { column: "P", method: "comment", source: "P3S1Q1" },
  { column: "Q", method: "spacer", source: "" },

  { column: "R", method: "ynA", source: "P3S2Q1" },
  { column: "S", method: "comment", source: "P3S2Q1" },
  { column: "T", method: "ynA", source: "P3S2Q2" },
  { column: "U", method: "comment", source: "P3S2Q2" },
  { column: "V", method: "spacer", source: "" },

  { column: "W", method: "ynA", source: "P3S3Q1" },
  { column: "X", method: "comment", source: "P3S3Q1" },
  { column: "Y", method: "ynA", source: "P3S3Q2" },
  { column: "Z", method: "comment", source: "P3S3Q2" },
  { column: "AA", method: "spacer", source: "" },

  { column: "AB", method: "ynA", source: "P3S5Q1" },
  { column: "AC", method: "comment", source: "P3S5Q1" },
  { column: "AD", method: "ynA", source: "P3S5Q2" },
  { column: "AE", method: "comment", source: "P3S5Q2" },
  { column: "AF", method: "spacer", source: "" },

  { column: "AG", method: "ynA", source: "P3S6Q1" },
  { column: "AH", method: "comment", source: "P3S6Q1" },
  { column: "AI", method: "ynA", source: "P3S6Q2" },
  { column: "AJ", method: "comment", source: "P3S6Q2" },
  { column: "AK", method: "ynA", source: "P3S6Q3" },
  { column: "AL", method: "comment", source: "P3S6Q3" },
  { column: "AM", method: "ynA", source: "P3S6Q4" },
  { column: "AN", method: "comment", source: "P3S6Q4" },
  { column: "AO", method: "ynA", source: "P3S6Q5" },
  { column: "AP", method: "comment", source: "P3S6Q5" },
  { column: "AQ", method: "ynA", source: "P3S6Q6" },
  { column: "AR", method: "comment", source: "P3S6Q6" },
  { column: "AS", method: "ynA", source: "P3S6Q7" },
  { column: "AT", method: "comment", source: "P3S6Q7" },
  { column: "AU", method: "ynA", source: "P3S6Q8" },
  { column: "AV", method: "comment", source: "P3S6Q8" },
  { column: "AW", method: "ynA", source: "P3S6Q9" },
  { column: "AX", method: "comment", source: "P3S6Q9" },
  { column: "AY", method: "ynA", source: "P3S6Q10" },
  { column: "AZ", method: "comment", source: "P3S6Q10" },
  { column: "BB", method: "spacer", source: "" },

  { column: "BB", method: "ynA", source: "P3S7Q1" },
  { column: "BC", method: "comment", source: "P3S7Q1" },
  { column: "BD", method: "ynA", source: "P3S7Q2" },
  { column: "BE", method: "comment", source: "P3S7Q2" },
  { column: "BF", method: "ynA", source: "P3S7Q3" },
  { column: "BG", method: "comment", source: "P3S7Q3" }
];
export default excelRowTemplate;
