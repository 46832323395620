import actionConstants from "_store/constants/actionConstants";

export const updateResponse = response => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    // const profile = getState().firebase.profile;
    // const authorId = getState().firebase.auth.uid;
    // this is the bit updating the database
    // console.log(")))))))))))))) in updateResponse", response);
    if (response && response.respId) {
      // console.log(response.respId);
      const docId = response.respId;
      const stuffToSave = response;
      delete stuffToSave.respId;

      firestore
        .collection("responses")
        .doc(docId)
        .set({
          ...stuffToSave
        })
        .then(() => {
          // console.log(")))))))))))) firestore response record updated");
          // console.log(response);
          dispatch({
            type: actionConstants.CREATE_RESPONSE_SUCCESS
          });
        })
        .catch(err => {
          console.log("firestore Error", err);
          dispatch({ type: actionConstants.CREATE_RESPONSE_ERROR, err });
        });
    } else {
      firestore
        .collection("responses")
        .add({
          ...response
        })
        .then(() => {
          console.log(")))))))))))) firestore response record added");
          // console.log(response);
          dispatch({
            type: actionConstants.CREATE_RESPONSE_SUCCESS
          });
        })
        .catch(err => {
          console.log("firestore Error", err);
          dispatch({ type: actionConstants.CREATE_RESPONSE_ERROR, err });
        });
    }
  };
};
