import React, { Component } from 'react';
// import MuiThemeProvider from "@material-ui/styles/MuiThemeProvider";
import { withStyles } from '@material-ui/core/styles';
import extendedTablesStyle from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle';
import { TextField } from '@material-ui/core';
import RegularButton from 'components/CustomButtons/Button';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { signIn } from '../../_store/actions/authActions';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Danger from 'components/Typography/Danger';
import { Typography } from '@material-ui/core';

// const styles = theme => ({});

class SignIn extends Component {
  state = {
    email: '',
    password: ''
  };

  handleChange = e => {
    // console.log(e.target.id);
    // console.log(e.target.value);
    this.setState({
      // eg. password: password typed in
      [e.target.id]: e.target.value
    });
  };
  handleSubmit = e => {
    e.preventDefault();
    // console.log(this.props.logIn);
    // console.log(this.state);
    this.props.signIn(this.state);
    // if (this.props.auth && this.props.auth.uid) {
    //   console.log("already signed in");
    // this.props.history.push("/admin/myretailers");
    // }
  };

  render() {
    const { authError, auth, classes } = this.props;

    // const sleep = milliseconds => {
    //   return new Promise(resolve => setTimeout(resolve, milliseconds));
    // };

    // sleep(500).then(() => {
    //   console.log("BACK FROM SLEEP");
    //   if (auth.uid) return <Redirect to="/" />;
    // });
    // if (auth.uid) {
    //   if (this.props.profile && !this.props.profile.manager) {
    //     console.log("already signed in");
    //     return <Redirect to="/admin/myretailers" />;
    //     // window.location.href = "/survey/myretailers";
    //     // return <Redirect to="/survey/myretailers" />;
    //     // browserHistory.push("/survey/myretailers");
    //   } else {
    //     return <Redirect to="/survey/myretailers" />;
    //   }
    // }
    if (auth.uid) {
      console.log('already signed in');
      return <Redirect to='/admin/myretailers' />;
      // } else {
      //   return <Redirect to="/admin/signin" />;
    }
    // if (!authError) {
    //   console.log("no auth error");
    //   return <Redirect to="/admin/myretailers" />;
    //   // } else {
    //   //   return <Redirect to="/admin/signin" />;
    // }

    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color='rose' icon>
              <h4 className={classes.cardIconTitle}>
                Phase II ended 30/09/2019. Phase III coming soon.
              </h4>
            </CardHeader>
            <CardBody>
              <Typography component='p'>
                Thanks for completing the phase III survey checklist, which was
                about creating an action plan for your site.
              </Typography>
              <Typography component='p'>
                Phase IV is now live. This phase is to confirm that you have
                everything in place in terms of infrastructure and training to
                align with the new contracts that go live on 1st April and the
                market introduction of ID.3. Like the last phase, your answers
                should be updated by the end of each month from January through
                April 2020.
              </Typography>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <div>
                Not registered yet?&nbsp;
                <a href='/admin/signup'>Sign up</a>
              </div>

              <form xs={12} onSubmit={this.handleSubmit} className='white'>
                <h5 className='grey-text text-darken-3'>Sign in</h5>
                <TextField
                  id='email'
                  type='email'
                  label='Email'
                  autoComplete='username'
                  className={classes.textField}
                  placeholder='Use your corporate email'
                  helperText='Use your corporate email address, not google, yahoo etc'
                  fullWidth
                  required={true}
                  onChange={this.handleChange}
                />
                <br />
                <br />
                <TextField
                  id='password'
                  label='Password'
                  className={classes.textField}
                  type='password'
                  fullWidth
                  autoComplete='current-password'
                  required={true}
                  onChange={this.handleChange}
                />
                <br /> <br />
                Forgot your password?
                <br />
                No problem.&nbsp;
                <a href='/admin/resetpassword'>Reset password</a>
                <br /> <br />
                <RegularButton
                  label='Sign in'
                  variant='contained'
                  color='primary'
                  className={classes.button}
                  onClick={this.handleSubmit}
                  autoFocus={true}
                >
                  Sign in
                </RegularButton>
                <Danger>{authError ? <p>{authError}</p> : null}</Danger>
              </form>
              <br />
              <br />
              <div>
                Problem? Please contact &nbsp;
                <a href='mailto:evreadiness@helpfulconsultants.com?subject=Survey'>
                  evreadiness@helpfulconsultants.com
                </a>
                .
              </div>
            </CardBody>{' '}
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = state => {
  console.log('in signin', state.auth.authError);
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth
  };
};
const mapDispatchToProps = dispatch => {
  return {
    signIn: creds => dispatch(signIn(creds))
  };
};

export default withStyles(extendedTablesStyle)(
  connect(mapStateToProps, mapDispatchToProps)(SignIn)
);
