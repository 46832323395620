// import ResourceTable from "views/Questions/ResourceTable";
// import ResourceList from "views/Questions/ResourceList";
// import QuestionsBySection from "views/Questions/QuestionsBySection";
import MyRetailers from "views/Retailers/MyRetailers";
import ClaimRetailers from "views/Retailers/ClaimRetailers";
// import ClaimedRetailersTable from "views/Retailers/ClaimedRetailersTable";
// import UnclaimedRetailersTable from "views/Retailers/UnclaimedRetailersTable";
// import RetailersTableWithClaimBtn from "views/Retailers/RetailersTableWithClaimBtn";
import RetailerProgressTablePaginated from "views/Retailers/RetailerProgressTablePaginated";
import UserTablePaginated from "views/Users/UserTablePaginated";
// import ResponsePanels from "views/Responses/ResponsePanels";
// import ResponseForm from "views/Responses/ResponseForm";
// import ResponsePanelsNew from "views/Responses/ResponsePanelsNew";
// import ResponsePanelsLatest from "views/Responses/ResponsePanelsLatest";
import SurveyAccordian from "views/Surveys/SurveyAccordian";
import RetailerResponseDetails from "views/Reports/RetailerResponseDetails";
// import SurveyAccordian from "views/Survey/SurveyAccordian";
// import SurveySection from "views/Survey/SurveySection";
// import ResponseFormNew from "views/Responses/ResponseFormNew";
// import ResponseDemo from "views/Responses/ResponseDemo";
import RegularForms from "views/Forms/RegularForms";
import ChangePassword from "views/Auth/ChangePassword";
// import RegularFormsTest from "views/Forms/RegularFormsTest";
// import SignedOut from "views/Auth/SignedOut";

// @material-ui/icons

// import LockIcon from "@material-ui/icons/Lock";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import UsersIcon from "@material-ui/icons/SupervisorAccount";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import StoreIcon from "@material-ui/icons/Store";
import TocIcon from "@material-ui/icons/Toc";

var dashRoutes = [
  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   rtlName: "لوحة القيادة",
  //   icon: DashboardIcon,
  //   component: Dashboard,
  //   layout: "/admin"
  // },
  // {
  //   path: "/widgets",
  //   name: "Widgets",
  //   rtlName: "لوحة القيادة",
  //   icon: WidgetsIcon,
  //   component: Widgets,
  //   layout: "/admin"
  // },
  // {
  //   path: "/charts",
  //   name: "Charts",
  //   rtlName: "لوحة القيادة",
  //   icon: Timeline,
  //   component: Charts,
  //   layout: "/admin"
  // },
  // {
  //   path: "/calendar",
  //   name: "Calendar",
  //   rtlName: "لوحة القيادة",
  //   icon: DateRange,
  //   component: Calendar,
  //   layout: "/admin"
  // },
  // {
  //   path: "/home",
  //   name: "Home",
  //   rtlName: "لوحة القيادة",
  //   icon: HomeIcon,
  //   component: MyRetailers,
  //   layout: "/admin"
  // },
  {
    path: "/myretailers/responses/:retailer/:phase",
    name: "Retailer Report",
    rtlName: "لوحة القيادة",
    icon: TocIcon,
    component: RetailerResponseDetails,
    layout: "/admin",
    linkNeeded: false
  },
  {
    path: "/myretailers/survey/:retailer/:phase",
    name: "Survey",
    rtlName: "لوحة القيادة",
    icon: TocIcon,
    component: SurveyAccordian,
    layout: "/admin",
    linkNeeded: false
  },
  {
    path: "/myretailers/",
    name: "My Retailers",
    rtlName: "لوحة القيادة",
    icon: StoreIcon,
    component: MyRetailers,
    layout: "/admin"
  },
  {
    path: "/claimretailers",
    name: "Claim Retailers",
    rtlName: "لوحة القيادة",
    icon: AddCircleIcon,
    component: ClaimRetailers,
    layout: "/admin"
  },
  // {
  //   path: "/retailerswithbtn",
  //   name: "Retailers with Button",
  //   rtlName: "لوحة القيادة",
  //   icon: AddCircleIcon,
  //   component: RetailersTableWithClaimBtn,
  //   layout: "/admin"
  // },
  // {
  //   path: "/claimedretailers",
  //   name: "Claimed Retailers",
  //   rtlName: "لوحة القيادة",
  //   icon: StoreIcon,
  //   component: ClaimedRetailersTable,
  //   layout: "/admin"
  // },
  // {
  //   path: "/sectionlist",
  //   name: "Survey Questions",
  //   rtlName: "لوحة القيادة",
  //   icon: TocIcon,
  //   component: QuestionsBySection,
  //   layout: "/admin",
  //   role: "manager"
  // },
  // {
  //   path: "/response",
  //   name: "Survey",
  //   rtlName: "لوحة القيادة",
  //   icon: TocIcon,
  //   component: ResponseForm,
  //   layout: "/admin"
  // },
  // {
  //   path: "/responsedemo",
  //   name: "Final Form Demo",
  //   rtlName: "لوحة القيادة",
  //   icon: TocIcon,
  //   component: ResponseDemo,
  //   layout: "/admin",
  //   linkNeeded: true
  // },

  // {
  //   path: "/responsepanels",
  //   name: "Survey Demo",
  //   rtlName: "لوحة القيادة",
  //   icon: TocIcon,
  //   component: ResponsePanels,
  //   layout: "/admin"
  // },
  // {
  //   path: "/surveyform/:retailer",
  //   name: "Survey Demo - Latest",
  //   rtlName: "لوحة القيادة",
  //   icon: TocIcon,
  //   component: ResponsePanelsLatest,
  //   layout: "/admin"
  // },
  // {
  //   path: "/responsepanelsnew",
  //   name: "Survey Demo",
  //   rtlName: "لوحة القيادة",
  //   icon: TocIcon,
  //   component: ResponsePanelsNew,
  //   layout: "/admin",
  //   linkNeeded: true,
  // },
  // {
  //   path: "/responseformnew",
  //   name: "DEv Frorm",
  //   rtlName: "لوحة القيادة",
  //   icon: TocIcon,
  //   component: ResponseFormNew,
  //   layout: "/admin"
  // },
  // {
  //   path: "/resources",
  //   name: "Help Resources",
  //   rtlName: "لوحة القيادة",
  //   icon: SubjectIcon,
  //   component: ResourceList,
  //   layout: "/admin"
  // },
  {
    path: "/userlist/:phase",
    name: "Responders & Progress",
    rtlName: "لوحة القيادة",
    icon: UsersIcon,
    component: UserTablePaginated,
    layout: "/admin",
    linkNeeded: true
  },
  // {
  //   path: "/userlist",
  //   name: "Users",
  //   rtlName: "لوحة القيادة",
  //   icon: UsersIcon,
  //   component: UserTablePaginated,
  //   layout: "/admin",
  //   role: "manager"
  // },
  // {
  //   path: "/retailerstatus",
  //   name: "Retailer Progress",
  //   rtlName: "لوحة القيادة",
  //   icon: StoreIcon,
  //   component: RetailerStatusTable,
  //   layout: "/admin"
  // },
  {
    path: "/retailerprogress/responses/:retailer",
    name: "Retailer Report",
    rtlName: "لوحة القيادة",
    icon: TocIcon,
    component: RetailerResponseDetails,
    layout: "/admin",
    linkNeeded: false
  },
  {
    path: "/retailerprogress/:phase",
    name: "Retailers & Progress",
    rtlName: "لوحة القيادة",
    icon: StoreIcon,
    component: RetailerProgressTablePaginated,
    layout: "/admin",
    linkNeeded: true
  },
  // {
  //   path: "/retailers",
  //   name: "All Retailers",
  //   rtlName: "لوحة القيادة",
  //   icon: StoreIcon,
  //   component: Retailers,
  //   layout: "/admin",
  //   role: "manager"
  // },
  // {
  //   path: "/signin",
  //   name: "Sign out",
  //   rtlName: "لوحة القيادة",
  //   icon: LockIcon,
  //   component: null,
  //   layout: "/admin",
  //   linkNeeded: false,
  //   signout: true
  // },
  {
    path: "/regularforms",
    name: "RegularForms",
    rtlName: "لوحة القيادة",
    icon: StoreIcon,
    component: RegularForms,
    layout: "/admin",
    linkNeeded: true
  },
  {
    path: "/changepassword",
    name: "Change Password",
    rtlName: "لوحة القيادة",
    icon: LockOpenIcon,
    component: ChangePassword,
    layout: "/admin",
    linkNeeded: true
  },
  {
    path: "/signout",
    name: "Sign out",
    rtlName: "لوحة القيادة",
    icon: LockIcon,
    component: null,
    layout: "/admin",
    linkNeeded: true,
    signout: true
  }
  // {
  //   path: "/regularformstest",
  //   name: "RegularFormsTest",
  //   rtlName: "لوحة القيادة",
  //   icon: StoreIcon,
  //   component: RegularFormsTest,
  //   layout: "/admin",
  //   linkNeeded: true
  // }
  // {
  //   path: "/signednout",
  //   name: "SignedOut",
  //   rtlName: "لوحة القيادة",
  //   icon: StoreIcon,
  //   component: SignedOut,
  //   layout: "/admin"
  // }
];
export default dashRoutes;
