import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
// Views and components
// import GridContainer from "components/Grid/GridContainer";
// import GridItem from "components/Grid/GridItem";
import SurveyQuestionScore from 'views/Surveys/SurveyQuestionScore';
// Styling
import { withStyles } from '@material-ui/core/styles';
import regularFormsStyle from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

const getResponseForQuestion = (responses = [], questionId = '') => {
  const filteredResponses =
    responses &&
    responses.length > 0 &&
    responses.filter(response => response.qId === questionId);

  const response = filteredResponses && filteredResponses[0];

  if (response) {
    // console.log(response);
    return response;
  }
};

const SurveySectionScores = ({ ...props }) => {
  const {
    // classes,
    section,
    questions,
    showScores,
    alwaysShowPrefix,
    reportingMode,
    showUKQuestions,
    responses,
    responseSummary
  } = props;
  // console.log("in SurveySectionScores");
  // console.log(section);
  // console.log("showUKQuestions: ", showUKQuestions);
  // console.log(props);

  return section && section.id && questions ? (
    <Fragment>
      {section.text}
      {showUKQuestions === true ? (
        <Fragment>
          {' section - '} {questions.length === 1 ? ' only ' : null}
          {questions.length}
          {questions.length > 1
            ? ' questions. '
            : ' question applies to this site. '}
        </Fragment>
      ) : null}
      {showScores === true ? <br /> : null}
      {showScores === true &&
      (alwaysShowPrefix === true || section.number === 1)
        ? 'Your answers at a glance: '
        : null}
      {showScores === true
        ? questions.map((question, idx) =>
            question.factoryQuestion ? (
              <SurveyQuestionScore
                key={question.id}
                sequence={idx + 1}
                question={question}
                response={getResponseForQuestion(responses, question.id)}
                responseSummary={responseSummary}
                showUKQuestions={showUKQuestions}
                reportingMode={reportingMode}
              />
            ) : showUKQuestions === true ? (
              <SurveyQuestionScore
                key={question.id}
                sequence={idx + 1}
                question={question}
                response={getResponseForQuestion(responses, question.id)}
                responseSummary={responseSummary}
                showUKQuestions={showUKQuestions}
                reportingMode={reportingMode}
              />
            ) : null
          )
        : null}
    </Fragment>
  ) : (
    'Loading...'
  );
};

SurveySectionScores.propTypes = {
  classes: PropTypes.object,
  phase: PropTypes.string, // added for phase III
  section: PropTypes.object,
  questions: PropTypes.array,
  responses: PropTypes.array,
  responseSummary: PropTypes.object
};

SurveySectionScores.defaultProps = {
  classes: {},
  phase: '4', // added for phase III
  section: {},
  questions: []
};

export default withStyles(regularFormsStyle)(SurveySectionScores);
