const excelTopHeadersTemplate = [
  {
    column: 'A',
    method: 'fixed',
    source: 'Tracking file / working file  E-Readiness Checklist Phase 3'
  },
  { column: 'B', method: 'spacer', source: '' },
  { column: 'C', method: 'spacer', source: '' },
  { column: 'D', method: 'spacer', source: '' },
  { column: 'E', method: 'spacer', source: '' },
  { column: 'F', method: 'spacer', source: '' },
  { column: 'G', method: 'spacer', source: '' },
  { column: 'H', method: 'spacer', source: '' },
  { column: 'I', method: 'spacer', source: '' },
  { column: 'J', method: 'spacer', source: 'responder' },
  { column: 'K', method: 'spacer', source: 'responder email' },
  { column: 'L', method: 'fixed', source: 'updated' },
  { column: 'M', method: 'spacer', source: '' },
  { column: 'N', method: 'spacer', source: '' },

  { column: 'O', method: 'fixed', source: 'Quarantine' },
  { column: 'P', method: 'spacer', source: '' },
  { column: 'Q', method: 'spacer', source: '' },
  { column: 'R', method: 'spacer', source: '' },

  { column: 'S', method: 'fixed', source: 'Infrastructure' },
  { column: 'T', method: 'spacer', source: '' },
  { column: 'U', method: 'spacer', source: '' },
  { column: 'V', method: 'spacer', source: '' },
  { column: 'W', method: 'spacer', source: '' },
  { column: 'X', method: 'spacer', source: '' },
  { column: 'Y', method: 'spacer', source: '' },
  { column: 'Z', method: 'spacer', source: '' },
  { column: 'AA', method: 'spacer', source: '' },
  { column: 'AB', method: 'spacer', source: '' },
  { column: 'AC', method: 'spacer', source: '' },

  { column: 'AD', method: 'fixed', source: 'Outdoor Area' },
  { column: 'AE', method: 'spacer', source: '' },
  { column: 'AF', method: 'spacer', source: '' },
  { column: 'AG', method: 'spacer', source: '' },
  { column: 'AH', method: 'spacer', source: '' },
  { column: 'AI', method: 'spacer', source: '' },
  { column: 'AJ', method: 'spacer', source: '' },
  { column: 'AK', method: 'spacer', source: '' },
  { column: 'AL', method: 'spacer', source: '' },
  { column: 'AM', method: 'spacer', source: '' },
  { column: 'AN', method: 'spacer', source: '' },
  { column: 'AO', method: 'spacer', source: '' },
  { column: 'AP', method: 'spacer', source: '' },
  { column: 'AQ', method: 'spacer', source: '' },
  { column: 'AR', method: 'spacer', source: '' },
  { column: 'AS', method: 'spacer', source: '' },
  { column: 'AT', method: 'spacer', source: '' },
  { column: 'AU', method: 'spacer', source: '' },
  { column: 'AV', method: 'spacer', source: '' },
  { column: 'AW', method: 'spacer', source: '' },
  { column: 'AX', method: 'spacer', source: '' },

  { column: 'AY', method: 'fixed', source: 'Indoor Area' },
  { column: 'AZ', method: 'spacer', source: '' },
  { column: 'BA', method: 'spacer', source: '' },
  { column: 'BB', method: 'spacer', source: '' },
  { column: 'BC', method: 'spacer', source: '' },
  { column: 'BD', method: 'spacer', source: '' },
  { column: 'BE', method: 'spacer', source: '' },
  { column: 'BF', method: 'spacer', source: '' },

  { column: 'BG', method: 'fixed', source: 'Service' },
  { column: 'BH', method: 'spacer', source: '' },
  { column: 'BI', method: 'spacer', source: '' },
  { column: 'BJ', method: 'spacer', source: '' },
  { column: 'BK', method: 'spacer', source: '' },
  { column: 'BL', method: 'spacer', source: '' },
  { column: 'BM', method: 'spacer', source: '' },
  { column: 'BN', method: 'spacer', source: '' },
  { column: 'BO', method: 'spacer', source: '' },
  { column: 'BP', method: 'spacer', source: '' },
  { column: 'BQ', method: 'spacer', source: '' },
  { column: 'BR', method: 'spacer', source: '' },
  { column: 'BS', method: 'spacer', source: '' },
  { column: 'BT', method: 'spacer', source: '' },
  { column: 'BU', method: 'spacer', source: '' },
  { column: 'BV', method: 'spacer', source: '' },
  { column: 'BW', method: 'spacer', source: '' },

  { column: 'BX', method: 'fixed', source: 'Battery' },
  { column: 'BY', method: 'spacer', source: '' },
  { column: 'BZ', method: 'spacer', source: '' },
  { column: 'CA', method: 'spacer', source: '' },
  { column: 'CB', method: 'spacer', source: '' },
  { column: 'CC', method: 'spacer', source: '' },
  { column: 'CD', method: 'spacer', source: '' },
  { column: 'CE', method: 'spacer', source: '' },
  { column: 'CF', method: 'spacer', source: '' },
  { column: 'CG', method: 'spacer', source: '' },
  { column: 'CH', method: 'spacer', source: '' },
  { column: 'CI', method: 'spacer', source: '' },
  { column: 'CJ', method: 'spacer', source: '' },
  { column: 'CK', method: 'spacer', source: '' },
  { column: 'CL', method: 'spacer', source: '' },
  { column: 'CM', method: 'spacer', source: '' },
  { column: 'CN', method: 'spacer', source: '' },
  { column: 'CO', method: 'fixed', source: 'Business' },
  { column: 'CP', method: 'spacer', source: '' },
  { column: 'CQ', method: 'spacer', source: '' },
  { column: 'CR', method: 'spacer', source: '' },
  { column: 'CS', method: 'spacer', source: '' },
  { column: 'CT', method: 'fixed', source: 'Training' },
  { column: 'CU', method: 'spacer', source: '' },
  { column: 'CV', method: 'spacer', source: '' },
  { column: 'CW', method: 'spacer', source: '' },
  { column: 'CX', method: 'spacer', source: '' },
  { column: 'CY', method: 'spacer', source: '' },
  { column: 'CZ', method: 'spacer', source: '' },
  { column: 'DA', method: 'spacer', source: '' },
  { column: 'DB', method: 'spacer', source: '' },
  { column: 'DC', method: 'spacer', source: '' },
  { column: 'DD', method: 'spacer', source: '' }
];
export default excelTopHeadersTemplate;
