import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
// import { Link } from "react-router-dom";
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import PropTypes from 'prop-types';
// View and components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Accordion from 'components/Accordion/Accordion';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import SurveySection from 'views/Surveys/SurveySection';
import SurveySectionScores from 'views/Surveys/SurveySectionScores';
import PercentageAnswered from 'views/Surveys/PercentageAnswered';
import PercentageReady from 'views/Surveys/PercentageReady';
// Actions
import { signOut } from '_store/actions/authActions';
// Styling
import withStyles from '@material-ui/core/styles/withStyles';
import { cardTitle } from 'assets/jss/material-dashboard-pro-react';
import { BeatLoader } from 'react-spinners';
import UnansweredIcon from '@material-ui/icons/RadioButtonUnchecked';
// import UnansweredIcon from "@material-ui/icons/Block";
import TBAIcon from '@material-ui/icons/PauseCircleOutline';
import YesIcon from '@material-ui/icons/CheckCircleOutline';
import NoIcon from '@material-ui/icons/HighlightOff';
// import NotApplicableIcon from "@material-ui/icons/NotInterested";
// Styling
// import statusIconStyle from "assets/jss/material-dashboard-pro-react/components/statusIconStyle";
// nodejs library that concatenates classes
// import classNames from "classnames";

const styles = {
  cardTitle,
  pageSubcategoriesTitle: {
    color: '#3C4858',
    textDecoration: 'none',
    textAlign: 'center'
  },
  cardCategory: {
    margin: '0',
    color: '#999999'
  },
  yesIcon: {
    marginLeft: '1px',
    marginBottom: '-2px',
    color: '#51ae30',
    width: '14px',
    height: '14px',
    fontSize: '14px',
    viewBox: '0 0 16 16',
    lineHeight: '14px'
  },
  noIcon: {
    marginLeft: '1px',
    marginBottom: '-2px',
    color: '#e4002c',
    width: '14px',
    height: '14px',
    fontSize: '14px',
    lineHeight: '14px'
  },
  noButOKIcon: {
    marginLeft: '1px',
    marginBottom: '-2px',
    color: '#51ae30',
    width: '14px',
    height: '14px',
    fontSize: '14px',
    lineHeight: '14px'
  },
  tbaIcon: {
    marginLeft: '1px',
    marginBottom: '-2px',
    color: 'gray',
    width: '14px',
    height: '14px',
    fontSize: '14px',
    lineHeight: '14px'
  },
  unansweredIcon: {
    marginLeft: '1px',
    marginBottom: '-2px',
    color: '#e4002c',
    width: '14px',
    height: '14px',
    fontSize: '14px',
    lineHeight: '14px'
  }
};

const SurveyAccordian = ({ ...props }) => {
  const {
    classes,
    match,
    auth,
    profile,
    retailer,
    sections,
    questions,
    resources,
    responses,
    responseSummary,
    phase // added for phase III
    // signOut
    // responseSummary
  } = props;

  const metaData = {
    rtlrId: match.params.retailer,
    rtlrNbr: retailer && retailer.retailerNumber,
    rtlrName: retailer && retailer.retailerName,
    usrId: auth.uid,
    usrName: profile.firstName + ' ' + profile.lastName
  };
  //   console.log('in survey accordian, metaData', metaData);

  const checkSupplyIsOverFifty = () => {
    // const responses = props.responses;
    // console.log("in getResponseForQuestion");
    // console.log(responses && responses);
    const filteredResponses =
      responses &&
      responses.length > 0 &&
      // responses.filter(response => response.hasOwnProperty("is50kQuestion"));
      responses.filter(
        response =>
          response.qId === 'DAs19JufElKcxXOh2EgC' || // phase 2
          response.qId === 'dLkI12864rPo1LElEMpo' //phase 3 and 4
      );
    const answer =
      filteredResponses && filteredResponses.length > 0
        ? filteredResponses[0].ynA
        : 'TBA';
    // console.log(answer);
    if (answer) {
      if (answer === 'Yes') {
        return true;
      } else {
        if (answer === 'No') {
          return false;
        } else {
          return null;
        }
      }
    } else {
      return null;
    }
  };

  const setSectionRelevance = () => {
    // console.log("in setSectionRelevance");
    // console.log(sections);
    // console.log(retailer);

    const applicableSections = sections.map(section => {
      if (section.bccOnly) {
        // console.log(section.text);
        // console.log(retailer);
        // console.log(retailer.bcc);
        if (retailer.bcc) {
          return {
            id: section.id,
            text: section.text,
            applicable: true,
            data: section
          };
        } else {
          return {
            id: section.id,
            text: section.text,
            applicable: false,
            data: section
          };
        }
      } else {
        // console.log(section.text);
        if (section.fullOnly) {
          // console.log(section.text);
          // console.log(retailer);
          // console.log(retailer.full);
          if (retailer.full) {
            return {
              id: section.id,
              text: section.text,
              applicable: true,
              data: section
            };
          } else {
            return {
              id: section.id,
              text: section.text,
              applicable: false,
              data: section
            };
          }
        } else {
          return {
            id: section.id,
            text: section.text,
            applicable: true,
            data: section
          };
        }
      }
    });
    // console.log(applicableSections);
    return applicableSections;
  };

  const getApplicableSections = (markedSections = []) => {
    // console.log("in getApplicableSections");
    // console.log(markedSections);

    const filteredSections = markedSections.filter(
      section => section.applicable === true
    );
    // console.log(filteredSections);
    return filteredSections;
  };

  const getNotApplicableSections = (markedSections = []) => {
    // console.log("in getApplicableSections");
    // console.log(markedSections);

    const filteredSections = markedSections.filter(
      section => section.applicable === false
    );
    // console.log(filteredSections);
    return filteredSections;
  };

  const getSectionQuestions = (sectionId = null, supplyIsOverFifty = false) => {
    // console.log("in get", questions, sectionId);
    // console.log(questions);
    // if (sectionId === 'lscHpi7StF7ijJMQjyj9') {
    //   console.log('supplyIsOverFifty: ', supplyIsOverFifty);
    // }

    const sectionQuestions = questions.filter(
      question => question.sectionId === sectionId
    );

    const markedQuestions = sectionQuestions.map(question => {
      if (question.bccOnly === true) {
        if (retailer.bcc === true) {
          // console.log(question.text);
          return {
            id: question.id,
            questionObj: question,
            applicable: true
          };
        } else {
          return {
            id: question.id,
            questionObj: question,
            applicable: false
          };
        }
      } else {
        if (
          question.parentQuestionId === 'DAs19JufElKcxXOh2EgC' ||
          question.parentQuestionId === 'dLkI12864rPo1LElEMpo'
        ) {
          if (supplyIsOverFifty === false) {
            return {
              id: question.id,
              questionObj: question,
              applicable: true
            };
          } else {
            return {
              id: question.id,
              questionObj: question,
              applicable: false
            };
          }
        } else {
          return {
            id: question.id,
            questionObj: question,
            applicable: true
          };
        }
      }
    });

    if (markedQuestions.length > 0) {
      // return [applicableQuestions[1]];
      // const testArray = applicableQuestions.slice(1, 2);
      // return testArray;
      return markedQuestions;
    }
  };

  // const sectionStatusOverview = getSectionQuestions(
  //   retailer,
  //   sectionObj.id,
  //   questions,
  //   supplyIsOverFifty,
  //   false
  // )

  const getApplicableSectionQuestions = (
    sectionId = '',
    supplyIsOverFifty = false
  ) => {
    // console.log("in get", questions, sectionId);
    // console.log("in getApplicableSectionQuestions");

    const markedQuestions = getSectionQuestions(sectionId, supplyIsOverFifty);
    // console.log(markedQuestions);
    const filteredQuestions = markedQuestions
      ? markedQuestions.filter(question => question.applicable === true)
      : [];
    const applicableQuestions = filteredQuestions.map(
      question => question.questionObj
    );
    // console.log(applicableQuestions);

    // console.log(applicableQuestions);
    if (applicableQuestions.length > 0) {
      // return [applicableQuestions[1]];
      // const testArray = applicableQuestions.slice(1, 2);
      // return testArray;
      return applicableQuestions;
    }
    // console.log(applicableQuestions);
    return applicableQuestions;
  };

  const dataIsLoaded =
    isLoaded(retailer) &&
    isLoaded(sections) &&
    isLoaded(questions) &&
    isLoaded(resources) &&
    isLoaded(responses) &&
    isLoaded(responseSummary) &&
    !isEmpty(retailer) &&
    !isEmpty(sections) &&
    !isEmpty(questions) &&
    !isEmpty(resources) &&
    retailer &&
    sections &&
    sections.length > 0 &&
    questions &&
    questions.length > 0 &&
    resources &&
    resources.length > 0;

  if (dataIsLoaded) {
    // console.log("READY !!!!!!");
    // console.log(retailer);
    // console.log(responseSummary && responseSummary);
    // const responseSummaryObj = responseSummary && responseSummary[0];

    // const summarylink = `/admin/retailerprogress/responses/${retailer.id}`;

    const supplyIsOverFifty = checkSupplyIsOverFifty();
    const sectionRelevance = setSectionRelevance();
    const applicableSectionsArr = getApplicableSections(sectionRelevance);
    const notApplicableSectionsArr = getNotApplicableSections(sectionRelevance);
    const phaseName = phase && phase === '4' ? 'IV' : '3' ? 'III' : 'II';

    const sectionQuestionsAndResponsesArr = applicableSectionsArr.map(
      sectionObj => ({
        sectionId: sectionObj.id,
        sectionName: sectionObj.text,
        sectionData: sectionObj.data,
        sectionQuestions: getApplicableSectionQuestions(
          sectionObj.id,
          supplyIsOverFifty
        ),
        sectionResponses: responses.filter(
          response => response.sctnId === sectionObj.id
        )
      })
    );
    // console.log(sectionQuestionsAndResponsesArr);

    return (
      <GridContainer justify='center'>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <strong>{`EV READINESS PHASE ${phaseName}`} </strong>
              <br />
              {phase === '2' ? (
                <Fragment>
                  Volkswagen stopped tracking Phase II in September 2019, but
                  you can continue to use this Phase II form if you want to keep
                  updating your Phase II answers for your own purposes. And you
                  can still see a{' '}
                  <a
                    href={
                      profile &&
                      profile.manager &&
                      auth &&
                      auth.uid &&
                      retailer.responderId &&
                      retailer.responderId !== auth.uid
                        ? `/admin/retailerprogress/responses/${retailer.id}/2`
                        : `/admin/myretailers/responses/${retailer.id}/2`
                    }
                  >
                    summary of your phase II answers
                  </a>
                  {'.'}
                  <br /> <br />
                  Did you want the{' '}
                  <a href={`/admin/myretailers/survey/${retailer.id}/3`}>
                    form for Phase III
                  </a>
                  , the current checklist phase from October 2019?
                </Fragment>
              ) : (
                <Fragment>
                  <br />
                  {`This is the  `}
                  <strong>{` form to update `}</strong>
                  {`your answers for Phase ${phaseName}. You can also see a `}
                  <a
                    href={
                      profile &&
                      profile.manager &&
                      auth &&
                      auth.uid &&
                      retailer.responderId &&
                      retailer.responderId !== auth.uid
                        ? `/admin/retailerprogress/responses/${retailer.id}/${phase}`
                        : `/admin/myretailers/responses/${retailer.id}/${phase}`
                    }
                  >
                    <strong>
                      {`read-only summary of your Phase ${phaseName}
                      answers and notes`}
                    </strong>
                  </a>
                  .
                </Fragment>
              )}
              <br /> <br />
              <Fragment>
                <strong>
                  We have copied across answers to matching questions from phase
                  III wherever possible. Please check they are still right.
                </strong>
              </Fragment>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <strong>
                {' '}
                {`Phase ${phaseName} 
                instructions: `}
              </strong>{' '}
              Please click a section title to open it and answer the questions.
              <br />
              <strong>Tip:</strong> This form saves your answers as you go, so
              there's no 'submit' button. This page will always show your latest
              answers and notes.
              {responses && responses.length > 0 ? (
                <Fragment>
                  <br />
                  <strong>Key: </strong>
                  <YesIcon className={classes.yesIcon} /> You've answered Yes or
                  provided the info requested;{' '}
                  <NoIcon className={classes.noButOKIcon} /> No to an optional
                  thing, like a wallbox in the handover area;{' '}
                  <NoIcon className={classes.noIcon} /> No;{' '}
                  <UnansweredIcon className={classes.unansweredIcon} /> Not
                  answered yet; <TBAIcon className={classes.tbaIcon} /> You're
                  finding out. <br />
                </Fragment>
              ) : null}
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader>
              {`EV Readiness Survey (Phase ${phaseName}) for`}
              <br />
              <h4 className={classes.cardTitle}>
                {retailer && retailer.retailerName}
              </h4>
              {profile && 'Responder: '} {profile && profile.firstName}{' '}
              {profile && profile.lastName}
              <br /> <br />
              <PercentageAnswered
                questions={questions}
                responseSummary={responseSummary}
              />
              <PercentageReady
                questions={questions}
                responseSummary={responseSummary}
              />
            </CardHeader>
            <CardBody>
              {notApplicableSectionsArr.length > 0
                ? notApplicableSectionsArr.length === 1
                  ? 'Non-applicable section omitted: ' +
                    notApplicableSectionsArr.map(
                      section => ' ' + section.text
                    ) +
                    '.'
                  : 'Non-applicable sections omitted: ' +
                    notApplicableSectionsArr.map(
                      section => ' ' + section.text
                    ) +
                    '.'
                : null}
              <Accordion
                collapses={sectionQuestionsAndResponsesArr.map(sectionObj => ({
                  title:
                    responses && responses.length > 0 ? (
                      <SurveySectionScores
                        phase={phase} // added for phase III
                        section={sectionObj.sectionData}
                        questions={sectionObj.sectionQuestions}
                        responses={sectionObj.sectionResponses}
                        showScores={true}
                        alwaysShowPrefix={false}
                        showUKQuestions={true}
                        reportingMode={false}
                      />
                    ) : (
                      <SurveySectionScores
                        phase={phase} // added for phase III
                        section={sectionObj.sectionData}
                        questions={sectionObj.sectionQuestions}
                        responses={sectionObj.sectionResponses}
                        showScores={false}
                        alwaysShowPrefix={false}
                        showUKQuestions={true}
                        reportingMode={false}
                      />
                    ),
                  content: (
                    <Fragment>
                      <SurveySection
                        metaData={metaData}
                        section={sectionObj.sectionData}
                        questions={sectionObj.sectionQuestions}
                        phase={phase} // added for phase III
                        resources={resources}
                        responses={sectionObj.sectionResponses}
                        showUKQuestions={true}
                        reportingMode={false}
                      />
                    </Fragment>
                  )
                }))}
              />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <strong>Finished for now?</strong> Thanks. Just{' '}
              {/* <Link to={"#"} onClick={signOut !== undefined ? signOut : null}>
                sign out
              </Link> */}
              sign out. You can come back and update your answers at any time.
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  } else {
    return <BeatLoader sizeUnit={'px'} size={30} color={'gray'} />;
  }
};

SurveyAccordian.propTypes = {
  classes: PropTypes.object,
  phase: PropTypes.string, // added for phase III
  sections: PropTypes.array,
  questions: PropTypes.array,
  resources: PropTypes.array,
  responses: PropTypes.array,
  signOut: PropTypes.func,
  activeRetailer: PropTypes.object,
  responseSummary: PropTypes.object
};

SurveyAccordian.defaultProps = {
  classes: {},
  phase: '4', // added for phase III
  sections: [],
  questions: [],
  resources: [],
  responses: []
};

const mapStateToProps = (state, ownProps) => {
  //   console.log(state);
  // console.log(ownProps);
  // console.log(ownProps.match.params.phase);
  // console.log(ownProps.match.path);
  // const retailers = state.firestore.ordered.myRetailers;
  const retailers = state.firestore.ordered.activeRetailer;

  // console.log(retailers);
  const activeRetailerArray =
    retailers &&
    retailers.filter(
      retailer => retailer.id === ownProps.match.params.retailer
    );
  // console.log(ownProps.match.params.retailer);
  // console.log(activeRetailerArray);
  const activeRetailer = activeRetailerArray && activeRetailerArray[0];
  // console.log(
  //   state.firestore.ordered.responses && state.firestore.ordered.responses
  // );
  const safetyFilteredResponses =
    ownProps.match.params.retailer &&
    state.firestore.ordered.responses &&
    state.firestore.ordered.responses.filter(
      response =>
        response.rtlrId === ownProps.match.params.retailer &&
        response.phase !== '2'
    );

  const responseSummaryObj =
    state.firestore.ordered.responseSummary &&
    state.firestore.ordered.responseSummary[0];
  // console.log(safetyFilteredResponses);

  // const activeRetailer =
  //   retailers && retailers.length > 0 ? retailers[0] : null;
  // const demoRetailer = {
  //   retailerId: "OnjB1u7Nqb3ZoKW5K3sq",
  //   retailerName: "Upstone Volkswagen (Sandbox, BCC)",
  //   retailerNumber: "99004",
  //   bcc: true,
  //   full: true,
  //   responderId: state.firebase.auth && state.firebase.auth.uid
  // };
  // const responseSummary =
  //   state.firestore.ordered.retailerResponseSummaries &&
  //   state.firestore.ordered.retailerResponseSummaries[0];
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    phase: ownProps.match.params.phase, // added for phase III
    sections: state.firestore.ordered.sections,
    questions: state.firestore.ordered.questions,
    resources: state.firestore.ordered.resources,
    responses: safetyFilteredResponses,
    retailer: activeRetailer,
    responseSummary: responseSummaryObj
    // responseSummary: responseSummary
  };
};
const mapDispatchToProps = dispatch => {
  return {
    signOut: () => dispatch(signOut())
  };
};

export default withStyles(styles)(
  compose(
    connect(mapStateToProps),
    connect(mapDispatchToProps),
    firestoreConnect(props => [
      // { collection: "users", doc: props.auth.uid, storeAs: "activeUser" },

      // {
      //   collection: "retailers",
      //   // doc: "OnjB1u7Nqb3ZoKW5K3sq",

      //   where: ["id", "==", "OnjB1u7Nqb3ZoKW5K3sq"],

      //   // orderBy: ["retailerName", "asc"],
      //   limit: 1,
      //   storeAs: "retailerInPlay"
      // },
      // {
      //   collection: "retailers",
      //   where: [["responderId", "==", props.auth.uid]],
      //   limit: 20,
      //   storeAs: "myRetailers"
      // },
      // {
      //   collection: "retailers",
      //   where: [["responderId", "==", props.auth.uid]],
      //   limit: 20,
      //   storeAs: "myRetailers"
      // },
      {
        collection: 'retailers',
        doc: props.match.params.retailer,
        // ["qId", "==", "60jNR4Raj1R0Ek458Qm9"]
        // ["qId", "==", props.question.data.id]
        // ["qId", "==", props.questionId]
        storeAs: 'activeRetailer'
      },
      {
        collection: 'responses',
        where: [
          // ["rtlrId", "==", "OnjB1u7Nqb3ZoKW5K3sq"]
          ['rtlrId', '==', props.match.params.retailer],
          ['phase', '==', props.match.params.phase]
          // ["qId", "==", "60jNR4Raj1R0Ek458Qm9"]
          // ["qId", "==", props.question.data.id]
          // ["qId", "==", props.questionId]
        ],
        orderBy: ['time', 'desc'],
        limit: 100
      },
      {
        collection: 'responseSummaries',
        // doc: props.match.params.retailer,
        where: [
          ['retailerId', '==', props.match.params.retailer],
          ['phase', '==', props.match.params.phase]
        ],
        // where: [
        //   // ["rtlrId", "==", "OnjB1u7Nqb3ZoKW5K3sq"]
        //   ["doc", "==", props.match.params.retailer]
        //   // ["qId", "==", "60jNR4Raj1R0Ek458Qm9"]
        //   // ["qId", "==", props.question.data.id]
        //   // ["qId", "==", props.questionId]
        // ],
        // limit: 1,
        storeAs: 'responseSummary'
      },
      {
        collection: 'sections',
        where: [['phase', '==', props.match.params.phase]],
        limit: 15,
        orderBy: ['number', 'asc']
      },
      {
        collection: 'questions',
        where: [['phase', '==', props.match.params.phase]],
        limit: 50,
        orderBy: ['displayWeight', 'asc']
      },
      { collection: 'resources', limit: 30 }
      // {
      //   collection: "retailerResponseSummaries",
      //   where: [
      //     // ["rtlrId", "==", "OnjB1u7Nqb3ZoKW5K3sq"]
      //     ["rtlrId", "==", props.match.params.retailer]
      //     //   // ["qId", "==", "60jNR4Raj1R0Ek458Qm9"]
      //     //   // ["qId", "==", props.question.data.id]
      //     //   // ["qId", "==", props.questionId]
      //   ],
      //   limit: 1,
      //   storeAs: "retailerResponseSummaries"
      // }
      // { collection: "sections", limit: 12, orderBy: ["number", "asc"] },
      // {
      //   collection: "questions",
      //   limit: 50,
      //   orderBy: ["displayWeight", "asc"]
      // },
      // { collection: "resources", limit: 30 }
    ])
  )(SurveyAccordian)
);
