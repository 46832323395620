const excelTopHeadersTemplate = [
  {
    column: "A",
    method: "fixed",
    source: "Tracking file / working file  E-Readiness Checklist Phase 3"
  },
  { column: "B", method: "spacer", source: "" },
  { column: "C", method: "spacer", source: "" },
  { column: "D", method: "spacer", source: "" },
  { column: "E", method: "spacer", source: "" },
  { column: "F", method: "spacer", source: "" },
  { column: "G", method: "spacer", source: "" },
  { column: "H", method: "spacer", source: "" },
  { column: "I", method: "spacer", source: "" },
  { column: "J", method: "spacer", source: "responder" },
  { column: "K", method: "spacer", source: "responder email" },
  { column: "L", method: "spacer", source: "" },
  { column: "M", method: "spacer", source: "" },
  { column: "N", method: "spacer", source: "" },

  { column: "O", method: "fixed", source: "Building" },
  { column: "P", method: "spacer", source: "" },
  { column: "Q", method: "spacer", source: "" },

  { column: "R", method: "fixed", source: "Infrastructure" },
  { column: "S", method: "spacer", source: "" },
  { column: "T", method: "spacer", source: "" },
  { column: "U", method: "spacer", source: "" },
  { column: "V", method: "spacer", source: "" },

  { column: "W", method: "fixed", source: "Outdoor Area" },
  { column: "X", method: "spacer", source: "" },
  { column: "Y", method: "spacer", source: "" },
  { column: "Z", method: "spacer", source: "" },
  { column: "AA", method: "spacer", source: "" },

  { column: "AB", method: "fixed", source: "Service" },
  { column: "AC", method: "spacer", source: "" },
  { column: "AD", method: "spacer", source: "" },
  { column: "AE", method: "spacer", source: "" },
  { column: "AF", method: "spacer", source: "" },

  { column: "AG", method: "fixed", source: "Battery" },
  { column: "AH", method: "spacer", source: "" },
  { column: "AI", method: "spacer", source: "" },
  { column: "AJ", method: "spacer", source: "" },
  { column: "AK", method: "spacer", source: "" },
  { column: "AL", method: "spacer", source: "" },
  { column: "AM", method: "spacer", source: "" },
  { column: "AN", method: "spacer", source: "" },
  { column: "AO", method: "spacer", source: "" },
  { column: "AP", method: "spacer", source: "" },
  { column: "AQ", method: "spacer", source: "" },
  { column: "AR", method: "spacer", source: "" },
  { column: "AS", method: "spacer", source: "" },
  { column: "AT", method: "spacer", source: "" },
  { column: "AU", method: "spacer", source: "" },
  { column: "AV", method: "spacer", source: "" },
  { column: "AW", method: "spacer", source: "" },
  { column: "AX", method: "spacer", source: "" },
  { column: "AY", method: "spacer", source: "" },
  { column: "AZ", method: "spacer", source: "" },
  { column: "BA", method: "spacer", source: "" },

  { column: "BB", method: "fixed", source: "Training" },
  { column: "BC", method: "spacer", source: "" },
  { column: "BD", method: "spacer", source: "" },
  { column: "BE", method: "spacer", source: "" },
  { column: "BF", method: "spacer", source: "" },
  { column: "Bg", method: "spacer", source: "" }
];
export default excelTopHeadersTemplate;
