import React, { Component } from "react";
// import { Link } from "react-router-dom";
// import { compose } from "redux";
import { connect } from "react-redux";
// import { Redirect } from "react-router-dom";
// import { withFirebase } from "react-redux-firebase";

import { TextField } from "@material-ui/core";
import RegularButton from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import Success from "components/Typography/Success";
import Danger from "components/Typography/Danger";
import { changePassword } from "_store/actions/authActions";

// import { withFirebase } from "../Firebase";

const INITIAL_STATE = {
  currentPassword: "",
  newPassword: "",
  newPasswordCheck: "",
  submitting: null,
  error: null
};

const checkCurrentPasswordMissing = currentPassword => {
  const currentPasswordMissing = currentPassword === "";
  // console.log("currentPasswordMissing", currentPasswordMissing);
  return currentPasswordMissing || false;
};

const checkNewPasswordMismatch = (newPassword, newPasswordCheck) => {
  const newPasswordMismatch =
    newPassword &&
    newPasswordCheck &&
    newPassword.length > 0 &&
    newPasswordCheck.length > 0 &&
    newPassword !== newPasswordCheck;
  // console.log("newPasswordMismatch", newPasswordMismatch);

  return newPasswordMismatch || false;
};

const checkNewPasswordTooShort = (newPassword, newPasswordCheck) => {
  const newPasswordTooShort =
    (newPassword && newPassword.length > 0 && newPassword.length < 6) ||
    (newPasswordCheck &&
      newPasswordCheck.length > 0 &&
      newPasswordCheck.length < 6);
  // console.log("newPasswordTooShort", newPasswordTooShort);

  return newPasswordTooShort || false;
};

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = e => {
    const userDetails = {
      email: this.props.auth.email,
      userId: this.props.auth.uid,
      currentPassword: this.state.currentPassword,
      newPassword: this.state.newPassword
    };

    const currentPasswordMissing = checkCurrentPasswordMissing(
      this.state.currentPassword
    );

    const newPasswordMismatch = checkNewPasswordMismatch(
      this.state.newPassword,
      this.state.newPasswordCheck
    );

    const newPasswordTooShort = checkNewPasswordTooShort(
      this.state.newPassword,
      this.state.newPasswordCheck
    );

    const isInvalid =
      currentPasswordMissing || newPasswordMismatch || newPasswordTooShort;

    // console.log("in onSubmit", userDetails);
    // console.log(this.props);
    // console.log(this.propschangePassword);
    if (!isInvalid) this.props.changePassword(userDetails);
    this.setState(state => ({
      submitting: true
    }));
    // } else {
    //   console.log(
    //     "input error",
    //     this.state.currentPassword,
    //     currentPasswordMissing,
    //     this.state.newPassword,
    //     newPasswordMismatch,
    //     this.state.newPasswordCheck,
    //     newPasswordTooShort
    //   );
    // }
    e.preventDefault();
  };

  checkInput = (currentPassword, newPassword, newPasswordCheck) => {
    const currentPasswordMissing =
      currentPassword === "" && newPassword !== "" && newPasswordCheck !== "";

    const newPasswordMismatch =
      newPassword &&
      newPasswordCheck &&
      newPassword.length > 0 &&
      newPasswordCheck.length > 0 &&
      newPassword !== newPasswordCheck;

    const newPasswordTooShort =
      (newPassword && newPassword.length > 0 && newPassword.length < 6) ||
      (newPasswordCheck &&
        newPasswordCheck.length > 0 &&
        newPasswordCheck.length < 6);

    const isInvalid =
      currentPasswordMissing || newPasswordMismatch || newPasswordTooShort;
    return isInvalid;
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { newPassword, newPasswordCheck, currentPassword } = this.state;
    const { authError, authChangeSuccess } = this.props;
    // console.log(this.props);
    const currentPasswordMissingMsg =
      "You need to provide your current password for security.";
    const newPasswordMismatchMsg =
      "The new password and confirmation must be the same.";
    const newPasswordTooShortMsg =
      "The new password must be at least 6 characters.";

    const currentPasswordMissing =
      newPassword &&
      newPasswordCheck &&
      checkCurrentPasswordMissing(currentPassword);

    const newPasswordMismatch =
      newPassword &&
      newPasswordCheck &&
      checkNewPasswordMismatch(newPassword, newPasswordCheck);

    const newPasswordTooShort =
      newPassword &&
      newPasswordCheck &&
      checkNewPasswordTooShort(newPassword, newPasswordCheck);

    const isInvalid =
      currentPasswordMissing || newPasswordMismatch || newPasswordTooShort;
    const confirmNotReady =
      isInvalid ||
      currentPassword === "" ||
      newPassword === "" ||
      newPasswordCheck === "";

    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody>
              <h5>Change Password</h5>
              {this.state.submitting && !authError ? null : (
                <form onSubmit={this.onSubmit}>
                  <TextField
                    id="currentPassword"
                    name="currentPassword"
                    label="Current Password"
                    //     className={classes.textField}
                    placeholder="Type in your current password."
                    type="password"
                    fullWidth
                    required={true}
                    value={currentPassword}
                    onChange={this.handleChange}
                  />
                  <TextField
                    id="newPassword"
                    name="newPassword"
                    label="New Password"
                    //     className={classes.textField}
                    placeholder="Type in your new password."
                    type="password"
                    fullWidth
                    required={true}
                    value={newPassword}
                    onChange={this.handleChange}
                  />
                  <TextField
                    id="newPasswordCheck"
                    name="newPasswordCheck"
                    label="Repeat to Confirm"
                    //     className={classes.textField}
                    placeholder="Once more, please."
                    type="password"
                    fullWidth
                    required={true}
                    value={newPasswordCheck}
                    onChange={this.handleChange}
                  />
                  <br />

                  <br />
                  <RegularButton
                    label="Change Password"
                    variant="contained"
                    color="primary"
                    //    className={classes.button}
                    onClick={this.handleSubmit}
                    autoFocus={true}
                    disabled={confirmNotReady}
                    type="submit"
                  >
                    Confirm Change
                  </RegularButton>
                </form>
              )}
              <br />
              <Danger>
                {currentPasswordMissing ? (
                  <p>{currentPasswordMissingMsg}</p>
                ) : null}
                {newPasswordMismatch ? <p>{newPasswordMismatchMsg}</p> : null}
                {newPasswordTooShort ? <p>{newPasswordTooShortMsg}</p> : null}
                {this.state.submitting && !isInvalid && authError ? (
                  <p>{authError}</p>
                ) : null}
              </Danger>
              <Success>
                {this.state.submitting && !authError && !isInvalid ? (
                  <p>{authChangeSuccess}</p>
                ) : null}
              </Success>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}
const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError,
    authChangeSuccess: state.auth.authChangeSuccess
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changePassword: password => dispatch(changePassword(password))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePassword);
