import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Redirect } from 'react-router-dom';
import { firestoreConnect, isLoaded } from 'react-redux-firebase';
import PropTypes from 'prop-types';
// View and components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Accordion from 'components/Accordion/Accordion';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import SurveySection from 'views/Surveys/SurveySection';
import SurveySectionScores from 'views/Surveys/SurveySectionScores';
import PercentageAnswered from 'views/Surveys/PercentageAnswered';
import PercentageReady from 'views/Surveys/PercentageReady';
// Actions
import { signOut } from '_store/actions/authActions';
// Styling
import withStyles from '@material-ui/core/styles/withStyles';
import { cardTitle } from 'assets/jss/material-dashboard-pro-react';
import { BeatLoader } from 'react-spinners';
import UnansweredIcon from '@material-ui/icons/RadioButtonUnchecked';
import TBAIcon from '@material-ui/icons/PauseCircleOutline';
import YesIcon from '@material-ui/icons/CheckCircleOutline';
import NoIcon from '@material-ui/icons/HighlightOff';
// import NotApplicableIcon from "@material-ui/icons/NotInterested";
// Styling
// import statusIconStyle from "assets/jss/material-dashboard-pro-react/components/statusIconStyle";
// nodejs library that concatenates classes
// import classNames from "classnames";

const styles = {
  cardTitle,
  pageSubcategoriesTitle: {
    color: '#3C4858',
    textDecoration: 'none',
    textAlign: 'center'
  },
  cardCategory: {
    margin: '0',
    color: '#999999'
  },
  yesIcon: {
    marginLeft: '1px',
    marginBottom: '-2px',
    color: '#51ae30',
    width: '14px',
    height: '14px',
    fontSize: '14px',
    viewBox: '0 0 16 16',
    lineHeight: '14px'
  },
  noIcon: {
    marginLeft: '1px',
    marginBottom: '-2px',
    color: '#e4002c',
    width: '14px',
    height: '14px',
    fontSize: '14px',
    lineHeight: '14px'
  },
  noButOKIcon: {
    marginLeft: '1px',
    marginBottom: '-2px',
    color: '#51ae30',
    width: '14px',
    height: '14px',
    fontSize: '14px',
    lineHeight: '14px'
  },
  tbaIcon: {
    marginLeft: '1px',
    marginBottom: '-2px',
    color: 'gray',
    width: '14px',
    height: '14px',
    fontSize: '14px',
    lineHeight: '14px'
  },
  unansweredIcon: {
    marginLeft: '1px',
    marginBottom: '-2px',
    color: '#e4002c',
    width: '14px',
    height: '14px',
    fontSize: '14px',
    lineHeight: '14px'
  }
};

const SurveyAccordian = ({ ...props }) => {
  const {
    classes,
    // match,
    auth,
    profile,
    retailer,
    phase,
    sections,
    questions,
    resources,
    responses,
    responseSummary,
    users
  } = props;

  //   console.log(responseSummary && responseSummary);

  const getResponderInfo = (responderId, users) => {
    const userDetails =
      responderId && users && users.find(user => user.id === responderId);
    if (userDetails) {
      return 'Responder: ' + userDetails.firstName + ' ' + userDetails.lastName;
    } else {
      return '';
    }
  };

  const getResponderName = (responderId, users) => {
    // console.log(
    //   'in retailer resp survey accordian, metaData getResponderName',
    //   responderId,
    //   users && users.length
    // );
    const userDetails =
      responderId && users && users.find(user => user.id === responderId);
    if (userDetails) {
      return userDetails.firstName + ' ' + userDetails.lastName;
    } else {
      return '';
    }
  };

  const metaData = {
    rtlrId: retailer && retailer.id,
    rtlrNbr: retailer && retailer.retailerNumber,
    rtlrName: retailer && retailer.retailerName,
    usrId: retailer && retailer.responderId,
    usrName: retailer && getResponderName(retailer.responderId, users)
  };

  //   console.log('in retailer resp survey accordian, metaData', metaData);
  const checkSupplyIsOverFifty = () => {
    // const responses = props.responses;
    // console.log("in getResponseForQuestion");
    // console.log(responses && responses);
    const answer =
      responseSummary.phase === '4'
        ? responseSummary['P4S2Q1-ynA']
        : responseSummary.phase === '3'
        ? responseSummary && responseSummary['P3S2Q1-ynA']
        : responseSummary && responseSummary['S3Q1-ynA'];
    // const answer =
    //   filteredResponses && filteredResponses.length > 0
    //     ? filteredResponses[0].ynA
    //     : "TBA";
    // console.log(answer);
    if (answer && answer === 'Yes') {
      return true;
    } else {
      return false;
    }
  };

  const setSectionRelevance = () => {
    // console.log("in setSectionRelevance");
    // console.log(sections);
    // console.log(retailer);

    const applicableSections = sections.map(section => {
      if (section.bccOnly) {
        // console.log(section.text);
        // console.log(retailer);
        // console.log(retailer.bcc);
        if (retailer.bcc) {
          return {
            id: section.id,
            text: section.text,
            applicable: true,
            data: section
          };
        } else {
          return {
            id: section.id,
            text: section.text,
            applicable: false,
            data: section
          };
        }
      } else {
        // console.log(section.text);
        if (section.fullOnly) {
          // console.log(section.text);
          // console.log(retailer);
          // console.log(retailer.full);
          if (retailer.full) {
            return {
              id: section.id,
              text: section.text,
              applicable: true,
              data: section
            };
          } else {
            return {
              id: section.id,
              text: section.text,
              applicable: false,
              data: section
            };
          }
        } else {
          return {
            id: section.id,
            text: section.text,
            applicable: true,
            data: section
          };
        }
      }
    });
    // console.log(applicableSections);
    return applicableSections;
  };

  const getApplicableSections = (markedSections = []) => {
    // console.log("in getApplicableSections");
    // console.log(markedSections);

    const filteredSections = markedSections.filter(
      section => section.applicable === true
    );
    // console.log(filteredSections);
    return filteredSections;
  };

  const getNotApplicableSections = (markedSections = []) => {
    // console.log("in getApplicableSections");
    // console.log(markedSections);

    const filteredSections = markedSections.filter(
      section => section.applicable === false
    );
    // console.log(filteredSections);
    return filteredSections;
  };

  const getSectionQuestions = (sectionId = null, supplyIsOverFifty = false) => {
    // console.log("in get", questions, sectionId);
    // console.log(questions);

    const sectionQuestions = questions.filter(
      question => question.sectionId === sectionId
    );

    const markedQuestions = sectionQuestions.map(question => {
      if (question.bccOnly === true) {
        if (retailer.bcc === true) {
          // console.log(question.text);
          return {
            id: question.id,
            questionObj: question,
            applicable: true
          };
        } else {
          return {
            id: question.id,
            questionObj: question,
            applicable: false
          };
        }
      } else {
        if (
          question.parentQuestionId === 'DAs19JufElKcxXOh2EgC' ||
          question.parentQuestionId === 'dLkI12864rPo1LElEMpo'
        ) {
          if (supplyIsOverFifty === true) {
            return {
              id: question.id,
              questionObj: question,
              applicable: false
            };
          } else {
            return {
              id: question.id,
              questionObj: question,
              applicable: true
            };
          }
        } else {
          return {
            id: question.id,
            questionObj: question,
            applicable: true
          };
        }
      }
    });

    if (markedQuestions.length > 0) {
      // return [applicableQuestions[1]];
      // const testArray = applicableQuestions.slice(1, 2);
      // return testArray;
      return markedQuestions;
    }
  };

  // const sectionStatusOverview = getSectionQuestions(
  //   retailer,
  //   sectionObj.id,
  //   questions,
  //   supplyIsOverFifty,
  //   false
  // )

  const getApplicableSectionQuestions = (
    sectionId = '',
    supplyIsOverFifty = false
  ) => {
    // console.log("in get", questions, sectionId);
    // console.log("in getApplicableSectionQuestions");

    const markedQuestions = getSectionQuestions(sectionId, supplyIsOverFifty);
    // console.log(markedQuestions);
    const filteredQuestions = markedQuestions
      ? markedQuestions.filter(question => question.applicable === true)
      : [];
    const applicableQuestions = filteredQuestions.map(
      question => question.questionObj
    );
    // console.log(applicableQuestions);

    // console.log(applicableQuestions);
    if (applicableQuestions.length > 0) {
      // return [applicableQuestions[1]];
      // const testArray = applicableQuestions.slice(1, 2);
      // return testArray;
      return applicableQuestions;
    }
    // console.log(applicableQuestions);
    return applicableQuestions;
  };

  // console.log(props);

  const dataIsLoaded =
    isLoaded(retailer) &&
    isLoaded(responseSummary) &&
    retailer &&
    sections &&
    sections.length > 0 &&
    questions &&
    questions.length > 0 &&
    resources &&
    resources.length > 0 &&
    users &&
    users.length > 0;

  if (!auth.uid) return <Redirect to='/admin/signin' />;

  // console.log("isLoaded(retailer)", isLoaded(retailer));
  // console.log("isLoaded(responsesummary)", isLoaded(responseSummary));
  // console.log("retailer", retailer);
  // console.log("sections", sections.length);
  // console.log("questions", questions.length);
  // console.log("resources", resources.length);
  // console.log("users", users.length);

  if (dataIsLoaded) {
    // console.log("READY !!!!!!");
    // console.log(retailer);
    // console.log(sections);

    const supplyIsOverFifty = checkSupplyIsOverFifty();
    const sectionRelevance = setSectionRelevance();
    const applicableSectionsArr = getApplicableSections(sectionRelevance);
    const notApplicableSectionsArr = getNotApplicableSections(sectionRelevance);

    const phaseName = phase && phase === '4' ? 'IV' : '3' ? 'III' : 'II';

    const sectionQuestionsAndResponsesArr = applicableSectionsArr.map(
      sectionObj => ({
        sectionId: sectionObj.id,
        sectionName: sectionObj.text,
        sectionData: sectionObj.data,
        sectionQuestions: getApplicableSectionQuestions(
          sectionObj.id,
          supplyIsOverFifty
        ),
        responseSummary: responseSummary
      })
    );
    // console.log(sectionQuestionsAndResponsesArr);

    return (
      <GridContainer justify='center'>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <strong>
                {`EV READINESS PHASE ${phaseName}
                `}{' '}
              </strong>
              <br />
              {phase === '2' ? (
                <Fragment>
                  Volkswagen stopped tracking Phase II in September 2019, but
                  you can still see your Phase II answers here. You can still{' '}
                  <a
                    href={
                      profile &&
                      profile.manager &&
                      auth &&
                      auth.uid &&
                      retailer.responderId &&
                      retailer.responderId !== auth.uid
                        ? `/admin/retailerprogress/survey/${retailer.id}/2`
                        : `/admin/myretailers/survey/${retailer.id}/2`
                    }
                  >
                    update your Phase II form
                  </a>
                  {' too for you own use if you want to.'}
                  <br /> <br />
                  Did you want to see{' '}
                  <a href={`/admin/myretailers/responses/${retailer.id}/3`}>
                    Phase III
                  </a>{' '}
                  instead? Phase III is the current checklist from October 2019.
                </Fragment>
              ) : (
                <Fragment>
                  <br />
                  {`This is a `}
                  <strong>{`read-only summary`}</strong>
                  {` of your answers for Phase ${phaseName}. You can also  
                 `}
                  <a
                    href={
                      profile &&
                      profile.manager &&
                      auth &&
                      auth.uid &&
                      retailer.responderId &&
                      retailer.responderId !== auth.uid
                        ? `/admin/retailerprogress/survey/${retailer.id}/${phase}`
                        : `/admin/myretailers/survey/${retailer.id}/${phase}`
                    }
                  >
                    {' '}
                    <strong>
                      {`update your Phase ${phaseName}
                      answers and notes with the survey form`}
                    </strong>
                  </a>
                  .
                </Fragment>
              )}
              <br /> <br />
              <Fragment>
                <strong>
                  We have copied across answers to matching questions from phase
                  III wherever possible. Please check they are still right.
                </strong>
              </Fragment>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <Fragment>
                <strong>Key: </strong>
                <YesIcon className={classes.yesIcon} /> Answered Yes or provided
                the info requested; <NoIcon className={classes.noButOKIcon} />{' '}
                No to an optional thing, like a wallbox in the handover area;{' '}
                <NoIcon className={classes.noIcon} /> No;{' '}
                <UnansweredIcon className={classes.unansweredIcon} /> Not
                answered yet; <TBAIcon className={classes.tbaIcon} /> Finding
                out;
                <br />
              </Fragment>
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader>
              {`EV Readiness Survey (Phase ${phaseName}) for`}
              <br />
              <h4 className={classes.cardTitle}>
                {retailer && retailer.retailerName}
              </h4>{' '}
              {getResponderInfo(retailer.responderId, users)} <br /> <br />
              <PercentageAnswered
                phase={phase}
                questions={questions}
                responseSummary={responseSummary}
              />
              <PercentageReady
                phase={phase}
                questions={questions}
                responseSummary={responseSummary}
              />
            </CardHeader>
            <CardBody>
              {notApplicableSectionsArr.length > 0
                ? notApplicableSectionsArr.length === 1
                  ? 'Non-applicable section omitted: ' +
                    notApplicableSectionsArr.map(
                      section => ' ' + section.text
                    ) +
                    '.'
                  : 'Non-applicable sections omitted: ' +
                    notApplicableSectionsArr.map(
                      section => ' ' + section.text
                    ) +
                    '.'
                : null}
              <Accordion
                collapses={sectionQuestionsAndResponsesArr.map(sectionObj => ({
                  title:
                    responses && responses.length > 0 ? (
                      <SurveySectionScores
                        phase={phase}
                        section={sectionObj.sectionData}
                        questions={sectionObj.sectionQuestions}
                        responses={sectionObj.sectionResponses}
                        responseSummary={responseSummary}
                        showScores={true}
                        alwaysShowPrefix={false}
                        reportingMode={true}
                        showUKQuestions={true}
                      />
                    ) : (
                      <SurveySectionScores
                        phase={phase}
                        section={sectionObj.sectionData}
                        questions={sectionObj.sectionQuestions}
                        responses={sectionObj.sectionResponses}
                        responseSummary={responseSummary}
                        showScores={false}
                        alwaysShowPrefix={false}
                        reportingMode={true}
                        showUKQuestions={true}
                      />
                    ),
                  content: (
                    <Fragment>
                      <GridItem xs={12} sm={12} md={12}>
                        {auth.uid &&
                        retailer &&
                        retailer.responderId &&
                        retailer.responderId === auth.uid ? (
                          <Fragment>
                            {`This is a summary. You can update your Phase ${phaseName} 
                answers and notes `}
                            <a
                              href={`/admin/myretailers/survey/${retailer.id}/${phase}`}
                            >
                              here
                            </a>
                            .<br />
                          </Fragment>
                        ) : null}
                        <SurveySection
                          phase={phase}
                          section={sectionObj.sectionData}
                          questions={sectionObj.sectionQuestions}
                          metaData={metaData}
                          resources={resources}
                          responses={sectionObj.sectionResponses}
                          responseSummary={responseSummary}
                          reportingMode={true}
                          showUKQuestions={true}
                        />
                      </GridItem>
                    </Fragment>
                  )
                }))}
              />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <strong>Finished for now?</strong> Thanks. Just{' '}
              {/* <Link to={"#"} onClick={signOut !== undefined ? signOut : null}>
                sign out
              </Link> */}
              sign out. You can come back and update your answers at any time.
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  } else {
    return <BeatLoader sizeUnit={'px'} size={30} color={'gray'} />;
  }
};

SurveyAccordian.propTypes = {
  classes: PropTypes.object,
  phase: PropTypes.string, // added for phase III
  sections: PropTypes.array,
  questions: PropTypes.array,
  resources: PropTypes.array,
  responses: PropTypes.array,
  users: PropTypes.array,
  signOut: PropTypes.func,
  activeRetailer: PropTypes.object,
  responseSummary: PropTypes.object
};

SurveyAccordian.defaultProps = {
  classes: {},
  phase: '4', // added for phase III
  sections: [],
  questions: [],
  resources: [],
  responses: [],
  users: []
};

const mapStateToProps = (state, ownProps) => {
  // console.log(state);
  // console.log(ownProps);
  // console.log(ownProps.match.path);
  const retailers = state.firestore.ordered.activeRetailer;

  // console.log(retailers);
  const activeRetailerArray =
    retailers &&
    retailers.filter(
      retailer => retailer.id === ownProps.match.params.retailer
    );
  // console.log(ownProps.match.params.retailer);
  // console.log(activeRetailerArray);
  const activeRetailer = activeRetailerArray && activeRetailerArray[0];
  // console.log(
  //   state.firestore.ordered.responses && state.firestore.ordered.responses
  // );
  const safetyFilteredResponses =
    ownProps.match.params.retailer &&
    state.firestore.ordered.myResponses &&
    state.firestore.ordered.myResponses.filter(
      response =>
        response.rtlrId === ownProps.match.params.retailer &&
        response.phase !== '2'
    );
  // console.log(safetyFilteredResponses);

  const responseSummaryObj =
    state.firestore.ordered.responseSummary &&
    state.firestore.ordered.responseSummary[0];

  // const activeRetailer =
  //   retailers && retailers.length > 0 ? retailers[0] : null;
  // const demoRetailer = {
  //   retailerId: "OnjB1u7Nqb3ZoKW5K3sq",
  //   retailerName: "Upstone Volkswagen (Sandbox, BCC)",
  //   retailerNumber: "99004",
  //   bcc: true,
  //   full: true,
  //   responderId: state.firebase.auth && state.firebase.auth.uid
  // };
  // const responseSummary =
  //   state.firestore.ordered.retailerResponseSummaries &&
  //   state.firestore.ordered.retailerResponseSummaries[0];
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    phase: ownProps.match.params.phase, // added for phase III
    sections: state.firestore.ordered.sections,
    questions: state.firestore.ordered.questions,
    resources: state.firestore.ordered.resources,
    users: state.firestore.ordered.users,
    responses: safetyFilteredResponses,
    retailer: activeRetailer,
    responseSummary: responseSummaryObj
    // responseSummary: responseSummary
  };
};
const mapDispatchToProps = dispatch => {
  return {
    signOut: () => dispatch(signOut())
  };
};

export default withStyles(styles)(
  compose(
    connect(mapStateToProps),
    connect(mapDispatchToProps),
    firestoreConnect(props => [
      { collection: 'users', limit: 300 },

      // {
      //   collection: "retailers",
      //   // doc: "OnjB1u7Nqb3ZoKW5K3sq",

      //   where: ["id", "==", "OnjB1u7Nqb3ZoKW5K3sq"],

      //   // orderBy: ["retailerName", "asc"],
      //   limit: 1,
      //   storeAs: "retailerInPlay"
      // },
      // {
      //   collection: "retailers",
      //   where: [["responderId", "==", props.auth.uid]],
      //   limit: 20,
      //   storeAs: "myRetailers"
      // },
      // {
      //   collection: "retailers",
      //   where: [["responderId", "==", props.auth.uid]],
      //   limit: 20,
      //   storeAs: "myRetailers"
      // },
      {
        collection: 'retailers',
        doc: props.match.params.retailer,
        // ["qId", "==", "60jNR4Raj1R0Ek458Qm9"]
        // ["qId", "==", props.question.data.id]
        // ["qId", "==", props.questionId]
        storeAs: 'activeRetailer'
      },
      {
        collection: 'responses',
        where: [
          // ["rtlrId", "==", "OnjB1u7Nqb3ZoKW5K3sq"]
          ['rtlrId', '==', props.match.params.retailer],
          ['phase', '==', props.match.params.phase] // added for phase III
          // ["qId", "==", "60jNR4Raj1R0Ek458Qm9"]
          // ["qId", "==", props.question.data.id]
          // ["qId", "==", props.questionId]
        ],
        orderBy: ['time', 'desc'],
        limit: 100,
        storeAs: 'myResponses'
      },
      {
        collection: 'responseSummaries',
        // doc: props.match.params.retailer,
        where: [
          ['retailerId', '==', props.match.params.retailer],
          ['phase', '==', props.match.params.phase] // added for phase III
        ],
        // where: [
        //   // ["rtlrId", "==", "OnjB1u7Nqb3ZoKW5K3sq"]
        //   ["doc", "==", props.match.params.retailer]
        //   // ["qId", "==", "60jNR4Raj1R0Ek458Qm9"]
        //   // ["qId", "==", props.question.data.id]
        //   // ["qId", "==", props.questionId]
        // ],
        // limit: 1,
        storeAs: 'responseSummary'
      },
      {
        collection: 'sections',
        where: [['phase', '==', props.match.params.phase]], // added for phase III
        limit: 15,
        orderBy: ['number', 'asc']
      },
      {
        collection: 'questions',
        where: [['phase', '==', props.match.params.phase]], // added for phase III
        limit: 50,
        orderBy: ['displayWeight', 'asc']
      },
      { collection: 'resources', limit: 30 }
      // {
      //   collection: "retailerResponseSummaries",
      //   where: [
      //     // ["rtlrId", "==", "OnjB1u7Nqb3ZoKW5K3sq"]
      //     ["rtlrId", "==", props.match.params.retailer]
      //     //   // ["qId", "==", "60jNR4Raj1R0Ek458Qm9"]
      //     //   // ["qId", "==", props.question.data.id]
      //     //   // ["qId", "==", props.questionId]
      //   ],
      //   limit: 1,
      //   storeAs: "retailerResponseSummaries"
      // }
      // { collection: "sections", limit: 12, orderBy: ["number", "asc"] },
      // {
      //   collection: "questions",
      //   limit: 50,
      //   orderBy: ["displayWeight", "asc"]
      // },
      // { collection: "resources", limit: 30 }
    ])
  )(SurveyAccordian)
);
