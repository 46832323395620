import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import cx from "classnames";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
// import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
// import FixedPlugin from "components/FixedPlugin/FixedPlugin.jsx";
// import ProtectedRoute from "layouts/ProtectedRoute";
import signedInRoutes from "routes/signedInRoutes";
import superUserSignedInRoutes from "routes/superUserSignedInRoutes";
import managerSignedInRoutes from "routes/managerSignedInRoutes";
import developerSignedInRoutes from "routes/developerSignedInRoutes";
import signedOutRoutes from "routes/signedOutRoutes";
import { signOut } from "_store/actions/authActions";
import AppInfo from "AppInfo";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
// import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

import appStyle from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.jsx";

// import image from "assets/img/sidebar-2.jpg";
// import logo from "assets/img/logo-white.svg";
import logo from "assets/img/vw-large@3x.png";

var ps;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      miniActive: false,
      image: null,
      color: "primary",
      bgColor: "white",
      hasImage: false,
      fixedClasses: "dropdown"
    };
    this.resizeFunction = this.resizeFunction.bind(this);
  }
  componentDidMount() {
    if (typeof window !== "undefined") {
      if (this.props.profile && this.props.profile.manager) {
        // console.log("in admin, not manager");
        // window.location.href = "/survey/myretailers";
        // return <Redirect to="/survey/myretailers" />;
        // browserHistory.push("/survey/myretailers");
      }
    }
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", this.resizeFunction);
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
    window.removeEventListener("resize", this.resizeFunction);
  }
  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      if (this.refs.mainPanel) this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  getRoute() {
    return this.props.location.pathname !== "/admin/full-screen-maps";
  }
  getRoutesVersion() {
    // console.log(this.props.auth && this.props.auth);
    // console.log(this.props.profile && this.props.profile);
    // return this.props.auth.uid ? signedInRoutes : signedOutRoutes;
    if (this.props.auth && this.props.auth.uid && this.props.profile.isLoaded) {
      if (this.props.profile.isLoaded && this.props.profile.developer) {
        // console.log(this.props.profile);
        // console.log("developer", this.props.profile.developer);
        return developerSignedInRoutes;
      } else {
        if (this.props.profile && this.props.profile.superUser) {
          // console.log("manager", this.props.profile.manager);
          return superUserSignedInRoutes;
        } else if (this.props.profile && this.props.profile.manager) {
          // console.log("manager", this.props.profile.manager);
          return managerSignedInRoutes;
        } else {
          // console.log("responder", this.props.profile);
          return signedInRoutes;
        }
      }
    } else {
      return signedOutRoutes;
    }
    // if (this.props.auth.uid && this.props.auth.uid) {
    //   if (this.props.profile.role && this.props.profile.manager) {
    //     return managerSignedInRoutes;
    //   } else {
    //     return signedInRoutes;
    //   }
    // } else {
    //   return signedOutRoutes;
    // }
  }
  getActiveRoute = () => {
    let activeRoute = "EV Readiness";
    let routes = this.getRoutesVersion();
    // console.log("in getActiveRoute");
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = this.getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          // console.log("collapseActiveRoute", collapseActiveRoute);
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          // console.log("active route", routes[i].name);
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  getRoutes = () => {
    let routes = this.getRoutesVersion();
    // console.log(routes);
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.component && prop.layout === "/admin") {
        // console.log("prop.role", prop.role);
        // console.log(this.props.profile);
        if (this.props.profile.isLoaded && prop.role === "manager") {
          if (this.props.profile.manager) {
            // console.log("returning manager route", prop);
            return (
              <Route
                path={prop.layout + prop.path}
                component={prop.component}
                key={key}
              />
            );
          } else {
            // console.log("returning null", prop);
            return null;
          }
        } else {
          // console.log("returning normal route", prop);
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        }
      } else {
        return null;
      }
    });
  };
  sidebarMinimize() {
    this.setState({ miniActive: !this.state.miniActive });
  }
  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  }
  render() {
    if (this.props.profile && this.props.profile.manager) {
      // console.log("in admin, not manager");
      // return <Redirect to="/survey/myretailers" />;
      // browserHistory.push("/survey/myretailers");
    }
    // console.log("in admin", this.props);
    const { classes, ...rest } = this.props;
    const routes = this.getRoutesVersion();
    const mainPanel =
      classes.mainPanel +
      " " +
      cx({
        [classes.mainPanelSidebarMini]: this.state.miniActive,
        [classes.mainPanelWithPerfectScrollbar]:
          navigator.platform.indexOf("Win") > -1
      });
    return (
      <div className={classes.wrapper}>
        <Sidebar
          routes={routes}
          logoText={"EV Readiness"}
          logo={logo}
          image={this.state.image}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color={this.state.color}
          bgColor={this.state.bgColor}
          miniActive={this.state.miniActive}
          signOut={this.signOut}
          {...rest}
        />
        <div className={mainPanel} ref="mainPanel">
          <AdminNavbar
            sidebarMinimize={this.sidebarMinimize.bind(this)}
            miniActive={this.state.miniActive}
            brandText={this.getActiveRoute(routes)}
            handleDrawerToggle={this.handleDrawerToggle}
            {...rest}
          />
          {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
          {this.getRoute() ? (
            <div className={classes.content}>
              <div className={classes.container}>
                <Switch>{this.getRoutes(routes)}</Switch>
              </div>
              <GridContainer>
                <GridItem>
                  <Card plain>
                    <CardBody>
                      <div>
                        <br />
                        <br />
                        This site is built for modern web browsers like Chrome,
                        Firefox or Microsoft Edge..
                        <br />
                        If you are having problems in Internet Explorer, please
                        update it to the latest version - or use Chrome, Firefox
                        or Microsoft Edge instead.
                      </div>
                      <div>
                        If you still have a problem, please contact&nbsp;
                        <a href="mailto:evreadiness@helpfulconsultants.com?subject=Survey">
                          evreadiness@helpfulconsultants.com
                        </a>
                        &nbsp;and we will be glad to help. Thanks.
                      </div>
                      <br />
                      <AppInfo />
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            </div>
          ) : (
            <div className={classes.map}>
              <Switch>{this.getRoutes(routes)}</Switch>
            </div>
          )}

          {/* {this.getRoute() ? <Footer fluid /> : null} */}
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};
const mapStateToProps = state => {
  // console.log("in admin mapStateToProps", state);
  // if (state.firebase.auth && state.firebase.auth.uid) {
  //   console.log("in admin mapStateToProps", state.firebase.auth.uid);
  //   return {
  //     auth: state.firebase.auth,
  //     profile: state.firebase.profile
  //   };
  // } else {
  //   // console.log("in admin mapStateToProps", state);
  //   return {
  //     auth: state.firebase.auth,
  //     profile: state.firebase.profile
  //   };
  // }

  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
    // questions: state.firestore.ordered.questions
  };
};
const mapDispatchToProps = dispatch => {
  return {
    signOut: () => dispatch(signOut())
  };
};
// export default withStyles(appStyle)(Dashboard);
export default withStyles(appStyle)(
  compose(
    connect(
      mapStateToProps,
      mapDispatchToProps
    ),
    firestoreConnect(props => [
      // { collection: "users", doc: props.auth.uid, storeAs: "activeUser" },
      { collection: "users", limit: 300, orderBy: ["lastName", "asc"] },
      { collection: "retailers", limit: 300, orderBy: ["retailerName", "asc"] }

      // {
      //   collection: "retailers",
      //   where: ["responderId", "==", props.auth.uid],
      //   orderBy: ["retailerName", "asc"],
      //   storeAs: "myRetailers"
      // }
      // {
      //   collection: "retailers",
      //   where: [["sandbox", "==", true], ["responderId", "==", null]],
      //   // orderBy: ["responderId", "asc"],
      //   orderBy: ["retailerName", "asc"],
      //   storeAs: "unclaimedRetailers"
      // },
      // {
      //   collection: "retailers",
      //   where: [["responderId", "==", props.auth.uid]],
      //   storeAs: "myRetailers"
      // },
      // { collection: "sections", limit: 15, orderBy: ["number", "asc"] },
      // {
      //   collection: "questions",
      //   limit: 50,
      //   orderBy: ["displayWeight", "asc"]
      // },
      // { collection: "resources", limit: 30 }
      // { collection: "retailers", where: [["responderId", "==", props.auth.uid]] }
    ])
  )(Dashboard)
);
