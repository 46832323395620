import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase';
// Views and components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Table from 'components/Table/Table';
import Button from 'components/CustomButtons/Button';
import RegularButton from 'components/CustomButtons/Button';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import PercentageAnswered from 'views/Surveys/PercentageAnswered';
import PercentageReady from 'views/Surveys/PercentageReady';
// Actions
import { unclaimRetailer } from '../../_store/actions/retailerActions';
// Styling
import { withStyles } from '@material-ui/core/styles';
import extendedTablesStyle from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle';
import { BeatLoader } from 'react-spinners';
import { Typography } from '@material-ui/core';
import Close from '@material-ui/icons/Close';

class ClaimedRetailersTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: []
    };
    // this.handleToggle = this.handleToggle.bind(this);
  }

  render() {
    // console.log(handleUnclaim && handleUnclaim);

    const {
      classes,
      // auth,
      profile,
      myRetailers,
      myResponseSummaries,
      // phaseTwoQuestions,
      // phaseThreeQuestions,
      phaseFourQuestions
    } = this.props;

    const phase2 = '2';
    const phase3 = '3';
    const phase4 = '4';
    // console.log(this.props);
    // console.log("myRetailers loaded", isLoaded(myRetailers));
    // console.log("myRetailers empty", isEmpty(myRetailers));

    // Function passed down to paginated table footer component, which sends up the page selected
    const handleUnclaim = retailer => {
      // console.log("unclaim clicked for ", retailer.retailerName);
      this.props.unclaimRetailer(retailer);
    };
    // const simpleButtons = [
    //   { color: "info", icon: Person },
    //   { color: "success", icon: Edit },
    //   { color: "danger", icon: Close }
    // ].map((prop, key) => {
    //   return (
    //     <Button
    //       color={prop.color}
    //       simple
    //       className={classes.actionButton}
    //       key={key}
    //       onClick={handleUnclaim}
    //     >
    //       <prop.icon className={classes.icon} />
    //     </Button>
    //   );
    // });
    // console.log(simpleButtons);

    const getResponseSummaryForRetailer = retailerId => {
      // console.log(myResponseSummaries);
      // console.log(retailerId);
      const returnObj =
        (myResponseSummaries &&
          myResponseSummaries.find(
            responseSummary =>
              responseSummary.retailerId === retailerId &&
              responseSummary.phase === '4' // for phase 4
          )) ||
        {};
      // console.log(returnObj);
      return returnObj;
    };

    const getResponseStatus = (myResponseSummaries, retailerId, phase) => {
      // console.log(myResponseSummaries && myResponseSummaries);
      // console.log(retailerId && retailerId);
      // console.log("phase", phase);
      const surveyStarted =
        myResponseSummaries &&
        myResponseSummaries.find(
          responseSummary =>
            responseSummary.hasOwnProperty('time') &&
            responseSummary.retailerId === retailerId &&
            responseSummary.phase === phase
        );
      // console.log(surveyStarted);
      return surveyStarted ? true : false;
    };

    const tableHead = ['Mine?', 'Site', 'Phase IV'];
    const retailerData = myRetailers
      ? myRetailers.map(retailer => [
          <Button
            color={'danger'}
            className={classes.actionButton}
            key={retailer.id}
            onClick={() => handleUnclaim(retailer)}
          >
            <Close />
          </Button>,
          <Fragment>
            <strong>{`${retailer.retailerName} (${retailer.retailerNumber})`}</strong>
            <br />
            <PercentageAnswered
              responseSummary={getResponseSummaryForRetailer(retailer.id)}
              questions={phaseFourQuestions}
            />
            <PercentageReady
              responseSummary={getResponseSummaryForRetailer(retailer.id)}
              questions={phaseFourQuestions}
            />
          </Fragment>,

          <RegularButton
            disabled={false}
            component={Link}
            variant='contained'
            color='primary'
            to={`/admin/myretailers/survey/${retailer.id}/${phase4}`}
            className={'classes.button'}
          >
            Phase IV Survey
          </RegularButton>
          //   getResponseStatus(myResponseSummaries, retailer.id, phase2) ? (
          //     <RegularButton
          //       component={Link}
          //       variant='contained'
          //       color='gray'
          //       to={`/admin/myretailers/responses/${retailer.id}/${phase2}`}
          //       className={'classes.button'}
          //     >
          //       See Phase II Answers
          //     </RegularButton>
          //   ) : (
          //     <RegularButton
          //       disabled={true}
          //       component={Link}
          //       variant='contained'
          //       color='transparent'
          //       to={`/admin/myretailers/survey/${retailer.id}/${phase2}`}
          //       className={'classes.button'}
          //     >
          //       Phase II closed
          //     </RegularButton>
          //   )
        ])
      : [];

    // console.log(retailerData);
    // console.log("classes", classes);

    // const tableHead = [];
    // const tableData = [];
    // const retailerArray = retailers && retailers;
    // const tableDummyData = [["Mine?", "Name1", "1"], ["Yours?", "Name2", "2"]];
    // const sampleRetailerArray = [
    //   { responderId: "Mine?", retailerName: "Name1", retailerNumber: "1" },
    //   { responderId: "Mine?", retailerName: "Name2", retailerNumber: "2" },
    //   { responderId: "Mine?", retailerName: "Name3", retailerNumber: "3" }
    // ];
    // // console.log("sampleRetailerArray", sampleRetailerArray[0]);

    // const tableData = tableDummyData;
    // const tableData = [["Name", "Number", "Mine?"]];
    // const tableData = [
    //   [retailerArray[0].retailerName, retailerArray[0].retailerName, "ggg"]
    // ];

    // const tableData =
    //   retailerArray &&
    //   retailerArray.length > 0 &&
    //   retailerArray.map(retailer => {
    //     return (
    //       retailer => retailer.retailerName, retailer.retailerNumber, "button"
    //     );
    //   });

    // console.log(theme);
    // console.log(profile);
    // const count = retailers && retailers.length;
    // console.log(auth.uid);
    // console.log(retailers && retailers);
    // if (!auth.uid) return <Redirect to="/admin/signin" />;

    const dataIsLoaded = isLoaded(myResponseSummaries) && isLoaded(myRetailers);

    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color='rose' icon>
              <h4 className={classes.cardIconTitle}>Phase IV now live</h4>
            </CardHeader>
            <CardBody>
              <div>
                <Typography component='p'>
                  Thanks for completing the phase III survey checklist, which
                  was about creating an action plan for your site.
                </Typography>
                <br />
                <Typography component='p'>
                  Phase IV is now live. This phase is to confirm that you have
                  everything in place in terms of infrastructure and training to
                  align with the new contracts that go live on 1st April and the
                  market introduction of ID.3. Like the last phase, your answers
                  should be updated by the end of each month from January
                  through April 2020.
                </Typography>
                <br />
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardHeader color='rose' icon>
              <h4 className={classes.cardIconTitle}>
                Your claimed Retailers...
              </h4>
            </CardHeader>
            <CardBody>
              {profile && profile.superUser ? (
                <div>
                  <Typography component='p'>
                    <strong>{`Hello ${profile.firstName}. `}</strong>
                    <strong>
                      As a super user, you have some 'sandbox' retailers of
                      different types named after you. These might be a full
                      retailer or not and might be a battery competence centre
                      (BCC). The set of questions varies by retailer type. You
                      can play about with these retailers and get used to the
                      survey. You can 'claim' them and 'unclaim' them.
                    </strong>
                    <br />
                    <br />
                    <strong>
                      Your sandbox retailers are not included in any progress
                      reports, nor are they visible to the network.
                    </strong>
                  </Typography>
                  <br />
                </div>
              ) : null}
              {dataIsLoaded ? (
                isEmpty(myRetailers) ? (
                  'No Retailers claimed.'
                ) : myRetailers && myRetailers.length > 0 ? (
                  <div>
                    <Typography component='p'>
                      If you see a Retailer here who is not yours, please use
                      the X to remove it from your list.
                    </Typography>

                    <Table
                      hover
                      tableHeaderColor='primary'
                      tableHead={tableHead}
                      tableData={retailerData}
                      coloredColls={[3]}
                      colorsColls={['primary']}
                    />

                    <Typography component='p'>&nbsp;</Typography>

                    <Typography component='p'>
                      {myRetailers.length > 0
                        ? 'Click the button to start, update or just view the survey.'
                        : null}
                    </Typography>
                    <Typography component='p'>&nbsp;</Typography>
                  </div>
                ) : null
              ) : (
                <BeatLoader sizeUnit={'px'} size={30} color={'gray'} />
              )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = state => {
  // console.log(state);
  // console.log("state", state);
  // const uid = state.firebase.auth.uid && state.firebase.auth.uid;
  // console.log("state.firebase.auth.uid", id);
  // const allRetailers = state.firestore.ordered.myRetailers;
  // console.log(state.firestore.ordered.retailers);
  // console.log(allRetailers && allRetailers.length);
  // const ClaimedRetailers = allRetailers && allRetailers.filter(retailer => retailer.number);
  // console.log(uid);
  // console.log(allRetailers);
  // const myRetailers = allRetailers
  //   ? allRetailers.filter(retailer => retailer.responderId === uid)
  //   : [];
  // const myRetailers = allRetailers;
  // console.log(myRetailers);
  const allQuestions = state.firestore.ordered.questions;
  // console.log(allQuestions);
  // const phaseTwoQuestions = allQuestions.filter(question => question.phase === "2")
  const phaseFourQuestions =
    (allQuestions && allQuestions.filter(question => question.phase === '4')) ||
    [];
  //   : [];
  return {
    myRetailers: state.firestore.ordered.myRetailers,
    myResponseSummaries: state.firestore.ordered.myResponseSummaries,
    // phaseTwoQuestions: phaseTwoQuestions,
    // phaseThreeQuestions: phaseThreeQuestions,
    phaseFourQuestions: phaseFourQuestions,
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};

const mapDispatchToProps = dispatch => {
  return {
    unclaimRetailer: retailer => dispatch(unclaimRetailer(retailer))
  };
};
ClaimedRetailersTable.propTypes = {
  classes: PropTypes.object,
  showGeneric: PropTypes.bool,
  questions: PropTypes.array,
  responseSummaries: PropTypes.array
};
ClaimedRetailersTable.defaultProps = {
  showGeneric: false,
  // retailers: [],
  auth: {},
  profile: {}
};

export default withStyles(extendedTablesStyle)(
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(props => [
      {
        collection: 'retailers',
        where: [['responderId', '==', props.auth.uid]],
        limit: 20,
        storeAs: 'myRetailers'
      },
      {
        collection: 'responseSummaries',
        where: [['responderId', '==', props.auth.uid]],
        limit: 40,
        storeAs: 'myResponseSummaries'
      },
      {
        collection: 'questions',
        limit: 100,
        orderBy: ['displayWeight', 'asc']
      }
      // {
      //   collection: "retailers",
      //   where: [["responderId", "==", props.auth.uid]],
      //   limit: 20,
      //   storeAs: "myRetailers"
      // }
      // { collection: "retailers", limit: 50 }
    ])
  )(ClaimedRetailersTable)
);
