import actionConstants from "../constants/actionConstants";

const initState = {
  retailers: []
};

const retailerReducer = (state = initState, action) => {
  switch (action.type) {
    case actionConstants.CREATE_RETAILER_SUCCESS:
      // console.log("created retailer", action.retailer);
      break;

    case actionConstants.CLAIM_RETAILER_SUCCESS:
      // console.log("claimed retailer", action.retailer);
      break;

    case actionConstants.CUNLAIM_RETAILER_SUCCESS:
      // console.log("unclaimed retailer", action.retailer);
      break;

    default:
      return state;
  }
  return state;
};

export default retailerReducer;
