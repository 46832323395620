import SignIn from "views/Auth/SignIn.jsx";
import SignUp from "views/Auth/SignUp.jsx";
// import SignedOut from "views/Auth/SignedOut";
import ResetPassword from "views/Auth/ResetPassword";

// import ErrorPage from "ErrorPage";

import HowToRegIcon from "@material-ui/icons/HowToReg";

import LockOpenIcon from "@material-ui/icons/LockOpen";
import LockIcon from "@material-ui/icons/Lock";

var dashRoutes = [
  // {
  //   path: "/home",
  //   name: "Homer",
  //   rtlName: "لوحة القيادة",
  //   icon: HomeIcon,
  //   component: SignIn,
  //   layout: "/admin"
  // },
  // {
  //   path: "/error",
  //   name: "Error Page",
  //   rtlName: "لوحة القيادة",
  //   icon: HomeIcon,
  //   component: ErrorPage,
  //   layout: "/admin"
  // },
  {
    path: "/signup",
    name: "Sign up",
    rtlName: "لوحة القيادة",
    icon: HowToRegIcon,
    component: SignUp,
    layout: "/admin"
  },
  {
    path: "/resetpassword",
    name: "Reset Password",
    rtlName: "لوحة القيادة",
    icon: LockIcon,
    component: ResetPassword,
    layout: "/admin",
    linkNeeded: true
  },
  {
    path: "/signin",
    name: "Sign in",
    rtlName: "لوحة القيادة",
    icon: LockOpenIcon,
    component: SignIn,
    layout: "/admin"
  }
  // {
  //   path: "/default",
  //   name: "Sign out",
  //   rtlName: "لوحة القيادة",
  //   icon: LockIcon,
  //   component: SignIn,
  //   layout: "/admin",
  //   signout: true
  // }
];
export default dashRoutes;
