import React from "react";
// react component for creating dynamic tables
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { firestoreConnect, isLoaded, isEmpty } from "react-redux-firebase";
import ReactTable from "react-table";
// Views and components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
//Actions
import { claimRetailer } from "_store/actions/retailerActions";
// Styling
import withStyles from "@material-ui/core/styles/withStyles";
import AddBoxIcon from "@material-ui/icons/AddBox";
import { BeatLoader } from "react-spinners";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const tableColumns = [
  {
    Header: "Nr",
    accessor: "retailerNumber",
    width: 150
  },
  {
    Header: "Retailer",
    accessor: "retailerName"
  },
  {
    Header: "Claim",
    accessor: "actions"
  }
];

const RetailersTableWithClaimBtn = ({ ...props }) => {
  const { classes, auth, profile, retailers, users } = props;
  if (!auth.uid) return <Redirect to="/admin/signin" />;

  // console.log("retailers loaded", isLoaded(retailers));
  // console.log("retailers empty", isEmpty(retailers));
  // console.log("users loaded", isLoaded(users));
  // console.log("users empty", isEmpty(users));
  // console.log("retailers", retailers.length);
  // console.log("users", users.length);

  const isManager = profile.manager;
  // console.log("isManager", isManager);

  // const role = state.firebase.profile.role && state.firebase.profile.role;
  // if ( role &&role === "manager") {
  // }

  // console.log(allRetailers && allRetailers.length);

  const dataIsLoaded =
    isLoaded(retailers) &&
    isLoaded(users) &&
    !isEmpty(retailers) &&
    !isEmpty(users) &&
    retailers &&
    retailers.length > 0 &&
    users &&
    users.length > 0;

  if (dataIsLoaded) {
    const userNameArray = users.map(user => {
      return {
        id: user.id,
        displayName: user.firstName + " " + user.lastName
      };
    });

    const filteredRetailers = isManager
      ? retailers.filter(retailer => retailer.sandbox)
      : retailers.filter(retailer => !retailer.sandbox);
    // const role = state.firebase.profile.role && state.firebase.profile.role;
    // if ( role &&role === "manager") {
    // }

    // console.log(filteredRetailers && filteredRetailers.length);

    const handleClaim = retailer => {
      console.log("unclaim clicked for ", retailer.retailerName);
      props.claimRetailer(retailer);
    };

    const tableData = filteredRetailers.map(retailer => {
      return {
        id: retailer.id,
        retailerName: retailer.retailerName,
        retailerNumber: retailer.retailerNumber,
        actions: retailer.responderId ? (
          users.filter(user => user.id === retailer.responderId) ? (
            userNameArray &&
            userNameArray
              .filter(prop => prop.id === retailer.responderId)
              .map(prop => (prop.displayName ? prop.displayName : "-"))
          ) : (
            "-"
          )
        ) : (
          <Button
            size={"sm"}
            color={"warning"}
            className={classes.actionButton}
            key={retailer.id}
            onClick={() => handleClaim(retailer)}
          >
            <AddBoxIcon />
            &nbsp;Claim as Sole Responder&nbsp;
          </Button>
        )
      };
    });

    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <h4 className={classes.cardIconTitle}>
                Retailer Sites for EV Survey
              </h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={tableData}
                filterable={false}
                columns={tableColumns}
                defaultPageSize={25}
                showPaginationTop
                showPaginationBottom={false}
                className="-striped -highlight"
                defaultSorted={[
                  {
                    id: "retailerName",
                    desc: false
                  }
                ]}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  } else {
    return <BeatLoader sizeUnit={"px"} size={30} color={"gray"} />;
  }
};

const mapStateToProps = state => {
  // const isManager = state.firebase.profile.manager;
  // console.log("isManager", isManager);

  // const allRetailers = state.firestore.ordered.retailers;
  // const role = state.firebase.profile.role && state.firebase.profile.role;
  // if ( role &&role === "manager") {
  // }

  // console.log(allRetailers && allRetailers.length);

  // const filteredRetailers = isManager
  //   ? allRetailers && allRetailers.filter(retailer => retailer.sandbox)
  //   : allRetailers && allRetailers.filter(retailer => !retailer.sandbox);
  // console.log(filteredRetailers && filteredRetailers.length);
  return {
    retailers: state.firestore.ordered.retailers,
    users: state.firestore.ordered.users,
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};

const mapDispatchToProps = dispatch => {
  return {
    claimRetailer: retailer => dispatch(claimRetailer(retailer))
  };
};
RetailersTableWithClaimBtn.propTypes = {
  classes: PropTypes.object,
  retailers: PropTypes.array,
  users: PropTypes.array
};
RetailersTableWithClaimBtn.defaultProps = {
  retailers: [],
  users: [],
  auth: {},
  profile: {}
};

export default withStyles(styles)(
  compose(
    connect(
      mapStateToProps,
      mapDispatchToProps
    ),
    firestoreConnect(props => [
      { collection: "retailers", limit: 250 },
      { collection: "users", limit: 250 }
    ])
  )(RetailersTableWithClaimBtn)
);
