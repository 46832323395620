import actionConstants from "../constants/actionConstants";

const initState = {
  authError: null,
  authChangeSuccess: null
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case actionConstants.SIGNIN_ERROR:
      // console.log("sign in error");
      return {
        ...state,
        authError: "Sign in failed. Email or password not recognized."
      };
    case actionConstants.SIGNIN_SUCCESS:
      // console.log("signin success");
      return {
        ...state,
        authError: null
      };
    case actionConstants.SIGNOUT_SUCCESS:
      // console.log("sign out success");
      return state;
    case actionConstants.SIGNUP_SUCCESS:
      // console.log("signup success");
      return state;
    case actionConstants.SIGNUP_ERROR:
      // console.log("signup error");
      // console.log(action.err.message);
      return {
        ...state,
        authError: action.err.message
      };
    case actionConstants.USER_UPDATE_SUCCESS:
      // console.log("USER_UPDATE success");
      return {
        ...state,
        authError: null
      };
    case actionConstants.USER_UPDATE_ERROR:
      // console.log("USER_UPDATE error");
      // console.log(action.err.message);
      return {
        ...state,
        authError: action.err.message
      };
    case actionConstants.PASSWORD_CHANGE_SUCCESS:
      // console.log("PASSWORD_CHANGE success");
      return {
        ...state,
        authError: null,
        authChangeSuccess: "Password changed OK."
      };
    case actionConstants.PASSWORD_CHANGE_ERROR:
      // console.log("PASSWORD_CHANGE error");
      // console.log(action.err.message);
      return {
        ...state,
        authError: action.err.message
      };
    default:
      return state;
  }
};

export default authReducer;
