import React from "react";
import { connect } from "react-redux";
// import { compose } from "redux";
// import { firestoreConnect, isLoaded, isEmpty } from "react-redux-firebase";
import PropTypes from "prop-types";
// import diff from "object-diff";

// import { Field } from "react-final-form";
// import { Field } from "react-final-form-html5-validation";
// View and components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
// import LoadSaveReinitializeForm from "views/Responses/ResponseLoadSaveReinitializeFormSingle";
// Actions

// Styling
import { withStyles } from "@material-ui/core/styles";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

// import { TextField, Radio } from "final-form-material-ui";
// import { FormControlLabel } from "@material-ui/core";
import UnansweredIcon from "@material-ui/icons/RadioButtonUnchecked";
import TBAIcon from "@material-ui/icons/PauseCircleOutline";
import YesIcon from "@material-ui/icons/CheckCircleOutline";
import NoIcon from "@material-ui/icons/HighlightOff";

const styles = {
  ...regularFormsStyle,
  yesIcon: {
    width: "16px",
    height: "16px",
    fontSize: "16px",
    viewBox: "0 0 16 16",
    lineHeight: "16px",
    backgroundColor: "white !important",
    color: "#51ae30 !important",
    border: "2px solid #51ae30",
    borderRadius: "50%",
    marginBottom: "-3px"
  },
  noIcon: {
    width: "16px",
    height: "16px",
    fontSize: "16px",
    viewBox: "0 0 16 16",
    lineHeight: "16px",
    color: "#e4002c !important",
    backgroundColor: "white !important",
    border: "2px solid #333",
    borderRadius: "50%",
    marginBottom: "-3px"
  },
  noButOKIcon: {
    width: "16px",
    height: "16px",
    fontSize: "16px",
    viewBox: "0 0 16 16",
    lineHeight: "16px",
    color: "#51ae30 !important",
    backgroundColor: "white !important",
    border: "2px solid #51ae30",
    borderRadius: "50%",
    marginBottom: "-3px"
  },
  tbaIcon: {
    color: "orange",
    width: "16px",
    height: "16px",
    fontSize: "16px",
    lineHeight: "16px",
    backgroundColor: "white !important",
    border: "2px solid #333",
    borderRadius: "50%",
    marginBottom: "-3px"
  },
  unansweredIcon: {
    color: "gray",
    width: "16px",
    height: "16px",
    fontSize: "16px",
    lineHeight: "16px",
    backgroundColor: "white !important",
    border: "1px solid #333",
    borderRadius: "50%",
    marginBottom: "-3px"
  }
};
// import classNames from "classnames";

// const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// const load = async () => {
//   await sleep(300);
//   return {
//     notes: "",
//     retailerNumber: "",
//     bcc: false
//   };
// };

const SurveyQuestionResponse = ({ ...props }) => {
  const {
    classes,
    // section,
    question,
    // metaData,
    // reportingMode,
    responseSummary
  } = props;

  // console.log(this.state);
  // console.log(metaData);

  // console.log("in SurveyQuestionResponse");
  // console.log(responseSummary && responseSummary);

  const loadingText = <div className="loading">Loading data ...</div>;
  const responseYnA = `${question.shortCode}-ynA`;
  const responseTxtA = `${question.shortCode}-txtA`;
  const responseNote = `${question.shortCode}-note`;
  const wallboxShortcode = "P3S4UKQ2";
  // if (question.shortCode === wallboxShortcode) {
  //   console.log("in SurveyQuestionResponse", question);
  // }

  // need to halt until this.state.loading === false otherwise the form starts seeing differenece and saving records

  return (
    <GridContainer>
      {question && question.id ? (
        <GridItem xs={12}>
          <GridContainer>
            <GridItem xs={12}>
              <h6>
                {/* {response && response.shortCode
                  ? response.shortCode
                  : "no shortCode."} */}
                {responseSummary &&
                (responseSummary[responseYnA] ||
                  responseSummary[responseTxtA] ||
                  responseSummary[responseNote])
                  ? " Showing recorded response..."
                  : " Not answered"}
              </h6>
            </GridItem>
            {question.yesNoQuestion ? (
              <GridItem xs={12}>
                <GridContainer>
                  <GridItem xs={12} sm={4} md={3}>
                    {responseSummary &&
                    responseSummary[responseYnA] &&
                    responseSummary[responseYnA] === "TBA" ? (
                      <div>
                        <TBAIcon className={classes.tbaIcon} />
                        {"  I will find out"}
                      </div>
                    ) : (
                      <div>
                        <UnansweredIcon className={classes.unansweredIcon} />

                        {"  I will find out"}
                      </div>
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={4} md={3}>
                    {responseSummary &&
                    responseSummary[responseYnA] &&
                    responseSummary[responseYnA] === "Yes" ? (
                      <div>
                        <YesIcon className={classes.yesIcon} />
                        {" Yes"}
                      </div>
                    ) : (
                      <div>
                        <UnansweredIcon className={classes.unansweredIcon} />
                        {"  Yes"}
                      </div>
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={4} md={3}>
                    {responseSummary &&
                    responseSummary[responseYnA] &&
                    responseSummary[responseYnA] === "No" ? (
                      <div>
                        {question.shortCode === wallboxShortcode ? (
                          <NoIcon className={classes.noButOKIcon} />
                        ) : (
                          <NoIcon className={classes.noIcon} />
                        )}{" "}
                        {"  No"}
                      </div>
                    ) : (
                      <div>
                        <UnansweredIcon className={classes.unansweredIcon} />
                        {"  No"}
                      </div>
                    )}
                  </GridItem>
                </GridContainer>
              </GridItem>
            ) : null}
            {question.textQuestion ? (
              <div>
                <GridItem xs={12}>
                  <br />
                  <h6>{"Text: "}</h6>
                  {responseSummary && responseSummary[responseTxtA] ? (
                    <div>{responseSummary[responseTxtA]}</div>
                  ) : (
                    "-"
                  )}
                </GridItem>
              </div>
            ) : null}
            <GridItem xs={12}>
              <GridContainer>
                <GridItem xs={12} lg={8}>
                  <h6>Notes and Actions:</h6>
                  {responseSummary && responseSummary[responseNote]
                    ? responseSummary[responseNote]
                    : "-"}
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </GridItem>
      ) : (
        loadingText
      )}
    </GridContainer>
  );
};

SurveyQuestionResponse.propTypes = {
  classes: PropTypes.object,
  section: PropTypes.object,
  question: PropTypes.object,
  responseSummary: PropTypes.object
};

SurveyQuestionResponse.defaultProps = {
  classes: {},
  section: {},
  question: {}
};

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};

export default withStyles(styles)(
  connect(mapStateToProps)(SurveyQuestionResponse)
);
