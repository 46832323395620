import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
// Views and components

// import SurveyQuestion from "views/Surveys/SurveyQuestion";
// Styling
import { withStyles } from '@material-ui/core/styles';
// import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

// import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
// import CheckBoxIcon from "@material-ui/icons/CheckBox";
// import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import UnansweredIcon from '@material-ui/icons/RadioButtonUnchecked';
// import UnansweredIcon from "@material-ui/icons/Block";
import TBAIcon from '@material-ui/icons/PauseCircleOutline';
import YesIcon from '@material-ui/icons/CheckCircleOutline';
import NoIcon from '@material-ui/icons/HighlightOff';
// import NotAppliacableIcon from "@material-ui/icons/NotInterested";
// import { types } from "util";
// Styling
// import statusIconStyle from "assets/jss/material-dashboard-pro-react/components/statusIconStyle";
// nodejs library that concatenates classes
// import classNames from "classnames";

const styles = {
  yesIcon: {
    marginLeft: '1px',
    marginBottom: '-2px',
    color: '#51ae30',
    width: '16px',
    height: '16px',
    fontSize: '16px',
    viewBox: '0 0 16 16',
    lineHeight: '16px'
  },
  noIcon: {
    marginLeft: '1px',
    marginBottom: '-2px',
    color: '#e4002c',
    width: '16px',
    height: '16px',
    fontSize: '16px',
    lineHeight: '16px'
  },
  noButOKIcon: {
    marginLeft: '1px',
    marginBottom: '-2px',
    color: '#51ae30',
    width: '16px',
    height: '16px',
    fontSize: '16px',
    lineHeight: '16px'
  },
  tbaIcon: {
    marginLeft: '1px',
    marginBottom: '-2px',
    color: 'gray',
    width: '16px',
    height: '16px',
    fontSize: '16px',
    lineHeight: '16px'
  },
  unansweredIcon: {
    marginLeft: '1px',
    marginBottom: '-2px',
    color: '#e4002c',
    width: '16px',
    height: '16px',
    fontSize: '16px',
    lineHeight: '16px'
  }
};
// });

const SurveyQuestionScore = ({ ...props }) => {
  const { classes, question, response, responseSummary } = props;

  const responseYnA = question && `${question.shortCode}-ynA`;
  const responseTxtA = question && `${question.shortCode}-txtA`;
  const responseDtA = question && `${question.shortCode}-dtA`;

  // console.log(props);

  const getScoreForQuestion = (response, responseSummary) => {
    // const filteredResponses =
    //   responses &&
    //   responses.length > 0 &&
    //   responses.filter(response => response.qId === questionId);
    // const wallboxShortcode = 'P3S4UKQ2';
    const wallboxShortcode = 'P4S4Q3'; // for phase 3

    if (response) {
      //   console.log(response);
      // console.log(response.ynA);
      if (response.ynA) {
        // console.log(response.ynA);
        switch (response.ynA) {
          case 'Yes':
            if (question.twoAnswersIfYes) {
              //   console.log(response);
              if (response.txtA) {
                return <YesIcon className={classes.yesIcon} />;
              } else {
                return <UnansweredIcon className={classes.unansweredIcon} />;
              }
            } else {
              return <YesIcon className={classes.yesIcon} />;
            }
          case 'No':
            if (question.optionalQuestion) {
              return <NoIcon className={classes.noButOKIcon} />;
            } else {
              if (question.twoAnswersIfNo) {
                // console.log('twoAnswersIfNo');
                // console.log(response);
                if (response.txtA) {
                  return <NoIcon className={classes.noIcon} />;
                } else {
                  return <UnansweredIcon className={classes.unansweredIcon} />;
                }
              } else {
                return <NoIcon className={classes.noIcon} />;
              }
            }
          case 'TBA':
            return <TBAIcon className={classes.tbaIcon} />;

          default:
            return <UnansweredIcon className={classes.unansweredIcon} />;
        }
      } else {
        if (response.dtA) {
          return <YesIcon className={classes.yesIcon} />;
        } else {
          if (response.txtA) {
            return <YesIcon className={classes.yesIcon} />;
          } else {
            return <UnansweredIcon className={classes.unansweredIcon} />;
          }
        }
      }
    } else if (responseSummary) {
      // console.log(responseSummary);
      // console.log(response.ynA);
      if (responseSummary[responseYnA]) {
        // console.log(response.ynA);
        switch (responseSummary[responseYnA]) {
          case 'Yes':
            if (question.twoAnswersIfYes) {
              if (responseSummary[responseTxtA]) {
                return <YesIcon className={classes.yesIcon} />;
              } else {
                return <UnansweredIcon className={classes.unansweredIcon} />;
              }
            } else {
              return <YesIcon className={classes.yesIcon} />;
            }
          case 'No':
            // console.log('No');
            if (question.optionalQuestion) {
              return <NoIcon className={classes.noButOKIcon} />;
            } else {
              if (question.twoAnswersIfNo) {
                console.log('twoAnswersIfNo');
                if (responseSummary[responseTxtA]) {
                  return <NoIcon className={classes.noIcon} />;
                } else {
                  return <UnansweredIcon className={classes.unansweredIcon} />;
                }
              } else {
                return <NoIcon className={classes.noIcon} />;
              }
            }
          case 'TBA':
            return <TBAIcon className={classes.tbaIcon} />;
          default:
            return <UnansweredIcon className={classes.unansweredIcon} />;
        }
      } else {
        if (responseSummary[responseDtA]) {
          return <YesIcon className={classes.yesIcon} />;
        } else {
          if (responseSummary[responseTxtA]) {
            return <YesIcon className={classes.yesIcon} />;
          } else {
            return <UnansweredIcon className={classes.unansweredIcon} />;
          }
        }
      }
    }
  };
  // console.log("in SurveySectionScores");
  //  console.log("props", props);
  // console.log(response);

  return question && (response || responseSummary) ? (
    <Fragment>{getScoreForQuestion(response, responseSummary)}</Fragment>
  ) : (
    <UnansweredIcon className={classes.unansweredIcon} />
  );
};

SurveyQuestionScore.propTypes = {
  classes: PropTypes.object,
  phase: PropTypes.string, // added for phase III
  question: PropTypes.object,
  response: PropTypes.object,
  responseSummary: PropTypes.object
};

SurveyQuestionScore.defaultProps = {
  classes: {},
  phase: '4', // added for phase III
  question: {}
};

export default withStyles(styles)(SurveyQuestionScore);
