import actionConstants from "../constants/actionConstants";

const initState = {
  retailerId: "123451234512345",
  retailerName: "Demo Retailer",
  retailerNumber: "12345",
  bcc: true
};

const activeRetailerReducer = (state = initState, action) => {
  switch (action.type) {
    case actionConstants.SET_ACTIVE_RETAILER_SUCCESS:
      console.log("setting active retailer");
      //   console.log(action);
      return {
        ...state,
        retailerId: action.activeRetailer.id,
        retailerName: action.activeRetailer.retailerName,
        retailerNumber: action.activeRetailer.retailerNumber,
        bcc: action.activeRetailer.bcc
          ? action.activeRetailer.bcc
          : false
      };
    default:
      return state;
  }
};

export default activeRetailerReducer;
