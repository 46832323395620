import actionConstants from "../constants/actionConstants";

export const createRetailer = retailer => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    // const profile = getState().firebase.profile;
    // const authorId = getState().firebase.auth.uid;
    // this is the bit updating the database
    firestore
      .collection("retailers")
      .add({
        ...retailer
      })
      .then(() => {
        dispatch({
          type: actionConstants.CREATE_RETAILER_SUCCESS,
          activeRetailer: retailer
        });
      })
      .catch(err => {
        dispatch({ type: actionConstants.CREATE_RETAILER_ERROR, err });
      });
  };
};

export const claimRetailer = retailer => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    const reporterId = getState().firebase.auth.uid;
    console.log("retailer claimed", retailer.retailerName);
    // this is the bit updating the database
    firestore
      .collection("retailers")
      .doc(retailer.id)
      .update({
        responderId: reporterId
      })
      .then(() => {
        dispatch({
          type: actionConstants.CLAIM_RETAILER_SUCCESS,
          retailer: retailer
        });
      })
      .catch(err => {
        dispatch({ type: actionConstants.CLAIM_RETAILER_ERROR, err });
      });
  };
};

export const unclaimRetailer = retailer => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    console.log("retailer unclaimed", retailer.retailerName);
    // const reporterId = getState().firebase.auth.uid;
    // console.log(retailer);
    // this is the bit updating the database
    firestore
      .collection("retailers")
      .doc(retailer.id)
      .update({
        // responderId: firebase.firestore.FieldValue.delete()
        responderId: null
      })
      // .then(resp => {
      //   console.log(resp);
      //   resp.update({
      //     responderId: firebase.firestore.FieldValue.delete()
      //   });
      // })
      .then(() => {
        dispatch({
          type: actionConstants.UNCLAIM_RETAILER_SUCCESS,
          retailer: retailer
        });
      })
      .catch(err => {
        dispatch({ type: actionConstants.UNCLAIM_RETAILER_ERROR, err });
      });
  };
};
