import MyRetailers from "views/Retailers/MyRetailers";
import ClaimRetailers from "views/Retailers/ClaimRetailers";
import SurveyAccordian from "views/Surveys/SurveyAccordian";
import RetailerResponseDetails from "views/Reports/RetailerResponseDetails";
// import PasswordResetForm from "views/Auth/ResetPassword";
import ChangePassword from "views/Auth/ChangePassword";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import StoreIcon from "@material-ui/icons/Store";
import TocIcon from "@material-ui/icons/Toc";

var dashRoutes = [
  // {
  //   path: "/home",
  //   name: "Home",
  //   rtlName: "لوحة القيادة",
  //   icon: HomeIcon,
  //   component: MyRetailers,
  //   layout: "/admin"
  // },

  {
    path: "/myretailers/responses/:retailer/:phase",
    name: "Retailer Report",
    rtlName: "لوحة القيادة",
    icon: TocIcon,
    component: RetailerResponseDetails,
    layout: "/admin",
    linkNeeded: false
  },
  {
    path: "/myretailers/survey/:retailer/:phase",
    name: "Survey",
    rtlName: "لوحة القيادة",
    icon: TocIcon,
    component: SurveyAccordian,
    layout: "/admin",
    linkNeeded: false
  },
  {
    path: "/myretailers",
    name: "My Retailers",
    rtlName: "لوحة القيادة",
    icon: StoreIcon,
    component: MyRetailers,
    layout: "/admin",
    linkNeeded: true
  },
  {
    path: "/claimretailers",
    name: "Claim Retailers",
    rtlName: "لوحة القيادة",
    icon: AddCircleIcon,
    component: ClaimRetailers,
    layout: "/admin",
    linkNeeded: true
  },
  {
    path: "/changepassword",
    name: "Change Password",
    rtlName: "لوحة القيادة",
    icon: LockOpenIcon,
    component: ChangePassword,
    layout: "/admin",
    linkNeeded: true
  },
  {
    path: "/signout",
    name: "Sign out",
    rtlName: "لوحة القيادة",
    icon: LockIcon,
    component: null,
    layout: "/admin",
    linkNeeded: true,
    signout: true
  }
];
export default dashRoutes;
