import React, { Fragment } from "react";
import { Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
// import { compose } from "redux";
// import { firestoreConnect } from "react-redux-firebase";
import PropTypes from "prop-types";
// Views and components
import RetailersTableWithClaimBtn from "views/Retailers/RetailersTableWithClaimBtn";
// import ClaimedRetailers from "views/Retailers/ClaimedRetailers";
// import UnclaimedRetailers from "views/Retailers/UnclaimedRetailers";
import ClaimedRetailersTable from "views/Retailers/ClaimedRetailersTable";
// Styling
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { Typography } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";

const styles = theme => ({
  root: {
    width: "100%"
  },
  heading: {
    fontSize: theme.typography.pxToRem(18)
    // fontWeight: theme.typography.fontWeightBolder
  },
  button: {
    margin: theme.spacing.unit
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  },
  iconSmall: {
    fontSize: 16
  }
});

const ClaimRetailers = ({ ...props }) => {
  const { classes, auth, profile } = props;

  if (!auth.uid) return <Redirect to="/admin/signin" />;
  return (
    <Fragment>
      <Typography component="p">
        &nbsp;Problem? Please contact &nbsp;
        <a href="mailto:evreadiness@helpfulconsultants.com?subject=Survey">
          evreadiness@helpfulconsultants.com
        </a>
        .
      </Typography>

      <ClaimedRetailersTable profile={profile} user={auth.uid} />
      <br />
      <Typography variant="h6" component="h6">
        Unclaimed Retailers
      </Typography>
      <Typography component="p">
        You can add Retailers to respond for from the list below. If you can't
        see the one you want, please contact us.
      </Typography>
      <br />
      <Typography
        component={Link}
        color="primary"
        to="myretailers"
        className={""}
      >
        <CancelIcon
          className={classNames(classes.leftIcon, classes.iconSmall)}
        />
        Close this list
      </Typography>
      <br />
      <Typography component="p">
        <strong>
          If the list does not load, you may need to refresh the page (e.g. with
          the F5 button).
        </strong>
      </Typography>
      <RetailersTableWithClaimBtn />
    </Fragment>
  );
};

ClaimRetailers.propTypes = {
  classes: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  // console.log(state);
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    activeUser: state.firestore.ordered.activeUser
  };
};
const mapDispatchToProps = dispatch => {
  return {};
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ClaimRetailers)
);
