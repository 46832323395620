import React, { Fragment } from "react";
import { Redirect, Link } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
// Views and components
import RegularButton from "components/CustomButtons/Button";
// import ClaimedRetailers from "views/Retailers/ClaimedRetailers";
import ClaimedRetailersTable from "views/Retailers/ClaimedRetailersTable";
// Actions
import { unclaimRetailer } from "_store/actions/retailerActions";
// Styling
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { Typography } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  },
  button: {
    margin: theme.spacing.unit
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  },
  rightIcon: {
    marginLeft: theme.spacing.unit
  },
  iconSmall: {
    fontSize: 20
  }
});

const MyRetailers = props => {
  // Function passed down to paginated table footer component, which sends up the page selected
  // const handleUnclaim = retailer => {
  //   console.log("unclaim clicked for ", retailer);
  //   props.unclaimRetailer(retailer);
  // };

  const { auth, profile, classes } = props;
  // console.log(theme);
  // console.log(profile);
  // const count = retailers && retailers.length;
  // console.log("auth.uid", auth.uid);
  // console.log(retailers && retailers);
  // console.log("in MyRetailers");
  const isManager = profile.role && profile.role.manager ? true : false;
  // if (!auth.uid) {
  //   return <Redirect to="/survey/signin" />;
  // }
  // console.log("in MyRetailers");
  if (!auth.uid) return <Redirect to="/admin/signin" />;
  return (
    <Fragment>
      <Typography component="p">
        &nbsp;Problem? Please contact &nbsp;
        <a href="mailto:evreadiness@helpfulconsultants.com?subject=Survey">
          evreadiness@helpfulconsultants.com
        </a>
        .
      </Typography>
      <ClaimedRetailersTable />
      <br />
      <Typography component="p">
        You can add a Retailer to respond for here:
      </Typography>
      <br />
      <RegularButton
        component={Link}
        variant="contained"
        color="primary"
        to={
          isManager === true ? "/admin/claimretailers" : "/admin/claimretailers"
        }
        className={"classes.button"}
      >
        <AddCircleIcon
          className={classNames(classes.rightIcon, classes.iconSmall)}
        />
        &nbsp; Claim Retailers
      </RegularButton>
    </Fragment>
  );
};

const mapStateToProps = state => {
  // console.log(state);

  const uid = state.firebase.auth.uid;
  // console.log("state.firebase.auth.uid", id);
  const allRetailers = state.firestore.ordered.myRetailers;
  // console.log(state.firestore.ordered.retailers);
  // console.log(allRetailers && allRetailers.length);
  // const myRetailers = allRetailers && allRetailers.filter(retailer => retailer.number);
  // console.log(uid);
  // console.log(allRetailers);
  const myRetailers =
    allRetailers &&
    allRetailers.filter(retailer => retailer.responderId === uid);
  // console.log(myRetailers);
  return {
    retailers: myRetailers,
    auth: state.firebase.auth,
    profile: state.firebase.profile
  };
};

const mapDispatchToProps = dispatch => {
  return {
    unclaimRetailer: retailer => dispatch(unclaimRetailer(retailer))
  };
};

export default withStyles(styles)(
  compose(
    connect(
      mapStateToProps,
      mapDispatchToProps
    ),
    firestoreConnect(props => [
      // {
      //   collection: "retailers",
      //   where: [["responderId", "==", props.auth.uid]],
      //   storeAs: "myRetailers"
      // }
      // { collection: "retailers", limit: 50 }
    ])
  )(MyRetailers)
);
