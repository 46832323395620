import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
// View and components
// import Primary from "components/Typography/Primary";
import Warning from 'components/Typography/Warning';
// import Info from "components/Typography/Info";
import Quote from 'components/Typography/Quote';
import Accordion from 'components/Accordion/Accordion';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import SurveyQuestionForm from 'views/Surveys/SurveyQuestionForm';
import SurveyQuestionResponse from 'views/Surveys/SurveyQuestionResponse';
// Styling
import { withStyles } from '@material-ui/core/styles';
import regularFormsStyle from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import { Typography, Success } from '@material-ui/core';

const getResourceForSection = (phase = '4', section, resources = []) => {
  //   console.log(phase && phase);
  //   console.log(section && section);
  //   console.log(resources && resources);
  let resourceText = '';
  let resourceToUse = {};

  if (resources.length > 0) {
    resourceToUse = resources.find(
      resource => resource.id === section.resourceId
    );
    // console.log(resourceToUse && resourceToUse);
    let resourceTextToUse =
      (resourceToUse && resourceToUse.text && resourceToUse.text) || '';
    // console.log(resourceToUse && resourceToUse);
    // console.log(resourceTextToUse && resourceTextToUse);
    if (resourceTextToUse && resourceTextToUse.length > 0) {
      return resourceTextToUse;
    }
  }
};

const getResourcesForQuestion = (resources = [], question = {}) => {
  // console.log("in getResourcesForQuestion");
  // console.log(question.resourceId);
  // console.log(resources && resources);

  const filteredResourcesArr =
    resources &&
    resources.length &&
    question.resourceId &&
    resources.filter(resource => resource.id === question.resourceId);
  let markup = '';

  if (question && question.resourceId) {
    // console.log(filteredResourcesArr);
    if (filteredResourcesArr && filteredResourcesArr.length > 0) {
      // console.log(filteredResourcesArr[0]);
      // console.log(filteredResourcesArr[0].text);
      filteredResourcesArr.forEach(resource => {
        if (resource.text && !resource.sectionRef) {
          // console.log("resource.text", resource.text);
          markup =
            markup +
            '<div key=' +
            question.id +
            '">' +
            resource.text +
            '<br /></div>';
          // markup + resource.text;
          // console.log(markup);
        }
        if (resource.url) {
          // console.log("resource.text", resource.url);
          markup =
            markup +
            '<div key=' +
            question.id +
            '">See <a href="' +
            resource.url +
            '" >' +
            resource.url +
            '</a><br /></div>';
          // markup + resource.text;
          // console.log(markup);
        }
        if (resource.url2) {
          // console.log("resource.text", resource.url2);
          markup =
            markup +
            '<div key=' +
            question.id +
            '">See <a href="' +
            resource.url2 +
            '" >' +
            resource.url2 +
            '</a><br /></div>';
          // markup + resource.text;
          // console.log(markup);
        }
      });

      return parse(markup);
    } else {
      return null;
    }
  } else {
    return null;
  }
};

const SurveyQuestion = ({ ...props }) => {
  const {
    classes,
    section,
    question,
    sequence,
    resources,
    response,
    responseSummary,
    metaData,
    reportingMode,
    showUKQuestions,
    phase // added for phase III
  } = props;

  // console.log(section && section);

  const loading = <div className='loading'>Loading data...</div>;
  // console.log(response && response);

  const resourceHtml =
    question && resources && getResourcesForQuestion(resources, question);

  return section && section.id && question ? (
    <GridContainer>
      <GridItem xs={12}>
        <Card key={question.id} product={true}>
          <CardHeader>
            <h5>
              <strong>
                {sequence}
                {': '} {question.text}
              </strong>
            </h5>
            {question.parentQuestionId ? (
              <strong>
                This question does not apply if you answer yes to the first one.
                <br /> <br />
              </strong>
            ) : null}
            {question.factoryQuestion === true ? null : (
              <Warning>
                This question is from Volkswagen UK, rather than from Volkswagen
                in Germany, but please answer it too.
              </Warning>
            )}
          </CardHeader>
          <CardBody>
            <GridContainer>
              {reportingMode ? null : question.clarification ? (
                <GridItem xs={12}>
                  <div className={classes.typo}>
                    <Quote
                      text={question.clarification}
                      author='Volkswagen UK Clarification'
                    />
                  </div>
                </GridItem>
              ) : null}
              {question.brandNote &&
              question.brandNote !== null &&
              (!question.brandNoteExceptions ||
                !question.brandNoteExceptions.includes(metaData.rtlrNbr)) ? (
                <GridItem xs={12}>
                  <div className={classes.typo}>
                    <Typography component='p'>
                      <strong>{`${question.brandNote} for ${metaData.rtlrName}.`}</strong>
                    </Typography>
                  </div>
                </GridItem>
              ) : null}

              <GridItem xs={12}>
                {reportingMode
                  ? question && (
                      <SurveyQuestionResponse
                        phase={phase} // added for phase III
                        section={section}
                        question={question}
                        metaData={metaData}
                        response={response}
                        responseSummary={responseSummary}
                        showUKQuestions={showUKQuestions}
                      />
                    )
                  : question && (
                      <SurveyQuestionForm
                        phase={phase} // added for phase III
                        section={section}
                        question={question}
                        metaData={metaData}
                        response={response}
                      />
                    )}
              </GridItem>
            </GridContainer>
            {reportingMode ? null : question.resourceId ? (
              <GridContainer>
                <GridItem xs={12}>
                  <div className={classes.typo}>
                    {question.resourceId !== null ? (
                      <div className={classes.typo}>
                        <br />
                        <h6 className={classes.note}>Help:</h6>
                        {resourceHtml}
                        {resources &&
                        resources.length &&
                        question.resourceId &&
                        resources.find(
                          resource =>
                            resource.id === question.resourceId &&
                            resource.sectionRef
                        ) ? (
                          <GridContainer>
                            <GridItem xs={12} sm={10} md={6} lg={6}>
                              <Accordion
                                collapses={[
                                  {
                                    title: 'Copy of the section help...',
                                    content: getResourceForSection(
                                      phase, // added for phase III
                                      section,
                                      resources
                                    )
                                  }
                                ]}
                              />
                            </GridItem>
                          </GridContainer>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                </GridItem>
              </GridContainer>
            ) : null}
            {reportingMode ? null : question.isParentQuestion ? (
              <GridContainer>
                <GridItem xs={12}>
                  <div className={classes.typo}>
                    <div key={question.id}>
                      <strong>
                        The other questions in this section only apply if you
                        answer no to this one.
                      </strong>
                      <br />
                    </div>
                  </div>
                </GridItem>
              </GridContainer>
            ) : null}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  ) : (
    loading
  );
};

SurveyQuestion.propTypes = {
  classes: PropTypes.object,
  section: PropTypes.object,
  question: PropTypes.object,
  resources: PropTypes.array,
  response: PropTypes.object
};

SurveyQuestion.defaultProps = {
  classes: {},
  section: {},
  question: {},
  resources: []
};

export default withStyles(regularFormsStyle)(SurveyQuestion);
