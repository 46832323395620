import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
// Views and components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import SurveyQuestion from 'views/Surveys/SurveyQuestion';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';

import SurveySectionScores from 'views/Surveys/SurveySectionScores';
// Styling
import { withStyles } from '@material-ui/core/styles';
import regularFormsStyle from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import UnansweredIcon from '@material-ui/icons/RadioButtonUnchecked';
import TBAIcon from '@material-ui/icons/PauseCircleOutline';
import YesIcon from '@material-ui/icons/CheckCircleOutline';
import NoIcon from '@material-ui/icons/HighlightOff';
// import NotApplicableIcon from "@material-ui/icons/NotInterested";

const styles = {
  regularFormsStyle,
  yesIcon: {
    marginLeft: '1px',
    marginBottom: '-4px',
    color: '#51ae30',
    width: '16px',
    height: '16px',
    fontSize: '16px',
    viewBox: '0 0 16 16',
    lineHeight: '16px'
  },
  noIcon: {
    marginLeft: '1px',
    marginBottom: '-4px',
    color: '#e4002c',
    width: '16px',
    height: '16px',
    fontSize: '16px',
    lineHeight: '16px'
  },
  noButOKIcon: {
    marginLeft: '1px',
    marginBottom: '-4px',
    color: '#51ae30',
    width: '16px',
    height: '16px',
    fontSize: '16px',
    lineHeight: '16px'
  },
  tbaIcon: {
    marginLeft: '1px',
    marginBottom: '-4px',
    color: 'gray',
    width: '16px',
    height: '16px',
    fontSize: '16px',
    lineHeight: '16px'
  },
  unansweredIcon: {
    marginLeft: '1px',
    marginBottom: '-4px',
    color: '#e4002c',
    width: '16px',
    height: '16px',
    fontSize: '16px',
    lineHeight: '16px'
  }
};

const getResponseForQuestion = (responses = [], questionId = '') => {
  const filteredResponses =
    responses &&
    responses.length > 0 &&
    responses.filter(response => response.qId === questionId);

  const response = filteredResponses && filteredResponses[0];

  if (response) {
    // console.log(response);
    return response;
  }
};

const SurveySection = ({ ...props }) => {
  const {
    classes,
    section,
    questions,
    resources,
    responses,
    responseSummary,
    metaData,
    reportingMode,
    showUKQuestions,
    phase // added for phase III
  } = props;

  // console.log("in SurveySection", phase);
  // console.log(responseSummary && responseSummary);

  // console.log("showUKQuestions: ", showUKQuestions);

  const loadingText = <div className='loading'>Loading data...</div>;

  return section && section.id && questions && resources ? (
    <GridContainer>
      <GridItem xs={12}>
        {reportingMode ? null : section && section.resourceId !== null ? (
          <div className={classes.typo}>
            <h5 className={classes.note}>
              Help Notes for the {section.text} Section
            </h5>
          </div>
        ) : null}
        {reportingMode
          ? null
          : section &&
            section.resourceId &&
            resources &&
            resources.length > 0 &&
            resources.find(resource => resource.id === section.resourceId)
          ? resources.find(resource => resource.id === section.resourceId).text
          : 'None'}
        {section &&
          section.id &&
          questions &&
          questions.length > 0 &&
          resources &&
          resources.length > 0 &&
          responses &&
          questions.map((question, idx) => (
            <SurveyQuestion
              key={question.id}
              sequence={idx + 1}
              phase={phase} // added for phase III
              section={section}
              question={question}
              metaData={metaData}
              resources={resources}
              response={getResponseForQuestion(responses, question.id)}
              responseSummary={responseSummary}
              reportingMode={reportingMode}
              showUKQuestions={showUKQuestions}
            />
          ))}
        <GridContainer>
          <GridItem xs={12}>
            <Card product={true}>
              <CardBody>
                {'End of '}
                <SurveySectionScores
                  phase={phase} // added for phase III
                  section={section}
                  questions={questions}
                  responses={responses}
                  responseSummary={responseSummary}
                  showScores={true}
                  alwaysShowPrefix={true}
                  reportingMode={reportingMode}
                  showUKQuestions={showUKQuestions}
                />
                <Fragment>
                  <br />
                  <br />
                  Key:
                  <YesIcon className={classes.yesIcon} /> You've answered Yes or
                  provided the info requested;{' '}
                  <NoIcon className={classes.noIcon} /> No;{' '}
                  <TBAIcon className={classes.tbaIcon} /> You're finding out;{' '}
                  <UnansweredIcon className={classes.unansweredIcon} /> Not
                  answered yet.
                  <br />
                </Fragment>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  ) : (
    loadingText
  );
};

SurveySection.propTypes = {
  classes: PropTypes.object,
  phase: PropTypes.string, // added for phase III
  section: PropTypes.object,
  questions: PropTypes.array,
  resources: PropTypes.array,
  responses: PropTypes.array,
  responseSummary: PropTypes.object
};

SurveySection.defaultProps = {
  classes: {},
  phase: '4', // added for phase III
  section: {},
  questions: [],
  resources: [],
  responses: []
};

export default withStyles(styles)(SurveySection);
