// import MyRetailers from "views/Retailers/MyRetailers";
// import ClaimRetailers from "views/Retailers/ClaimRetailers";
import RetailerProgressTablePaginated from 'views/Retailers/RetailerProgressTablePaginated';
import SurveyAccordian from 'views/Surveys/SurveyAccordian';
import RetailerResponseDetails from 'views/Reports/RetailerResponseDetails';
import UserTablePaginated from 'views/Users/UserTablePaginated';
import FactoryExcel from 'views/Reports/FactoryExcelPhaseThree';
import ChangePassword from 'views/Auth/ChangePassword';
// import ResponsePanelsNew from "views/Responses/ResponsePanelsNew";
// import ResponsePanelsLatest from "views/Responses/ResponsePanelsLatest";

// @material-ui/icons

import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import UsersIcon from '@material-ui/icons/SupervisorAccount';
// import AddCircleIcon from "@material-ui/icons/AddCircle";
// import HowToRegIcon from "@material-ui/icons/HowToReg";
import StoreIcon from '@material-ui/icons/Store';
// import SubjectIcon from "@material-ui/icons/Subject";
import TocIcon from '@material-ui/icons/Toc';
import TabIcon from '@material-ui/icons/Tab';
// import LockOpenIcon from "@material-ui/icons/LockOpen";
// import DashboardIcon from "@material-ui/icons/Dashboard";
// import ListAltIcon from "@material-ui/icons/ListAlt";
// import PersonIcon from "@material-ui/icons/Person";

var dashRoutes = [
  // {
  //   path: "/home",
  //   name: "Home",
  //   rtlName: "لوحة القيادة",
  //   icon: HomeIcon,
  //   component: MyRetailers,
  //   layout: "/admin"
  // },
  {
    path: '/myretailers/responses/:retailer/:phase',
    name: 'Retailer Report',
    rtlName: 'لوحة القيادة',
    icon: TocIcon,
    component: RetailerResponseDetails,
    layout: '/admin',
    linkNeeded: false
  },

  // {
  //   path: "/responsepanelslatest",
  //   name: "Survey Demo - Latest",
  //   rtlName: "لوحة القيادة",
  //   icon: TocIcon,
  //   component: ResponsePanelsLatest,
  //   layout: "/admin"
  // },
  // {
  //   path: "/responsepanelsnew",
  //   name: "Survey Demo",
  //   rtlName: "لوحة القيادة",
  //   icon: TocIcon,
  //   component: ResponsePanelsNew,
  //   layout: "/admin"
  // },
  // {
  //   path: "/resources",
  //   name: "Help Resources",
  //   rtlName: "لوحة القيادة",
  //   icon: SubjectIcon,
  //   component: ResourceList,
  //   layout: "/admin"
  // },

  {
    path: '/retailerprogress/responses/:retailer/:phase',
    name: 'Retailer Report',
    rtlName: 'لوحة القيادة',
    icon: TocIcon,
    component: RetailerResponseDetails,
    layout: '/admin',
    linkNeeded: false
  },
  {
    path: '/myretailers/survey/:retailer/:phase',
    name: 'Survey',
    rtlName: 'لوحة القيادة',
    icon: TocIcon,
    component: SurveyAccordian,
    layout: '/admin',
    linkNeeded: false
  },
  {
    path: '/retailerprogress/:phase',
    name: 'Retailers & Progress',
    rtlName: 'لوحة القيادة',
    icon: StoreIcon,
    component: RetailerProgressTablePaginated,
    layout: '/admin',
    linkNeeded: true
  },

  {
    path: '/userlist/:phase',
    name: 'Responders & Progress',
    rtlName: 'لوحة القيادة',
    icon: UsersIcon,
    component: UserTablePaginated,
    layout: '/admin',
    linkNeeded: true
  },
  //   {
  //     path: '/reports/excel',
  //     name: 'Excel for Factory',
  //     rtlName: 'لوحة القيادة',
  //     icon: TabIcon,
  //     component: FactoryExcel,
  //     layout: '/admin',
  //     linkNeeded: true
  //   },
  {
    path: '/changepassword',
    name: 'Change Password',
    rtlName: 'لوحة القيادة',
    icon: LockOpenIcon,
    component: ChangePassword,
    layout: '/admin',
    linkNeeded: true
  },
  {
    path: '/signin',
    name: 'Sign out',
    rtlName: 'لوحة القيادة',
    icon: LockIcon,
    component: null,
    layout: '/admin',
    linkNeeded: true,
    signout: true
  }
];
export default dashRoutes;
