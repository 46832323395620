import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import XLSX from 'xlsx';
import moment from 'moment';
import Button from 'components/CustomButtons/Button';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { Typography } from '@material-ui/core';
// import { isNull } from "util";
import excelTopHeadersTemplateFactory from 'views/Reports/excelTopHeadersTemplateFactoryPhaseThree';
import excelTopHeadersTemplateUK from 'views/Reports/excelTopHeadersTemplateUKPhaseThree';
import excelRowTemplateFactory from 'views/Reports/excelRowTemplateFactoryPhaseThree';
import excelRowTemplateUK from 'views/Reports/excelRowTemplateUKPhaseThree';
// import { tsConstructSignatureDeclaration } from "@babel/types";
/* xlsx.js (C) 2013-present  SheetJS -- http://sheetjs.com */
/* Notes:
   - usage: `ReactDOM.render( <SheetJSApp />, document.getElementById('app') );`
   - xlsx.full.min.js is loaded in the head of the HTML page
   - this script should be referenced with type="text/babel"
   - babel.js in-browser transpiler should be loaded before this script
*/
class FactoryExcel extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   data: [] /* Array of Arrays e.g. [["a","b"],[1,2]] */,
    //   cols: [] /* Array of column objects e.g. { name: "C", K: 2 } */

    // };
    // this.state = {
    //   cols: this.getHeaders(),
    //   data: this.getDataRows()
    // };
    this.getTopHeadings = this.getTopHeadings.bind(this);
    this.getFactoryColumnHeadings = this.getFactoryColumnHeadings.bind(this);
    this.getColumnHeadings = this.getColumnHeadings.bind(this);
    this.getDataRowTemplate = this.getDataRowTemplate.bind(this);
    this.adjustDataRowTemplateForRetailer = this.adjustDataRowTemplateForRetailer.bind(
      this
    );
    this.getDataRows = this.getDataRows.bind(this);
    this.getDataRow = this.getDataRow.bind(this);
    this.checkSupplyIsOverFifty = this.checkSupplyIsOverFifty.bind(this);
    this.processItem = this.processItem.bind(this);
    this.getResponseSummaryRecord = this.getResponseSummaryRecord.bind(this);
    this.getAllRows = this.getAllRows.bind(this);
    // this.handleFile = this.handleFile.bind(this);
    this.exportFile = this.exportFile.bind(this);
  }

  // handleFile(file /*:File*/) {
  //   /* Boilerplate to set up FileReader */
  //   const reader = new FileReader();
  //   const rABS = !!reader.readAsBinaryString;
  //   reader.onload = e => {
  //     /* Parse data */
  //     const bstr = e.target.result;
  //     const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
  //     /* Get first worksheet */
  //     const wsname = wb.SheetNames[0];
  //     const ws = wb.Sheets[wsname];
  //     /* Convert array of arrays */
  //     const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
  //     /* Update state */
  //     this.setState({ data: data, cols: make_cols(ws["!ref"]) });
  //   };
  //   if (rABS) reader.readAsBinaryString(file);
  //   else reader.readAsArrayBuffer(file);
  // }

  exportFile(data, fileName) {
    /* convert state to workbook */
    // console.log("in exportFile");
    // console.log(data);
    const dateToday = moment(new Date()).format('YYYY-MM-DD');
    const filename = `${fileName}-${dateToday}.xlsx`;
    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'SheetJS');
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, filename);
  }

  getResponseSummaryRecord(retailer, responseSummaries) {
    // console.log("in getResponseSummaryRecord");
    // console.log(responseSummaries);
    // console.log(retailer.id);
    if (responseSummaries && responseSummaries.length > 0) {
      // const testRow = responseSummaries.find(
      //   responseSummary => responseSummary.retailerId === "0SQzfwufl82mNP62pH2p"
      // );
      const row = responseSummaries.find(
        responseSummary => responseSummary.retailerId === retailer.id
      );
      if (row) {
        // console.log(row);
        return row;
      }
    }
  }

  processItem = item => {
    // console.log(item);

    return item;
  };

  adjustDataRowTemplateForRetailer = (
    dataRowTemplate,
    retailer,
    isSupplyOverFifty
  ) => {
    // console.log(retailer.retailerName);
    // console.log(
    //   "isSupplyOverFifty",
    //   isSupplyOverFifty,
    //   "for ",
    //   retailer.retailerName
    // );
    // console.log(dataRowTemplate[100]);
    const BCCSection = 'S6';
    const CISection = 'S4';
    // const infrastructureSection = "S2";
    const BCCTrainingQuestion = 'XXXX';
    const overFiftyDependsQuestion = 'S2UKQ1';
    const adjusted = dataRowTemplate.map(item =>
      item.substr(2, 2) === BCCSection ||
      item.substr(2, 4) === BCCTrainingQuestion
        ? // console.log("BCC Question", item, retailer.retailerName, retailer.bcc);
          retailer.bcc === true
          ? item
          : item.includes('note')
          ? 'spacer'
          : 'N/A'
        : item.substr(2, 2) === CISection
        ? retailer.full === true
          ? item
          : item.includes('note')
          ? 'spacer'
          : 'N/A'
        : item.substr(2, 6) === overFiftyDependsQuestion
        ? isSupplyOverFifty !== true
          ? item
          : item.includes('note')
          ? 'spacer'
          : 'N/A'
        : item
    );
    // console.log(adjusted);
    // if (retailer.bcc === true) console.log(adjusted);
    return adjusted;
  };

  checkSupplyIsOverFifty = responseSummaryRecord => {
    // const InfrastructureSection = "S3";
    // const Over50Question = "S3Q1-ynA";
    // console.log(responseSummaryRecord);

    if (
      responseSummaryRecord &&
      // responseSummaryRecord["S3Q1-ynA"] &&
      // responseSummaryRecord["S3Q1-ynA"] === "Yes"
      responseSummaryRecord['P3S2Q1-ynA'] &&
      responseSummaryRecord['P3S2Q1-ynA'] === 'Yes'
    ) {
      // console.log("overfifty:", responseSummaryRecord["P3S2Q1-ynA"]);
      return true;
    } else return false;
  };

  getDataRow(retailer, users, responseSummaries, dataRowTemplate) {
    // const responseSummaryRecord = this.getResponseSummaryRecord(
    //   "0SQzfwufl82mNP62pH2p",
    //   responseSummaries
    // );
    // console.log(dataRowTemplate);
    // console.log(retailer && users);

    const user =
      retailer && users && users.find(user => user.id === retailer.responderId);
    // console.log(user && user.firstName);
    // console.log(user && user.lastName);
    const responseSummaryRecord = this.getResponseSummaryRecord(
      retailer,
      responseSummaries
    );
    const isSupplyOverFifty = this.checkSupplyIsOverFifty(
      responseSummaryRecord
    );
    const dataRowTemplateAdjusted = this.adjustDataRowTemplateForRetailer(
      dataRowTemplate,
      retailer,
      isSupplyOverFifty
    );
    // if (!retailer.full) {
    //   console.log("no full");
    //   // console.log(dataRowTemplateAdjusted);
    // }

    // console.log("isSupplyOverFifty", retailer.retailerName, isSupplyOverFifty);
    // console.log(dataRowTemplateAdjusted);
    // console.log(responseSummaryRecord);

    // console.log(retailer.retailerName);

    if (responseSummaryRecord) {
      // console.log("responseSummaryRecord found");

      const dataRow = dataRowTemplateAdjusted.map(
        item =>
          item === 'spacer'
            ? ''
            : item === 'N/A'
            ? 'N/A'
            : item === 'brand'
            ? 'Volkswagen'
            : item === 'retailerNumber'
            ? retailer.retailerNumber && retailer.retailerNumber
            : item === 'retailerName'
            ? retailer.retailerName && retailer.retailerName
            : item === 'responderName'
            ? user && user.firstName + ' ' + user.lastName
            : item === 'responderEmail'
            ? user && user.email
            : item === 'lastUpdated'
            ? (responseSummaryRecord.time &&
                moment(responseSummaryRecord.time.toDate()).format(
                  'DD/MM/YYYY'
                )) ||
              ''
            : this.processItem(responseSummaryRecord[item]) // the retailer answer
      );
      // console.log(dataRow);
      return dataRow;
    } else {
      // console.log("responseSummaryRecord missing");
      // console.log(retailer.retailerName);
      const stubDataRow = dataRowTemplateAdjusted.map(item =>
        item === 'spacer'
          ? ''
          : item === 'N/A'
          ? 'N/A'
          : item === 'brand'
          ? 'Volkswagen'
          : item === 'retailerNumber'
          ? retailer.retailerNumber && retailer.retailerNumber
          : item === 'retailerName'
          ? retailer.retailerName && retailer.retailerName
          : item === 'responderName'
          ? (user && user.firstName + ' ' + user.lastName) || ''
          : item === 'responderEmail'
          ? (user && user.email) || ''
          : item === 'lastUpdated'
          ? ''
          : ''
      );
      // console.log(stubDataRow);
      return stubDataRow;
    }
  }

  getDataRowTemplate(excelRowTemplate) {
    const dataRowTemplate = excelRowTemplate.map(item =>
      item.method === 'spacer'
        ? (item.method = 'spacer')
        : item.method === 'ynA'
        ? `${item.source}-ynA`
        : item.method === 'txtA'
        ? `${item.source}-txtA`
        : item.method === 'comment'
        ? `${item.source}-note`
        : item.method === 'brand'
        ? item.method
        : item.method === 'retailerNumber'
        ? item.method
        : item.method === 'retailerName'
        ? item.method
        : item.method === 'responderName'
        ? 'responderName'
        : item.method === 'responderEmail'
        ? 'responderEmail'
        : item.method === 'lastUpdated'
        ? 'lastUpdated'
        : null
    );
    // console.log(dataRowTemplate);
    return dataRowTemplate;
  }

  getDataRows(retailers, users, responseSummaries, excelRowTemplate) {
    // console.log("in getDataRowsNew");
    // console.log("in getDataRows");
    // console.log(excelRowTemplate);
    // const responseDataRow = this.getResponseSummaryRecord(
    //   "0SQzfwufl82mNP62pH2p",
    //   responseSummaries
    // );
    const dataRowTemplate = this.getDataRowTemplate(excelRowTemplate);
    const dataRows = retailers.map(retailer =>
      retailer.sandbox
        ? null
        : this.getDataRow(retailer, users, responseSummaries, dataRowTemplate)
    );
    // console.log(dataRowTemplate);
    // console.log(dataRows);
    // console.log(responseDataRow);

    return dataRows;
  }

  getTopHeadings(excelTopHeadersTemplate) {
    // console.log("in getTopHeadings");
    const topHeadings = excelTopHeadersTemplate.map(item => item.source);
    return topHeadings;
  }

  getColumnHeadings(excelRowTemplate) {
    // console.log("in getColumnHeadings");
    // console.log(excelRowTemplate);
    const headingsRow = excelRowTemplate.map(item =>
      item.method === 'spacer'
        ? 'Spacer'
        : item.method === 'ynA'
        ? `${item.source} response`
        : item.method === 'txtA'
        ? `${item.source} response`
        : item.method === 'response'
        ? `${item.source}`
        : item.method === 'comment'
        ? `${item.source} ${item.method}`
        : item.method === 'brand'
        ? 'Brand'
        : item.method === 'retailerNumber'
        ? 'OrgNR'
        : item.method === 'retailerName'
        ? "dealership's name"
        : item.method === 'responderName'
        ? 'responder'
        : item.method === 'responderEmail'
        ? 'responder email'
        : item.method === 'lastUpdated'
        ? 'last updated'
        : null
    );
    // console.log(headingsRow);
    return headingsRow;
  }
  getFactoryColumnHeadings(questions, excelRowTemplate) {
    // console.log("in getFactoryColumnHeadings");
    // console.log(questions);
    const headingsRow = excelRowTemplate.map(item =>
      item.method === 'spacer'
        ? ''
        : item.method === 'response'
        ? questions.find(question => question.shortCode === item.source).text ||
          ''
        : item.method === 'ynA'
        ? questions.find(question => question.shortCode === item.source).text ||
          ''
        : item.method === 'txtA'
        ? questions.find(question => question.shortCode === item.source).text ||
          ''
        : item.method === 'comment'
        ? 'comment'
        : item.method === 'brand'
        ? 'Brand'
        : item.method === 'retailerNumber'
        ? 'OrgNR'
        : item.method === 'retailerName'
        ? "dealership's name"
        : item.method === 'responderName'
        ? 'contact person'
        : item.method === 'responderEmail'
        ? 'email contact person'
        : item.method === 'lastUpdated'
        ? 'last updated'
        : null
    );
    return headingsRow;
  }

  getAllRows(
    retailers,
    users,
    responseSummaries,
    questions,
    excelTopHeadersTemplate,
    excelRowTemplate
  ) {
    // console.log("in getAllRows");
    const columnHeadings = this.getColumnHeadings(excelRowTemplate);
    // console.log("in getAllRows");
    const factoryColumnHeadings = this.getFactoryColumnHeadings(
      questions,
      excelRowTemplate
    );
    // console.log("in getAllRows");
    const topHeadings = this.getTopHeadings(excelTopHeadersTemplate);
    const dataRows = this.getDataRows(
      retailers,
      users,
      responseSummaries,
      excelRowTemplate
    );
    const fullData = [
      topHeadings,
      factoryColumnHeadings,
      columnHeadings,
      ...dataRows
    ];
    // console.log(fullData);
    return fullData;
  }

  render() {
    const {
      // profile,
      retailers,
      users,
      questions,
      responseSummaries
    } = this.props;
    // console.log(this.props);
    // console.log(responseSummaries);

    if (
      retailers &&
      retailers.length > 0 &&
      users &&
      users.length > 0 &&
      questions &&
      questions.length > 0 &&
      responseSummaries &&
      responseSummaries.length > 0
    ) {
      // this.getDataRowsNew(retailers);
      // const columnHeadings = this.getColumnHeadings();
      const dataRowsFactory = this.getAllRows(
        retailers,
        users,
        responseSummaries,
        questions,
        excelTopHeadersTemplateFactory,
        excelRowTemplateFactory
      );
      const dataRowsUK = this.getAllRows(
        retailers,
        users,
        responseSummaries,
        questions,
        excelTopHeadersTemplateUK,
        excelRowTemplateUK
      );
      // console.log(dataRowsFactory);
      // console.log(dataRowsUK);

      return (
        <GridContainer justify='center'>
          <GridItem xs={12}>
            <Typography component='p'>
              Phase III reports will be available here from November 1st 2019.
            </Typography>
            <Typography component='p'>
              The buttons are disabled for now.
            </Typography>
          </GridItem>
          <br />
          <br />
          <GridItem xs={12}>
            <Button
              disabled={false}
              variant={'contained'}
              color={'primary'}
              className={'classes.button'}
              //     disabled={!data.length}
              onClick={() =>
                this.exportFile(
                  dataRowsFactory,
                  'ev-readiness-phase-iii-for-factory-uk-data'
                )
              }
            >
              Download Phase III Factory Excel file
            </Button>
          </GridItem>
          <br />
          <br />
          <GridItem xs={12}>
            <Button
              disabled={false}
              variant={'contained'}
              color={'primary'}
              className={'classes.button'}
              //     disabled={!data.length}
              onClick={() =>
                this.exportFile(
                  dataRowsUK,
                  'ev-readiness-phase-iii-with-uk-questions'
                )
              }
            >
              Download Phase III Factory Excel with UK Questions
            </Button>
          </GridItem>
        </GridContainer>
      );
    } else return 'Preparing the files...';
  }
}

const mapStateToProps = state => {
  // console.log(state);
  const allRetailers =
    state.firestore.ordered.retailers && state.firestore.ordered.retailers;
  // console.log(allRetailers && allRetailers.length);
  const filteredRetailers =
    allRetailers &&
    allRetailers.filter(
      retailer =>
        retailer.sandbox !== true &&
        retailer.surveyOn &&
        retailer.surveyOn === true
    );
  // console.log(filteredRetailers && filteredRetailers.length);
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    questions: state.firestore.ordered.questions,
    retailers: filteredRetailers,
    users: state.firestore.ordered.users,
    responseSummaries: state.firestore.ordered.responseSummaries
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props => [
    // { collection: "users", doc: props.auth.uid, storeAs: "activeUser" },
    // {
    //   collection: "retailers",
    //   // doc: "OnjB1u7Nqb3ZoKW5K3sq",
    //   where: ["id", "==", "OnjB1u7Nqb3ZoKW5K3sq"],
    //   // orderBy: ["retailerName", "asc"],
    //   limit: 1,
    //   storeAs: "retailerInPlay"
    // },
    // {
    //   collection: "retailers",
    //   where: [["responderId", "==", props.auth.uid]],
    //   limit: 20,
    //   storeAs: "myRetailers"
    // },
    // {
    //   collection: "retailers",
    //   where: [["responderId", "==", props.auth.uid]],
    //   limit: 20,
    //   storeAs: "myRetailers"
    // },
    // {
    //   collection: "responses",
    //   where: [
    //     // ["rtlrId", "==", "OnjB1u7Nqb3ZoKW5K3sq"]
    //     ["rtlrId", "==", props.match.params.retailer]
    //     // ["qId", "==", "60jNR4Raj1R0Ek458Qm9"]
    //     // ["qId", "==", props.question.data.id]
    //     // ["qId", "==", props.questionId]
    //   ],
    //   orderBy: ["time", "desc"],
    //   limit: 150
    // },
    {
      collection: 'retailers',
      limit: 300,
      orderBy: ['retailerName', 'asc'],
      storeAs: 'retailers'
    },
    {
      collection: 'questions',
      where: [['phase', '==', '3']],
      limit: 50,
      orderBy: ['displayWeight', 'asc']
    },
    // { collection: "resources", limit: 30 }
    {
      collection: 'responseSummaries',
      where: [['phase', '==', '3']],
      limit: 300
    },
    {
      collection: 'users',
      limit: 300
    }
    // { collection: "sections", limit: 12, orderBy: ["number", "asc"] },
    // {
    //   collection: "questions",
    //   limit: 50,
    //   orderBy: ["displayWeight", "asc"]
    // },
    // { collection: "resources", limit: 30 }
  ])
)(FactoryExcel);
