import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import LinearProgress from '@material-ui/core/LinearProgress';

const BorderLinearProgress = withStyles({
  root: {
    height: 15,
    backgroundColor: lighten('#ccc', 0.5)
  },
  bar: {
    borderRadius: 0,
    backgroundColor: '#51ae30'
  }
})(LinearProgress);

// const styles = {
//   root: {
//     flexGrow: 1
//   },
//   margin: {
//     // margin: theme.spacing(1)
//   }
// };

const getMaximumPoints = (questions, responseSummary) => {
  // const reserveShortCode = "S3Q1-ynA";
  const reserveShortCode = 'P4S2Q1-ynA'; //added for phase 3
  let maximumPoints = 0;
  responseSummary &&
    questions &&
    questions.forEach(question => {
      if (question.bccOnly) {
        if (responseSummary.bcc) {
          maximumPoints += 1;
        }
      } else if (question.fullOnly) {
        if (responseSummary.full) {
          maximumPoints += 1;
        }
      } else if (
        question.parentQuestionId &&
        (question.parentQuestionId === 'DAs19JufElKcxXOh2EgC' ||
          question.parentQuestionId === 'dLkI12864rPo1LElEMpo')
      ) {
        if (
          !responseSummary[reserveShortCode] ||
          responseSummary[reserveShortCode] !== 'Yes'
        ) {
          maximumPoints += 1;
        }
      } else {
        maximumPoints += 1;
      }
    });
  //   console.log('maximum points', maximumPoints);
  return maximumPoints;
};

const getScoreForQuestion = (question, responseSummary) => {
  // const filteredResponses =
  //   responses &&
  //   responses.length > 0 &&
  //   responses.filter(response => response.qId === questionId);

  const responseYnA = question && `${question.shortCode}-ynA`;
  const responseTxtA = question && `${question.shortCode}-txtA`;
  //   const wallboxAnswer = 'P3S4UKQ2-ynA'; //added for phase 3
  //   const wallboxQuestion = 'P4S4Q3'; //added for phase 4
  //   const wallboxAnswer = 'P4S4Q3-ynA'; //added for phase 4
  const overFiftyAnswer = 'P4S2Q1-ynA'; //added for phase 4
  const underFiftyQuestion = 'P4S2Q2'; //added for phase 4
  const underFiftyAnswer = 'P4S2Q2-txtA'; //added for phase 4
  // const responseDtA = question && `${question.shortCode}-dtA`;

  if (responseSummary) {
    // console.log(response.ynA);
    // console.log('question is ' + question.shortCode);
    if (question.shortCode === underFiftyQuestion) {
      if (responseSummary[overFiftyAnswer] === 'Yes') {
        // console.log(
        //   'question is ' +
        //     question.shortCode +
        //     ' overFiftyAnswer answer is ' +
        //     responseSummary[overFiftyAnswer] +
        //     ' but underFiftyAnswer is ' +
        //     responseSummary[underFiftyAnswer] +
        //     'so 0'
        // );
        return 0;
      } else {
        if (
          responseSummary[underFiftyAnswer] &&
          responseSummary[underFiftyAnswer] !== ''
        ) {
          //   console.log(
          //     'question is ' +
          //       question.shortCode +
          //       ' overFiftyAnswer answer is ' +
          //       responseSummary[overFiftyAnswer] +
          //       ' and underFiftyAnswer is ' +
          //       responseSummary[underFiftyAnswer] +
          //       'so 1'
          //   );
          return 1;
        } else {
          //   console.log(
          //     'question is ' +
          //       question.shortCode +
          //       ' overFiftyAnswer answer is ' +
          //       responseSummary[overFiftyAnswer] +
          //       ' and underFiftyAnswer is ' +
          //       responseSummary[underFiftyAnswer] +
          //       'so 0'
          //   );
          return 0;
        }
      }
    } else if (question.twoAnswersIfYes) {
      //   console.log(question, responseSummary);
      if (
        responseSummary[responseYnA] &&
        responseSummary[responseYnA] === 'No'
      ) {
        // console.log(question.shortCode, 1);
        return 1;
      } else if (
        responseSummary[responseYnA] &&
        responseSummary[responseYnA] === 'Yes' &&
        responseSummary[responseTxtA] &&
        responseSummary[responseTxtA] !== ''
      ) {
        // console.log(question.shortCode, 1);
        return 1;
      } else {
        // console.log(question.shortCode, 0);
        return 0;
      }
    } else if (question.twoAnswersIfNo) {
      // console.log(question, responseSummary);
      if (
        responseSummary[responseYnA] &&
        responseSummary[responseYnA] === 'Yes'
      ) {
        return 1;
      } else if (
        responseSummary[responseYnA] &&
        responseSummary[responseYnA] === 'No' &&
        responseSummary[responseTxtA] &&
        responseSummary[responseTxtA] !== ''
      ) {
        return 1;
      } else {
        return 0;
      }
    } else if (
      responseSummary[responseYnA] &&
      responseSummary[responseYnA] === 'Yes'
    ) {
      return 1;
    } else if (
      !question.yesNoQuestion &&
      question.textQuestion &&
      responseSummary[responseTxtA] &&
      responseSummary[responseTxtA] !== ''
    ) {
      // console.log(question, responseSummary);
      return 1;
    } else {
      if (
        responseSummary[responseYnA] &&
        responseSummary[responseYnA] === 'No'
      ) {
        return 1; // added for no answers taken as a completed answer
      }
      return 0;
    }
  } else {
    return 0;
  }
};

const getPoints = (questions, responseSummary) => {
  let points = 0;
  questions.forEach(question => {
    // console.log(getScoreForQuestion(question, responseSummary));
    points += getScoreForQuestion(question, responseSummary);
    // console.log(
    //   question.shortCode,
    //   getScoreForQuestion(question, responseSummary)
    // );
  });
  //   console.log('total points: ' + points);
  return points;
};

const PercentageAnswered = ({ ...props }) => {
  const { questions, responseSummary } = props;
  // const classes = useStyles();
  const dataIsLoaded =
    questions && questions.length > 0 && responseSummary ? true : false;

  if (dataIsLoaded) {
    const maximumPoints = getMaximumPoints(questions, responseSummary);
    const points = getPoints(questions, responseSummary);
    const percentageComplete = Math.round((100 * points) / maximumPoints);
    return (
      <Fragment>
        <BorderLinearProgress
          variant='determinate'
          value={percentageComplete}
        />
        Phase IV: {percentageComplete}% of questions answered
      </Fragment>
    );
  } else {
    return null;
  }
};

PercentageAnswered.propTypes = {
  classes: PropTypes.object,
  phase: PropTypes.string, // added for phase III
  questions: PropTypes.array,
  responseSummary: PropTypes.object
};

export default PercentageAnswered;
