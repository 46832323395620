const excelRowTemplate = [
  { column: 'A', method: 'spacer', source: '' },
  { column: 'B', method: 'spacer', source: '' },
  { column: 'C', method: 'spacer', source: '' },
  { column: 'D', method: 'brand', source: 'Volkswagen' },
  { column: 'E', method: 'retailerNumber', source: 'retailerNumber' },
  { column: 'F', method: 'retailerName', source: 'retailerName' },
  { column: 'G', method: 'spacer', source: '' },
  { column: 'H', method: 'spacer', source: '' },
  { column: 'I', method: 'spacer', source: '' },
  { column: 'J', method: 'responderName', source: 'responderName' },
  { column: 'K', method: 'responderEmail', source: 'responderEmail' },
  { column: 'L', method: 'lastUpdated', source: 'lastUpdated' },
  { column: 'M', method: 'spacer', source: '' },
  { column: 'N', method: 'spacer', source: '' },

  { column: 'O', method: 'ynA', source: 'P4S1Q1' },
  { column: 'P', method: 'txtA', source: 'P4S1Q1' },
  { column: 'Q', method: 'comment', source: 'P4S1Q1' },
  { column: 'R', method: 'spacer', source: '' },

  { column: 'S', method: 'ynA', source: 'P4S2Q1' },
  { column: 'T', method: 'comment', source: 'P4S2Q1' },
  { column: 'U', method: 'txtA', source: 'P4S2Q2' },
  { column: 'V', method: 'comment', source: 'P4S2Q2' },
  { column: 'W', method: 'ynA', source: 'P4S2Q3' },
  { column: 'X', method: 'comment', source: 'P4S2Q3' },
  { column: 'Y', method: 'txtA', source: 'P4S2Q4' },
  { column: 'Z', method: 'comment', source: 'P4S2Q4' },
  { column: 'AA', method: 'ynA', source: 'P4S2Q5' },
  { column: 'AB', method: 'comment', source: 'P4S2Q5' },
  { column: 'AC', method: 'spacer', source: '' },

  { column: 'AD', method: 'ynA', source: 'P4S3Q1' },
  { column: 'AE', method: 'comment', source: 'P4S3Q1' },
  { column: 'AF', method: 'txtA', source: 'P4S3Q2' },
  { column: 'AG', method: 'comment', source: 'P4S3Q2' },
  { column: 'AH', method: 'ynA', source: 'P4S3Q3' },
  { column: 'AI', method: 'comment', source: 'P4S3Q3' },
  { column: 'AJ', method: 'ynA', source: 'P4S3Q4' },
  { column: 'AK', method: 'comment', source: 'P4S3Q4' },
  { column: 'AL', method: 'ynA', source: 'P4S3Q5' },
  { column: 'AM,', method: 'comment', source: 'P4S3Q5' },
  { column: 'AN', method: 'ynA', source: 'P4S3Q6' },
  { column: 'AO', method: 'comment', source: 'P4S3Q6' },
  { column: 'AP', method: 'ynA', source: 'P4S3Q7' },
  { column: 'AQ', method: 'comment', source: 'P4S3Q7' },
  { column: 'AR', method: 'ynA', source: 'P4S3Q8' },
  { column: 'AS', method: 'comment', source: 'P4S3Q8' },
  { column: 'AT', method: 'ynA', source: 'P4S3Q9' },
  { column: 'AU', method: 'comment', source: 'P4S3Q9' },
  { column: 'AV', method: 'ynA', source: 'P4S3Q10' },
  { column: 'AW', method: 'comment', source: 'P4S3Q10' },
  { column: 'AX', method: 'spacer', source: '' },

  { column: 'AY', method: 'ynA', source: 'P4S4Q1' },
  { column: 'AZ', method: 'txtA', source: 'P4S4Q1' },
  { column: 'BA', method: 'comment', source: 'P4S4Q1' },
  { column: 'BB', method: 'ynA', source: 'P4S4Q2' },
  { column: 'BC', method: 'comment', source: 'P4S4Q2' },
  { column: 'BD', method: 'ynA', source: 'P4S4Q3' },
  { column: 'BE', method: 'comment', source: 'P4S4Q3' },
  { column: 'BF', method: 'spacer', source: '' },

  { column: 'BG', method: 'ynA', source: 'P4S5Q1' },
  { column: 'BH', method: 'comment', source: 'P4S5Q1' },
  { column: 'BI', method: 'ynA', source: 'P4S5Q2' },
  { column: 'BJ', method: 'comment', source: 'P4S5Q2' },
  { column: 'BK', method: 'ynA', source: 'P4S5Q3' },
  { column: 'BL', method: 'comment', source: 'P4S5Q3' },
  { column: 'BM', method: 'ynA', source: 'P4S5Q4' },
  { column: 'BN', method: 'comment', source: 'P4S5Q4' },
  { column: 'BO', method: 'ynA', source: 'P4S5Q5' },
  { column: 'BP', method: 'comment', source: 'P4S5Q5' },
  { column: 'BQ', method: 'ynA', source: 'P4S5Q6' },
  { column: 'BR', method: 'comment', source: 'P4S5Q6' },
  { column: 'BS', method: 'ynA', source: 'P4S5Q7' },
  { column: 'BT', method: 'comment', source: 'P4S5Q7' },
  { column: 'BU', method: 'ynA', source: 'P4S5Q8' },
  { column: 'BV', method: 'comment', source: 'P4S5Q8' },
  { column: 'BW', method: 'spacer', source: '' },

  { column: 'BX', method: 'ynA', source: 'P4S6Q1' },
  { column: 'BY', method: 'comment', source: 'P4S6Q1' },
  { column: 'BZ', method: 'ynA', source: 'P4S6Q2' },
  { column: 'CA', method: 'comment', source: 'P4S6Q2' },
  { column: 'CB', method: 'ynA', source: 'P4S6Q3' },
  { column: 'CC', method: 'comment', source: 'P4S6Q3' },
  { column: 'CD', method: 'ynA', source: 'P4S6Q4' },
  { column: 'CE', method: 'comment', source: 'P4S6Q4' },
  { column: 'CF', method: 'ynA', source: 'P4S6Q5' },
  { column: 'CG', method: 'comment', source: 'P4S6Q5' },
  { column: 'CH', method: 'ynA', source: 'P4S6Q6' },
  { column: 'CI', method: 'comment', source: 'P4S6Q6' },
  { column: 'CJ', method: 'ynA', source: 'P4S6Q7' },
  { column: 'CK', method: 'comment', source: 'P4S6Q7' },
  { column: 'CL', method: 'ynA', source: 'P4S6Q8' },
  { column: 'CM', method: 'comment', source: 'P4S6Q8' },
  { column: 'CN', method: 'spacer', source: '' },

  { column: 'CO', method: 'ynA', source: 'P4S7Q1' },
  { column: 'CP', method: 'comment', source: 'P4S7Q1' },
  { column: 'CQ', method: 'ynA', source: 'P4S7Q2' },
  { column: 'CR', method: 'comment', source: 'P4S7Q2' },
  { column: 'CS', method: 'spacer', source: '' },

  { column: 'CT', method: 'ynA', source: 'P4S8Q1' },
  { column: 'CU', method: 'comment', source: 'P4S8Q1' },
  { column: 'CV', method: 'ynA', source: 'P4S8Q2' },
  { column: 'CW', method: 'comment', source: 'P4S8Q2' },
  { column: 'CX', method: 'ynA', source: 'P4S8Q3' },
  { column: 'CY', method: 'txtA', source: 'P4S8Q3' },
  { column: 'CZ', method: 'comment', source: 'P4S8Q3' },
  { column: 'CQ', method: 'ynA', source: 'P4S8Q4' },
  { column: 'DA', method: 'comment', source: 'P4S8Q4' },
  { column: 'DB', method: 'ynA', source: 'P4S8Q5' },
  { column: 'DC', method: 'comment', source: 'P4S8Q5' },
  { column: 'DD', method: 'spacer', source: '' }
];
export default excelRowTemplate;
