import React, { Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import PropTypes from 'prop-types';
// react component for creating dynamic tables
import ReactTable from 'react-table';
import moment from 'moment';
// Views and components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import PercentageAnswered from 'views/Surveys/PercentageAnswered';
import PercentageReady from 'views/Surveys/PercentageReady';
// import Button from "components/CustomButtons/Button";
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import RegularButton from 'components/CustomButtons/Button';
// Styling
import withStyles from '@material-ui/core/styles/withStyles';
import { BeatLoader } from 'react-spinners';
import { cardTitle } from 'assets/jss/material-dashboard-pro-react';

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  }
};
// const phase2 = "2";
// const phase3 = '3';
const phase4 = '4';
const tableColumns = [
  {
    Header: 'Nr',
    accessor: 'retailerNumber',
    width: 80
  },
  {
    Header: 'Retailer',
    accessor: 'retailerInfo'
  },
  {
    Header: 'Survey Updated',
    accessor: 'surveyStatus'
  },
  {
    Header: '',
    accessor: 'actions',
    sortable: false
  }
];

const getUserNameArray = users => {
  return users
    ? users.map(user => {
        return {
          id: user.id,
          displayName: user.firstName + ' ' + user.lastName
        };
      })
    : [];
};

const getTableData = (
  profile,
  retailers,
  users,
  responseSummaries,
  questions
) => {
  // console.log("users", users && users.length);
  // console.log("retailers", retailers && retailers.length);

  // console.log(
  //   "responseSummaries: ",
  //   responseSummaries && responseSummaries.length
  // );
  // const retailers =
  //   allRetailers &
  //   allRetailers.filter(retailer => !retailer.hasOwnProperty("sandbox") );

  const userNameArray = getUserNameArray(users);
  // console.log(questions && questions);

  const tableData = retailers.map(retailer => {
    // console.log(retailer);
    const isClaimed = retailer.responderId ? retailer.responderId : null;
    const responseSummary = responseSummaries.find(
      // responseSummary => responseSummary.retailerId === retailer.id
      responseSummary => responseSummary.retailerId === retailer.id // added for phase III
    );

    const dateUpdated =
      (responseSummary &&
        responseSummary.time &&
        moment(responseSummary.time.toDate()).format('DD/MM/YYYY HH:MM a')) ||
      null;
    // (responseSummary && responseSummary.time && moment()) || null;

    const getRetailerInfo = retailer => {
      const responder =
        isClaimed &&
        userNameArray.find(user => user.id === retailer.responderId);

      return responder
        ? `${retailer.retailerName} - ${responder.displayName}` || ''
        : retailer.retailerName;
    };
    return {
      id: retailer.id,
      retailerNumber: retailer.retailerNumber,
      retailerInfo: getRetailerInfo(retailer),
      surveyStatus: isClaimed
        ? dateUpdated
          ? dateUpdated
          : 'Not started'
        : '-',
      actions: (
        <Fragment>
          {isClaimed ? (
            profile.manager ? (
              dateUpdated ? (
                <Fragment>
                  <PercentageAnswered
                    responseSummary={getResponseSummaryForRetailer(
                      responseSummaries,
                      retailer.id
                    )}
                    questions={questions}
                  />
                  <PercentageReady
                    responseSummary={getResponseSummaryForRetailer(
                      responseSummaries,
                      retailer.id
                    )}
                    questions={questions}
                  />
                  <br />
                  <RegularButton
                    component={Link}
                    size='sm'
                    variant='contained'
                    color='primary'
                    to={`/admin/retailerprogress/responses/${retailer.id}/${phase4}`}
                    className={'classes.button'}
                  >
                    View
                  </RegularButton>
                </Fragment>
              ) : (
                '-'
              )
            ) : (
              '-'
            )
          ) : (
            '-'
          )}
        </Fragment>
      )
    };
  });
  return tableData;
  // console.log("tableData", tableData);
};

const getClaimedRetailerArr = retailers => {
  const claimedRetailerArr = retailers
    ? retailers.filter(retailer => retailer.responderId !== null)
    : [];
  return claimedRetailerArr;
};
const getClaimedRetailerCount = retailers => {
  const claimedRetailerArr = getClaimedRetailerArr(retailers);

  const claimedRetailerCount = claimedRetailerArr
    ? claimedRetailerArr.length
    : 0;
  return claimedRetailerCount;
};
const getStartedSurveyCount = responseSummaries => {
  const startedSurveyArr =
    responseSummaries &&
    responseSummaries.filter(responseSummary =>
      responseSummary.hasOwnProperty('time')
    );
  const startedSurveyCount = startedSurveyArr ? startedSurveyArr.length : 0;
  return startedSurveyCount;
};
const getUnclaimedRetailerCount = retailers => {
  return retailers && retailers.length > 0
    ? retailers.length - getClaimedRetailerCount(retailers)
    : 0;
};

const getResponseSummaryForRetailer = (responseSummaries, retailerId) => {
  // console.log(responseSummaries);
  // console.log(retailerId);
  return (
    (responseSummaries &&
      responseSummaries.find(
        // responseSummary => responseSummary.retailerId === retailerId
        responseSummary => responseSummary.retailerId === retailerId // added for phase III
      )) ||
    {}
  );
};
// let tableDataReady = false;

const RetailerProgressTablePaginated = ({ ...props }) => {
  const {
    auth,
    profile,
    classes,
    retailers,
    users,
    responseSummaries,
    questions
  } = props;
  // console.log(responseSummaries);
  // console.log(retailers);
  // console.log(profile);
  // console.log(users);
  // console.log(props);
  // console.log("responseSummaries isLoaded", isLoaded(responseSummaries));
  // console.log("responseSummaries isEmpty", isEmpty(responseSummaries));
  // console.log("users isLoaded", isLoaded(users));
  // console.log("users isEmpty", isEmpty(users));
  // console.log("retailers isLoaded", isLoaded(retailers));
  // console.log("retailers isEmpty", isEmpty(retailers));
  if (!auth.uid) return <Redirect to='/admin/signin' />;

  const dataIsLoaded =
    isLoaded(responseSummaries) &&
    !isEmpty(responseSummaries) &&
    isLoaded(users) &&
    !isEmpty(users) &&
    isLoaded(retailers) &&
    !isEmpty(retailers) &&
    isLoaded(questions) &&
    !isEmpty(questions) &&
    users &&
    users.length > 0 &&
    questions &&
    questions.length > 0 &&
    retailers &&
    retailers.length > 0 &&
    responseSummaries &&
    responseSummaries.length > 1 // needs to be >1 not >0 for coming from detail view
      ? true
      : false;

  // const tableData = dataIsLoaded
  //   ? getTableData(profile, retailers, users, responseSummaries, questions)
  //   : [];

  const tableData =
    dataIsLoaded &&
    getTableData(profile, retailers, users, responseSummaries, questions);

  // console.log(tableData);

  return dataIsLoaded && tableData && tableData.length > 0 ? (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color='primary' icon>
            <h4 className={classes.cardIconTitle}>
              Retailer Site Survey Progress
            </h4>
          </CardHeader>
          <CardBody>
            <div>
              <h6>
                {getClaimedRetailerCount(retailers)} sites have been claimed and{' '}
                {getStartedSurveyCount(responseSummaries)} survey checklists
                have been started.
              </h6>
              <p>
                {getUnclaimedRetailerCount(retailers)} sites are waiting to be
                claimed. You can sort this table by the "Survey Updated" column
                to group them together.
              </p>
              <ReactTable
                data={tableData}
                filterable={false}
                columns={tableColumns}
                defaultPageSize={50}
                showPaginationTop
                showPaginationBottom={false}
                className='-striped -highlight'
                sortable={true}
                defaultSorted={[
                  {
                    id: 'retailerNumber',
                    desc: false
                  }
                ]}
              />
            </div>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  ) : (
    <GridContainer>
      <GridItem xs={12}>
        <BeatLoader sizeUnit={'px'} size={30} color={'gray'} />
        It may take a while to do all the progress calculations.
      </GridItem>
    </GridContainer>
  );
};

RetailerProgressTablePaginated.propTypes = {
  auth: PropTypes.object,
  profile: PropTypes.object,
  classes: PropTypes.object,
  retailers: PropTypes.array,
  questions: PropTypes.array,
  responseSummaries: PropTypes.array,
  users: PropTypes.array
};
RetailerProgressTablePaginated.defaultProps = {
  auth: {},
  profile: {}
};

const mapStateToProps = state => {
  // console.log(state);
  // console.log(state.firestore.ordered.retailers);
  // const allUsers = state.firestore.ordered.users;
  // console.log(allUsers && allUsers.length);
  // const filteredUsers =
  //   allUsers && allUsers.filter(user => !user.manager
  // console.log(filteredUsers && filteredUsers.length);

  // const role = state.firebase.profile.role && state.firebase.profile.role;
  // if ( role &&role === "manager") {
  // }
  // console.log(allRetailers && allRetailers.length);
  const allRetailers =
    state.firestore.ordered.retailers && state.firestore.ordered.retailers;
  //   console.log(allRetailers && allRetailers.length);
  const filteredRetailers =
    allRetailers &&
    allRetailers.filter(
      retailer =>
        retailer.sandbox !== true &&
        retailer.surveyOn &&
        retailer.surveyOn === true
    );
  //   console.log(filteredRetailers && filteredRetailers.length);
  const allResponseSummaries =
    state.firestore.ordered.responseSummaries &&
    state.firestore.ordered.responseSummaries;
  // console.log(allRetailers && allRetailers.length);
  // console.log(allResponseSummaries && allResponseSummaries.length);
  // const nonSandboxResponseSummaries =
  //   allResponseSummaries &&
  //   allResponseSummaries.filter(
  //     responseSummary => !responseSummary.hasOwnProperty("sandbox")
  //   );
  const filteredResponseSummaries =
    allResponseSummaries &&
    allResponseSummaries.filter(
      responseSummary => !responseSummary.hasOwnProperty('sandbox')
    );

  // console.log(filteredResponseSummaries && filteredResponseSummaries.length);
  // console.log(filteredResponseSummaries && filteredResponseSummaries);
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    retailers: filteredRetailers,
    users: state.firestore.ordered.users,
    questions: state.firestore.ordered.questions,
    responseSummaries: filteredResponseSummaries
  };
};

export default withStyles(styles)(
  compose(
    connect(mapStateToProps),
    firestoreConnect(props => [
      { collection: 'users', limit: 300 }, // can't use userBy as it causes a mystery hang
      { collection: 'retailers', limit: 250 },
      {
        collection: 'responseSummaries',
        where: [['phase', '==', '4']],
        limit: 500
      },
      {
        collection: 'questions',
        where: [['phase', '==', '4']],
        limit: 100
      }
    ])
  )(RetailerProgressTablePaginated)
);
