import React, { Component } from "react";
// import { Link } from "react-router-dom";
import { withFirebase } from "react-redux-firebase";

import { TextField } from "@material-ui/core";
import RegularButton from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";

const ResetPassword = props => {
  // console.log(props);

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardBody>
            <div>
              Not registered yet?&nbsp;
              <a href="/admin/signup">Sign up</a>&nbsp;instead.
            </div>

            <h5>Request a Password Reset Email</h5>
            <PasswordResetForm />

            <br />
          </CardBody>{" "}
        </Card>
      </GridItem>
    </GridContainer>
  );
};

const INITIAL_STATE = {
  email: "",
  error: null,
  success: null
};

class PasswordResetFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = e => {
    const { email } = this.state;

    e.preventDefault();
    // console.log(this.props.resetPassword);
    // console.log(this.state);
    // console.log(email);

    this.props.firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        // console.log("password reset success");
        this.setState({ ...INITIAL_STATE, success: true });
      })
      .catch(error => {
        // console.log("password reset error");
        this.setState({ error });
      });

    e.preventDefault();
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error, success } = this.state;
    // console.log(this.props.firebase.Auth);

    const isInvalid = email === "";

    return (
      <form onSubmit={this.onSubmit}>
        <TextField
          id="email"
          name="email"
          label="Email Address"
          //     className={classes.textField}
          placeholder="Type in the email you signed up with."
          type="text"
          fullWidth
          autoComplete="current-password"
          required={true}
          value={this.state.email}
          onChange={this.handleChange}
        />
        <br />
        <br />
        <RegularButton
          label="Sign in"
          variant="contained"
          color="primary"
          //    className={classes.button}
          onClick={this.handleSubmit}
          autoFocus={true}
          disabled={isInvalid}
          type="submit"
        >
          Reset My Password
        </RegularButton>

        {error && (
          <p>
            There's a problem reseting your password. Are you using the email
            address you signed up with? Did you actually&nbsp;
            <a href="/admin/signup">sign up</a>?
          </p>
        )}

        {success && (
          <p>
            Please check your email. You should receive an email with a link to
            reset your password.
          </p>
        )}
      </form>
    );
  }
}

const PasswordResetForm = withFirebase(PasswordResetFormBase);

export default ResetPassword;
