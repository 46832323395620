import React, { Component } from "react";
// import MuiThemeProvider from "@material-ui/styles/MuiThemeProvider";
import { withStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import RegularButton from "components/CustomButtons/Button";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { signUp } from "../../_store/actions/authActions";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import Danger from "components/Typography/Danger";
import CardBody from "components/Card/CardBody";

const styles = theme => ({});

class SignUp extends Component {
  state = {
    email: "",
    password: "",
    firstName: "",
    lastName: ""
  };
  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };
  handleSubmit = e => {
    e.preventDefault();
    // console.log(this.state);
    this.props.signUp(this.state);
  };
  render() {
    const { auth, authError, classes } = this.props;
    if (auth.uid) return <Redirect to="/admin/myretailers" />;

    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody>
              <form onSubmit={this.handleSubmit}>
                <div>
                  Already registered?&nbsp;Then please&nbsp;
                  <a href="/survey/signin">sign in</a>.
                </div>
                <div>
                  Problem? Please contact&nbsp;
                  <a href="mailto:evreadiness@helpfulconsultants.com?subject=Survey">
                    evreadiness@helpfulconsultants.com
                  </a>
                  .
                </div>
                <h5 className="grey-text text-darken-3">Register</h5>
                <TextField
                  id="email"
                  type="email"
                  label="Email"
                  className={classes.textField}
                  placeholder="Use your corporate email"
                  helperText="Use your corporate email address, not google, yahoo etc"
                  fullWidth
                  required={true}
                  onChange={this.handleChange}
                />
                <br />
                <br />
                <TextField
                  id="password"
                  label="Password"
                  className={classes.textField}
                  type="password"
                  fullWidth
                  required={true}
                  onChange={this.handleChange}
                />
                <br />
                <br />
                <TextField
                  id="firstName"
                  label="First Name"
                  className={classes.textField}
                  type="text"
                  fullWidth
                  required={true}
                  onChange={this.handleChange}
                />
                <br />
                <br />
                <TextField
                  id="lastName"
                  label="Last Name"
                  className={classes.textField}
                  type="text"
                  fullWidth
                  required={true}
                  onChange={this.handleChange}
                />
                <br />
                <br />
                <br />
                <Danger>{authError ? <p>{authError}</p> : null}</Danger>
                <RegularButton
                  label="Sign up"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  type="submit"
                  autoFocus={true}
                >
                  Register
                </RegularButton>
                <br /> <br />
                <div>
                  You only need to sign up once, even if you look after more
                  than one site.
                </div>
              </form>
              <br />
            </CardBody>
          </Card>
        </GridItem>{" "}
      </GridContainer>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError
  };
};
const mapDispatchToProps = dispatch => {
  return {
    signUp: newUser => dispatch(signUp(newUser))
  };
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SignUp)
);
