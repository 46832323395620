import { combineReducers } from "redux";
import { firestoreReducer } from "redux-firestore";
import { firebaseReducer } from "react-redux-firebase";
import authReducer from "./authReducer";
import retailerReducer from "./retailerReducer";
// import activeUserReducer from "./activeUserReducer";
import activeRetailerReducer from "./activeRetailerReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  retailer: retailerReducer,
  // activeUser: activeUserReducer,
  activeRetailer: activeRetailerReducer,
  firestore: firestoreReducer,
  firebase: firebaseReducer
});

export default rootReducer;
