const actionConstants = {
  SIGNIN_SUCCESS: "SIGNIN_SUCCESS",
  SIGNIN_ERROR: "SIGNIN_ERROR",
  SIGNIN_SETUP_ERROR: "SIGNIN_SETUP_ERROR",
  SIGNOUT_SUCCESS: "SIGNOUT_SUCCESS",
  SIGNOUT_ERROR: "SIGNOUT_ERROR",
  SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
  SIGNUP_ERROR: "SIGNUP_ERROR",
  PASSWORD_RESET_SUCCESS: "PASSWORD_RESET_SUCCESS",
  PASSWORD_RESET_ERROR: "PASSWORD_RESET_ERROR",
  PASSWORD_CHANGE_SUCCESS: "PASSWORD_CHANGE_SUCCESS",
  PASSWORD_CHANGE_ERROR: "PASSWORD_CHANGE_ERROR",
  USER_UPDATE_SUCCESS: "USER_UPDATE_SUCCESS",
  USER_UPDATE_ERROR: "USER_UPDATE_ERROR",
  CREATE_RETAILER_SUCCESS: "CREATE_RETAILER_SUCCESS",
  CREATE_RETAILER_ERROR: "CREATE_RETAILER_ERROR",
  CREATE_RESPONSE_SUCCESS: "CREATE_RESPONSE_SUCCESS",
  CREATE_RESPONSE_ERROR: "CREATE_RESPONSE_ERROR",
  CLAIM_RETAILER_SUCCESS: "CLAIM_RETAILER_SUCCESS",
  CLAIM_RETAILER_ERROR: "CLAIM_RETAILER_ERROR",
  UNCLAIM_RETAILER_SUCCESS: "UNCLAIM_RETAILER_SUCCESS",
  UNCLAIM_RETAILER_ERROR: "UNCLAIM_RETAILER_ERROR",
  SET_ACTIVE_RETAILER_SUCCESS: "SET_ACTIVE_RETAILER_SUCCESS",
  SET_ACTIVE_RETAILER_ERROR: "SET_ACTIVE_RETAILER_ERROR",
  GET_USER_SUCCESS: "GET_USER_SUCCESS",
  GET_USER_ERROR: "GET_USER_ERROR"
};

export default actionConstants;
