import React from 'react';
import firebaseConfigInfo from '_config/firebaseConfigInfo';
// import appInfo from "_config/appInfo.js";

function AppInfo() {
  // const info = appInfo;
  // console.log(info);
  const context =
    firebaseConfigInfo.projectId === 'vwguk-evreadiness' ? 'Live' : 'Test';
  // const version = info.version;
  const appInfo = {
    context: context,
    version: '02.01.02 (Phase IV, Summary fix, training records. logs)'
  };
  //   console.log("appInfo", appInfo);
  return (
    <div>
      {' '}
      Version: {appInfo.version} {' ('}
      {appInfo.context}
      {')'}
      <br /> <br />
      Volkswagen UK
    </div>
  );
}

export default AppInfo;
