import actionConstants from "../constants/actionConstants";
// import { getMaxListeners } from "cluster";

export const signIn = credentials => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // const firestore = getFirestore();
    const firebase = getFirebase();
    // console.log(firebase);
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);

    firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then(
        dispatch({
          type: actionConstants.SIGNIN_SUCCESS
        })
      )
      .catch(err => {
        dispatch({ type: actionConstants.SIGNIN_ERROR, err });
        // this.props.history.push("/admin/signin");
      });
    // .then(resp => {
    //   console.log(resp.user.uid);
    //   const docRef = firestore.collection("users").doc(resp.user.uid);
    //   docRef
    //     .get()
    //     .then(doc => {
    //       if (doc.exists) {
    //         console.log("Document data:", doc.data());
    //         let activeUser = {
    //           firstName: doc.data().firstName,
    //           lastName: doc.data().lastName,
    //           displayName: doc.data().displayName,
    //           email: doc.data().email
    //         };
    //         if (doc.data().role) {
    //           activeUser.role = doc.data().role;
    //         }
    //         if (!doc.data().displayName) {
    //           if (activeUser.firstName && activeUser.lastName) {
    //             activeUser.displayName =
    //               activeUser.firstName + " " + activeUser.lastName;
    //           }
    //         }
    //         console.log(activeUser);
    //         dispatch({
    //           type: actionConstants.GET_USER_SUCCESS,
    //           activeUser
    //         });
    //       } else {
    //         // doc.data() will be undefined in this case
    //         dispatch({ type: actionConstants.GET_USER_ERROR });
    //       }
    //     })
    //     .catch(err => {
    //       dispatch({ type: actionConstants.GET_USER_ERROR, err });
    //     });
    // });
  };
};

export const changePassword = userDetails => {
  // console.log("current password is", userDetails.currentPassword);
  // console.log("new password is", userDetails.newPassword);
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    // const firestore = getFirestore();
    const firebase = getFirebase();
    var currentPassword = userDetails.currentPassword;
    var newPassword = userDetails.newPassword;
    // console.log(firebase);
    // console.log(user);

    const reauthenticate = currentPassword => {
      // console.log("currentPassword", currentPassword);
      var user = firebase.auth().currentUser;
      var cred = firebase.auth.EmailAuthProvider.credential(
        user.email,
        currentPassword
      );
      return user.reauthenticateAndRetrieveDataWithCredential(cred);
    };

    reauthenticate(currentPassword)
      .then(() => {
        var user = firebase.auth().currentUser;
        user
          .updatePassword(newPassword)
          .then(() => {
            console.log("Password updated!");
            dispatch({ type: actionConstants.PASSWORD_CHANGE_SUCCESS });
          })
          .catch(err => {
            console.log("catch 1");
            dispatch({ type: actionConstants.PASSWORD_CHANGE_ERROR, err });
          });
      })
      .catch(err => {
        console.log("catch 2");
        dispatch({ type: actionConstants.PASSWORD_CHANGE_ERROR, err });
      });
  };
};

// user
//   .resetPassword(password)
//   .then(
//     dispatch({
//       type: actionConstants.PASSWORD_CHANGE_SUCCESS
//     })
//   )
//   .catch(err => {
//     dispatch({ type: actionConstants.PASSWORD_CHANGE_ERROR, err });
//     // this.props.history.push("/admin/signin");
//   });
// .then(resp => {
//   console.log(resp.user.uid);
//   const docRef = firestore.collection("users").doc(resp.user.uid);
//   docRef
//     .get()
//     .then(doc => {
//       if (doc.exists) {
//         console.log("Document data:", doc.data());
//         let activeUser = {
//           firstName: doc.data().firstName,
//           lastName: doc.data().lastName,
//           displayName: doc.data().displayName,
//           email: doc.data().email
//         };
//         if (doc.data().role) {
//           activeUser.role = doc.data().role;
//         }
//         if (!doc.data().displayName) {
//           if (activeUser.firstName && activeUser.lastName) {
//             activeUser.displayName =
//               activeUser.firstName + " " + activeUser.lastName;
//           }
//         }
//         console.log(activeUser);
//         dispatch({
//           type: actionConstants.GET_USER_SUCCESS,
//           activeUser
//         });
//       } else {
//         // doc.data() will be undefined in this case
//         dispatch({ type: actionConstants.GET_USER_ERROR });
//       }
//     })
//     .catch(err => {
//       dispatch({ type: actionConstants.GET_USER_ERROR, err });
//     });
// });
// };

// return () => {
//   // // const firestore = getFirestore();
//   // const firebase = getFirebase();
//   console.log("firebase is");
// console.log(firebase);
// firebase.auth().sendPasswordResetEmail(email);
//     .addOnSuccessListener(new OnSuccessListener() {
//     public void onSuccess(Void result) {
//      console.log("password reset success");
//       dispatch({ type: actionConstants.PASSWORD_RESET_SUCCESS });
//     }
//  }).addOnFailureListener(new OnFailureListener() {
//     public onFailure(Exception e)
//       console.log("password reset error");
//       dispatch({ type: actionConstants.PASSWORD_RESET_ERROR });
//     }
//  });
// };
// };

// export const resetPassword = email => {
//   // console.log("password reset called", email);

//   return (dispatch, getState, { getFirebase }) => {
//     const firebase = getFirebase();
//     console.log(firebase);
//     // alert("signOut called");
//     firebase
//       .auth()
//       .sendPasswordResetEmail(email)
//       .then(() => {
//         console.log("password reset success");
//         dispatch({ type: actionConstants.PASSWORD_RESET_SUCCESS });
//       })
//       .catch(err => {
//         console.log("password reset error");
//         dispatch({ type: actionConstants.PASSWORD_RESET_ERROR });
//       });
//   };
// };

export const signOut = () => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    // console.log("signOut called");
    // alert("signOut called");
    firebase
      .auth()
      .signOut()
      .then(() => {
        // firebase.logout();
        // alert("signOut achieved");
        // console.log("signOut achieved");
        dispatch({ type: actionConstants.SIGNOUT_SUCCESS });
      })
      .catch(err => {
        dispatch({ type: actionConstants.SIGNOUT_ERROR });
      });
  };
};

export const signUp = newUser => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const displayName = newUser.firstName + " " + newUser.lastName;
    // console.log(newUser);
    firebase
      .auth()
      .createUserWithEmailAndPassword(newUser.email, newUser.password)
      .then(resp => {
        return firestore
          .collection("users")
          .doc(resp.user.uid)
          .set({
            firstName: newUser.firstName,
            lastName: newUser.lastName,
            displayName: displayName,
            initials:
              newUser.firstName[0].toUpperCase() +
              newUser.lastName[0].toUpperCase(),
            email: newUser.email
          });
      })
      .then(resp => {
        const user = firebase.auth().currentUser;
        // console.log("adding display name to auth");
        // console.log(user);
        return user.updateProfile({
          emailVerified: false,
          displayName: displayName,
          disabled: false
        });
      })
      .then(() => {
        dispatch({ type: actionConstants.SIGNUP_SUCCESS });
      })
      .catch(err => {
        dispatch({ type: actionConstants.SIGNUP_ERROR, err });
      });
  };
};
